<template>
<div class="card card-default">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12">
                <button class="btn btn-success" @click="addNewPackage()" type="button">
                    New Domain Package
                </button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>FDN</th>
                                <th>Domain</th>
                                <th>Markup</th>
                                <td>Price</td>
                                <th>Term</th>
                                <th>Expired</th>
                                <th>Integration</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :class="{'row-expired' : p.expired}" v-for="p in packages" :key="p.domainRegistrationPackageNumber">
                                <td>{{ p.description }}</td>
                                <td>
                                    <span class="badge badge-primary">
                                        <strong>
                                            .{{ p.topLevelDomain }}
                                        </strong>
                                    </span>
                                </td>
                                <td>{{ p.markup }}%</td>
                                <td>
                                    {{
                                        getCurrencyPrice(
                                        p.domainregistrationpackageprices,
                                        getCurrentPeriod(),
                                        p.currency
                                        )
                                    }}
                                    <span
                                        style="cursor: pointer"
                                        @click="openPriceModal(p)"
                                        v-tooltip="'Edit Price'"
                                        class="pull-right align-self-center"
                                    >
                                        <em class="fa fa-edit fa-fw text-green"></em>
                                    </span>
                                </td>
                                <td>{{ p.term }} Months</td>
                                <td>
                                    <span
                                        class="badge"
                                        :class="
                                        p.expired
                                            ? 'badge-warning'
                                            : 'badge-green'
                                        "
                                        >{{ p.expired }}
                                    </span>
                                </td>
                                <td>{{ p.integrationType }}</td>
                                <td style="text-align:center">
                                    <button
                                        v-tooltip="'Edit Package'"
                                        type="button"
                                        @click="openEditModal(p)"
                                        class="btn btn-sm btn-info m-1"
                                    >
                                        <em class="fa fa-edit fa-fw"></em>
                                    </button>
                                    <button
                                        v-tooltip="'Delete Package'"
                                        type="button"
                                        @click="deletePackage(p.domainRegistrationPackageNumber)"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <em class="fa fa-trash fa-fw"></em>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <modal :title="newPackage.objKey ? 'Update Package' : 'Create New Domain Package'" ref="addNewPackage" @save="newPackage.objKey ? updatePackage(newPackage) : createPackage()">
      <div class="form-group">
        <label>Top Level Domain</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">.</span>
          </div>
          <input
            type="text"
            v-model="newPackage.topLevelDomain"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          v-model="newPackage.description"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Markup</label>
        <div class="input-group">
          <input
            type="text"
            v-model="newPackage.markup"
            class="form-control"
          />
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Currency</label>
        <v-select
          :options="currencies"
          placeholder="Select Currency"
          v-model="newPackage.currency"
        ></v-select>
      </div>
      <div class="form-group">
        <label>Term</label>
        <select class="form-control" v-model="newPackage.term">
          <option v-for="term in terms" :key="term">{{ term }}</option>
        </select>
      </div>
      <div class="form-group" v-if="newPackage.objKey">
        <label class="control-label"> Expired </label>
        <v-select :options="['true', 'false']" v-model="newPackage.expired" placeholder="false"></v-select>
      </div>
      <div class="form-group">
        <label>Integration</label>
        <select
          class="form-control"
          v-model="newPackage.integrationType"
        >
          <option v-for="integration in integrations" :key="integration">
            {{ integration }}
          </option>
        </select>
      </div>
    </modal>
    
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      :currency="productCurrency"
      @addPrice="addPrice"
      @pricesAdded="getPackages()"
    />

</div>
</template>

<script>

import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import Modal from '@/components/GeneralComponents/Modal';
import PriceFunctions from "@/components/Admin/PriceFunctions";

export default {
  extends: PriceFunctions,
    components: {
        Modal,
        AddPriceModal,
    },
    data() {
        return {
            definition: {},
            packages: {},
            newPackage: {},
            newPrice: {},
            rowPrice: [],
            terms: [12, 24],
            integrations: ["Manual", "Partial", "Full"],
            currencies: ["ZAR", "USD", "EUR"],
            productCurrency: "",
        }
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    mounted() {
      this.getPackages();
    },
    methods: {
        getPackages() {
            this.$http.get(this.$config.aimsAPI + 'pricing/domainregistrationpackages')
            .then(response => {
                this.definition = response.data;
                this.packages = response.data.domainregistrationpackages;
                this.newPackage = response.data.domainregistrationpackage;
                this.newPrice = response.data.domainregistrationpackageprice;
            },
            error => {
                this.showError('Error fetching Global Domain Pricing Packages', error)
                console.error(error);
            });
        },
        addNewPackage() {
            this.newPackage = this.definition.domainregistrationpackage;
            this.$refs.addNewPackage.open();
        },
        createPackage() {
            this.$refs.addNewPackage.isLoading();
            this.$http
                .post(
                    this.$config.aimsAPI + "pricing/domainregistrationpackages",
                    this.newPackage
                )
                .then(
                (response) => {
                    this.showSuccess("Create Success");
                    this.$refs.addNewPackage.close();

                    const registrationPackage = response.data;
                    registrationPackage.domainregistrationpackageprices = [];
                    this.packages.push(registrationPackage);
                    this.openPriceModal(registrationPackage);
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Creating Domain Registration Package", error);
                }
                )
                .finally(() => {
                    this.$refs.addNewPackage.isLoading();
                });
        },
        openEditModal(pack) {
            this.newPackage = this.deepCopyObject(pack);
            this.$refs.addNewPackage.open();
        },
        updatePackage(pack) {
            this.$http.put(this.$config.aimsAPI + "pricing/domainregistrationpackages",
                pack
                )
                .then(
                () => {
                    this.getPackages();
                    this.showSuccess("Save Success");
                    this.$refs.addNewPackage.close();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Saving Domain Registration Package", error);
                }
                );
        },

        deletePackage(number) {
            this.$swal({
                title: "Are you sure you want to delete this Domain Pricing",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete"
            }).then((result) => {
                if (result.value) {
                    this.$http.delete(this.$config.aimsAPI + "pricing/domainregistrationpackages/" + number)
                        .then((success) => {
                                this.getPackages();
                                this.showSuccess("Delete Success");
                            },
                            (error) => {
                                console.error(error);
                                this.showError("Error Deleting Domain Registration Package", error);
                            }
                        );
                }
            });
        },
        setNewPrice() {
            this.newPrice = this.deepCopyObject(
                this.definition.domainregistrationpackageprice
            );
        },
        openPriceModal(registrationPackage) {
            this.productCurrency = registrationPackage.currency;
            this.rowPrice = this.deepCopyObject(
                registrationPackage.domainregistrationpackageprices || []
            );
            this.newPrice.domainRegistrationPackageNumber =
                registrationPackage.domainRegistrationPackageNumber;
            this.$refs.addPriceModal.open();
        },
        addPrice(newPrice) {
            this.rowPrice.forEach((price) => {
                if (price.validUntil == 0) {
                price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
                }
            });
            this.rowPrice.push(newPrice);
            this.setNewPrice();
            this.newPrice.domainRegistrationPackageNumber =
                newPrice.domainRegistrationPackageNumber;
        },
    },
}
</script>

<style>
    .row-expired {
        background-color: #f8f8f8;
    }
</style>
