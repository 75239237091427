<template>
  <div>
    <div>
      <ul class="nav nav-pills nav-fill" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="connectivityPackage-tab"
            data-toggle="tab"
            href="#connectivityPackage"
            role="tab"
            aria-controls="connectivityPackage"
            aria-selected="true"
            ><i class="fa fa-dropbox"></i> Private APN Packages</a
          >
        </li>
      </ul>
    </div>
    <div class="row row-spacing mb-2">
      <div class="col-md-12">
        <button v-if="userEditRight" type="button" class="btn btn-info" @click="createNewPackage()">
          Add New Package
        </button>
      </div>
    </div>
    <div class="row row-spacing mb-3">
      <div class="col-md-12">
        <div
          class="table-responsive table-bordered"
          v-if="privateApnPackages.length > 0"
        >
          <table class="table">
            <thead>
              <tr class="text-nowrap">
                <th>Description</th>
                <th>Bundle Size (GB)</th>
                <th>Setup Fee</th>
                <th>Price (excl. VAT)</th>
                <th>Out of Bundle (excl. VAT) per MB</th>
                <th>Per SIM (excl. VAT)</th>
                <th>Expired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'bg-primary': privateApnPackage.expired }"
                v-for="privateApnPackage in privateApnPackages"
                :key="privateApnPackage.apnPackageNumber"
              >
                <td>{{ privateApnPackage.description }}</td>
                <td class="text-nowrap text-center">
                  {{ privateApnPackage.dataBundleSize }}
                </td>
                <td class="text-nowrap">
                  {{ privateApnPackage.setupfee }}
                </td>
                <td>
                  {{ getPrice(privateApnPackage.prices, period) }}
                  <span
                    v-if="userEditRight"                  
                    style="cursor: pointer"
                    @click="openPriceModal(privateApnPackage)"
                    v-tooltip="'Edit Row Price'"
                    class="pull-right"
                  >
                    <em class="fa fa-edit fa-fw text-green"></em>
                  </span>
                </td>
                <td class="text-nowrap">
                  {{ getApnPackagePrice(privateApnPackage.prices, period) }}
                </td>
                <td class="text-nowrap">
                  {{ getApnPerSimPrice(privateApnPackage.prices, period) }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      privateApnPackage.expired ? 'badge-warning' : 'badge-green'
                    "
                    >{{ privateApnPackage.expired }}
                  </span>
                </td>
                <td class="text-center">
                  <button
                    v-if="userEditRight"                  
                    v-tooltip="'Edit Package'"
                    @click="editPackage(privateApnPackage)"
                    type="button"
                    class="btn btn-sm btn-info m-1"
                  >
                    <em class="fa fa-edit fa-fw"></em>
                  </button>
                  <button
                    v-if="userEditRight"                  
                    v-tooltip="'Delete Package'"
                    type="button"
                    @click="deletePackage(privateApnPackage)"
                    class="btn btn-sm btn-primary"
                  >
                    <em class="fa fa-trash fa-fw"></em>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">No Private APN Packages Available</div>
      </div>
    </div>
    <ModifyPrivateApnPackageModal
      ref="modifyPackageModal"
      :pricePackage="pricePackage"
      @packageAdded="packageAdded"
      @packageEdited="loadprivateApnPackages()"
    />
    <PrivateApnPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="loadprivateApnPackages()"
    />
  </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import PrivateApnPriceModal from "@/components/Admin/Modals/PrivateApnPriceModal";
import ModifyPrivateApnPackageModal from "@/components/Admin/Modals/ModifyPrivateApnPackageModal";

export default {
  extends: PriceFunctions,
  components: {
    ModifyPrivateApnPackageModal,
    PrivateApnPriceModal,
  },
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      privateApnPackages: [],
      period: this.getCurrentPeriod(),
      rowPrice: [],
      pricePackage: {},
      newPrice: {},
    };
  },
  mounted() {
    this.loadprivateApnPackages();
  },
  methods: {
    loadprivateApnPackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/packages/product/" +
            this.productNumber +
            "/ApnPackage"
        )
        .then(
          (response) => {
            this.privateApnPackages = response.data;
            this.setNewPrice();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Private Apn Packages", error);
          }
        );
    },
    setNewPrice() {
      this.$http
        .get(
          this.$config.aimsAPI + "pricing/new/price/ApnPackagePrice"
        )
        .then(
          (response) => {
            this.newPrice = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Price Template", error);
          }
        );
    },
    openPriceModal(privateApnPackage) {
      this.rowPrice = this.deepCopyObject(privateApnPackage.prices || []);
      this.newPrice.apnPackageNumber =
        privateApnPackage.apnPackageNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.apnPackageNumber =
        newPrice.apnPackageNumber;

    },
    editPackage(privateApnPackage) {
      this.pricePackage = this.deepCopyObject(privateApnPackage);
      this.$refs.modifyPackageModal.open();
    },
    createNewPackage() {
      this.$http
        .get(
          this.$config.aimsAPI + "pricing/new/package/ApnPackage"
        )
        .then(
          (response) => {
            this.pricePackage = response.data;
            this.pricePackage.productNumber = this.productNumber;
            this.$refs.modifyPackageModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Package Template", error);
          }
        );
    },
    packageAdded(privateApnPackage) {
      this.privateApnPackages.push(privateApnPackage);
      this.openPriceModal(privateApnPackage);
    },
    deletePackage(privateApnPackage) {
      this.$swal({
        title: "Are you sure you want to delete this Package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                privateApnPackage.objKey +
                "?objClassName=" +
                privateApnPackage.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadprivateApnPackages();
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    },
    getApnPackagePrice(data, period, vat) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              if (vat) {
                return this.timesVAT(
                  parseFloat(price[0].outOfBundlePriceExclVat)
                );
              } else {
                return this.currencyFormat(
                  parseFloat(price[0].outOfBundlePriceExclVat)
                );
              }
            } else {
              return "R " + 0;
            }
          } else {
            if (vat) {
              return this.timesVAT(
                parseFloat(price.outOfBundlePriceExclVat)
              );
            } else {
              return this.currencyFormat(
                parseFloat(price.outOfBundlePriceExclVat)
              );
            }
          }
        } else {
          return "R " + 0;
        }
      } else {
        return 0;
      }
    },
    getApnPerSimPrice(data, period, vat) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              if (vat) {
                return this.timesVAT(
                  parseFloat(price[0].perSimPriceExclVat)
                );
              } else {
                return this.currencyFormat(
                  parseFloat(price[0].perSimPriceExclVat)
                );
              }
            } else {
              return "R " + 0;
            }
          } else {
            if (vat) {
              return this.timesVAT(
                parseFloat(price.perSimPriceExclVat)
              );
            } else {
              return this.currencyFormat(
                parseFloat(price.perSimPriceExclVat)
              );
            }
          }
        } else {
          return "R " + 0;
        }
      } else {
        return 0;
      }
    }
  },
};
</script>
<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}
.nav-pills .nav-link {
  background-color: #f3f2ee;
}
a {
  color: rgba(0, 0, 0, 0.6);
}
</style>
