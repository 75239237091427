<template>
  <div class="row" :class="{ 'sidebar-removed': sidebarRemoved }">
    <div class="col-md-12">
      <loading id="saleLoadingSpinner" active style="display: none"></loading>
      <div class="card card-flat">
        <div class="card-header">
          <div class="row" v-if="$route.name !== 'archivedservice'">
            <ul class="nav col-md-6">
              <li
                v-allowed:view="menuItem.groupPermissions"
                class="nav-item"
                :class="{ dropdown: menuItem.children.length > 1 }"
                v-for="(menuItem, index) in saleMenu"
                :key="index"
              >
                <a
                  v-if="menuItem.children.length > 1"
                  :id="menuItem.title"
                  :class="activeLink(menuItem)"
                  class="nav-link dropdown-toggle"
                  style="color: #656565"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <em><i :class="menuItem.iconClass"></i></em>
                  <span class="">{{ menuItem.title }}</span>
                </a>
                <router-link
                  v-else
                  style="color: rgb(101, 101, 101)"
                  class="nav-link"
                  :id="menuItem.title"
                  :to="{
                    name: menuItem.children[0].routeName,
                    params: menuItem.children[0].routeParams,
                  }"
                >
                  <em><i :class="menuItem.iconClass"></i></em>
                  <span class="">{{ menuItem.title }}</span>
                </router-link>
                <div class="dropdown-menu" v-if="menuItem.children.length > 1">
                  <router-link
                    v-allowed:view="menuItem.groupPermissions"
                    v-for="(menuItem, index) in menuItem.children"
                    :key="index"
                    class="dropdown-item"
                    :to="{
                      name: menuItem.routeName,
                      params: menuItem.routeParams,
                    }"
                  >
                    <em><i :class="menuItem.iconClass"></i></em>
                    <span class="">{{ menuItem.title }}</span>
                  </router-link>
                </div>
              </li>

              <li class="nav-item" v-if="showCommands"> 
                <a
                  id="menuItem.title"
                  class="nav-link dropdown-toggle"
                  style="color: #656565"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <em><i class="fa-solid fa-microchip"></i></em>
                  <span class="">Commands</span>
                </a>
                <div class="dropdown-menu">
                  <div 
                    v-for="(command, index) in saleDefinition.commands"
                    :key="index"
                    class="dropdown-item"
                    @click="runCommand(sale.saleNumber, command)"
                  > 
                    <em><i class="fa-solid fa-caret-right"></i></em>
                    <span class="">{{command.name}}</span>
                  </div>
                </div>
              </li>
            </ul>
            <div
              class="col-md-6 pull-right text-right"
              v-allowed:view="['STAFF']"
              v-if="saleDefinition.sale.status && saleDefinition.product.productDefinition !== 'za.co.adept.aims.product.archive.ArchivedService'" 
            >
              <button
                type="button"
                v-if="saleDefinition.sale.status == 'Suspended' || saleDefinition.sale.status == 'Inactive'"
                @click="activateSale()"
                class="btn btn-green"
              >
                Activate Service
              </button>
              <button
                type="button"
                v-if="saleDefinition.sale.status == 'Active'"
                @click="suspendSale()"
                class="btn btn-danger"
              >
                Suspend Service
              </button>
              <!-- Advanced Users Deactivate Button -->
              <button
                v-allowed:view="['STAFF']"
                type="button"
                v-if="saleDefinition.sale.status == 'Active' || saleDefinition.sale.status == 'Suspended'"
                v-show="!isPending"
                @click="deactivateSaleAdvanced()"
                class="btn btn-red text-light"
              >
                Deactivate Service
              </button>
              <!-- Normal Deactivate Button -->
              <button
                v-allowed:view="['CLIENT', 'RESELLER']"
                type="button"
                v-if="saleDefinition.sale.status == 'Active' || saleDefinition.sale.status == 'Suspended'"
                v-show="!isPending"
                @click="deactivateSale()"
                class="btn btn-red text-light"
              >
                Deactivate Service
              </button>
              <button
                v-allowed:view="['STAFF']"
                type="button"
                v-if="(saleDefinition.sale.status == 'Active' || saleDefinition.sale.status == 'Suspended' || saleDefinition.sale.status == 'Inactive') || canDeactivate"
                v-show="isPending"
                @click="cancelDeactivation()"
                class="btn bg-warning text-light"
              >
                Cancel Deactivation
              </button>
              <button
                v-allowed:view="['STAFF']"
                type="button"
                v-if="saleDefinition.sale.status == 'Active' || saleDefinition.sale.status == 'Suspended'"
                v-show="!isPending"
                @click="archiveSettings()"
                class="btn btn-info text-light"
              >
              Archive <i class="fa fa-cogs"/>
              </button>
            </div>
          </div>
          <hr v-if="saleMenu.length > 0 && $route.name !== 'archivedservice'" />
          <ServiceTitle
            ref="serviceTitle"
            v-if="sale.description"
            :saleDescription="sale.description"
            :saleLabel="sale.label"
            @saleLabelChanged="saleLabelChanged"
            :product="product"
            :status="sale.status"
            @reloadSale="setSaleNavbar(sale.saleNumber)"
          />
        </div>
        <div class="serviceView">
          <router-view></router-view>
          <div class="cover clientCover" title="Read Only" v-if="userContainsPermission(['CLIENT']) && productAccessRestricted() && (saleDefinition.sale.status != 'Suspended')"></div>
          <div class="cover suspensionCover" v-if="saleDefinition.sale.status == 'Suspended'"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceTitle from "@/components/Services/ServiceTitle";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import SaleAdminDetail from "@/pages/Sales/SaleAdminDetail.vue";
import Loading from "vue-loading-overlay";
const saleNavbarStructure = require("@/structures/saleNavbarStructure.json");

export default {
  extends: SaleFunctions,
  extends: SaleAdminDetail,
  components: {
    ServiceTitle,
    Loading,
  },
  computed: {
    saleMenu() {
      if (this.navbarStructure.menu.length > 1) {
        return this.navbarStructure.menu;
      }
        
      return this.navbarStructure.menu.filter((m) => m.children.filter((c) => this.userContainsPermission(c.groupPermissions)).length > 1);
    },
  },
  data() {
    return {
      navbarStructure: {
        menu: [],
      },
      sale: {},
      saleDefinition: {},
      product: {},
      sidebarRemoved: false,
      canSuspend: false,
      canDeactivate: false,
      isPending: false,
      showCommands: false,
      saleNumber: null,
    };
  },
  async mounted() {
    await this.$http.get(this.$config.aimsAPI + "users/user/loggedInUser").then(
      (response) => {
        this.loggedInUser = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching User", error);
      }
    );

    await this.$http.get(this.$config.aimsAPI + "users/groups").then(
      (response) => {
        this.userGroups = response.data;
      },
      (error) => {
        console.warn(error);
        this.logout("tokenIssue=1");
      }
    );    
    
    await this.setSaleNavbar(this.$route.params.saleNumber);    
  },
  watch: {
    $route(to, from) {      
      this.saleNumber = to.params.saleNumber;

      this.$http.get(this.$config.aimsAPI + "sales/" + this.saleNumber).then(
        (response) => {
          this.sale = response.data;
          this.product = this.sale.product; 
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching sale", error);
        }
      );
    }
  },
  methods: {
    productAccessRestricted: function() {
      //true means it should be restricted
      //concern about using text here
      if (this.product.clientAccessRestriction == 'Client Read-Only' || this.product.clientAccessRestriction == 'None(Select one)') {
        return true;
      } else {
        return false;
      }
      
    },
    togglePending() {
      this.isPending = !this.isPending;
    },
    setSaleNavbar(saleNumber) {      
      this.$http
        .get(this.$config.aimsAPI + "sales/" + saleNumber)
        .then((response) => {
          const sale = response.body;
          
          const productDefinition = this.getProductDefinition(
            sale.product.productDefinition
          );
          
          this.canSuspend = productDefinition.integratedSuspension;
          this.canDeactivate = productDefinition.integratedDeactivation;

          //set Service Title params
          this.sale = sale;
          this.product = sale.product;        
          
          //set the service view route
          const serviceRouteIndex = saleNavbarStructure.menu.findIndex(
            (m) => m.id === "service"
          );
          const saleRouteIndex = saleNavbarStructure.menu[
            serviceRouteIndex
          ].children.findIndex((m) => m.id === "saleview");
          saleNavbarStructure.menu[serviceRouteIndex].children[
            saleRouteIndex
          ].routeName = productDefinition.routeName;

          //set the service regrades route if exists
          const regradeRouteIndex = saleNavbarStructure.menu[
            serviceRouteIndex
          ].children.findIndex((m) => m.id === "serviceregradeview");
          if (regradeRouteIndex != -1) {
            if (productDefinition.regradeRouteName) {
              saleNavbarStructure.menu[serviceRouteIndex].children[
                regradeRouteIndex
              ].routeName = productDefinition.regradeRouteName;
            } else {
              //regrade route not defined
              saleNavbarStructure.menu[serviceRouteIndex].children.splice(
                regradeRouteIndex,
                1
              );
            }
          };
          
          if (this.$route.name === "archivedservice") {
            return this.isCached = false;
          } 
          const hostUrl = this.$config.aimsAPI;
          const saleNumber = this.$route.params.saleNumber;
          this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}?cache=${this.isCached}`).then(
            (response) => {
              this.saleDefinition = response.data;
              
              if (this.saleDefinition.commands && this.saleDefinition.commands.length > 0 && (this.userContainsPermission(['ADMIN']) || this.userContainsPermission(['TECHNICAL_STAFF']))) {
                this.showCommands = true;
              }

              if(this.saleDefinition.supportingInfo && this.saleDefinition.supportingInfo.deactivateOn) {
                this.isPending = true;
              } else {
                this.isPending = false;
              }
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Sale Definition", error);
            }
          );

          if (this.navbarStructure.menu.length == 0) {
            this.navbarStructure = this.getRestrictedNavStructure(saleNavbarStructure);
          }
          if (!this.userContainsPermission([
              "STAFF",
              "RESELLER",
              "CLIENT",
              "SYSTEM",
            ])
          ) {
            this.sidebarRemoved = true;
          }
        });
    },
    activeLink({ title }) {
      if (this.$route.name.includes("billing")) {
        if (title == "Billing") {
          return "router-link-active";
        }
      } else if (this.$route.name.includes("sale")) {
        if (title == "Sale") {
          return "router-link-active";
        }
      } else {
        if (title == "Service") {
          return "router-link-active";
        }
      }
    },
    saleLabelChanged(label) {
      this.sale.label = label;
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/label/" +
            this.sale.saleNumber +
            "/update?label=" +
            this.sale.label
        )
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.sale = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Label", error);
          }
        );
    },
    reloadDetails() {      
      this.loadSale();
      this.setSaleNavbar(this.sale.saleNumber);
    },
    suspendSale() {
      this.saleSuspend(this.sale.saleNumber).then(
        (success) => {
          this.sale = success.data.sale;
          this.reloadDetails();
        },
        (error) => {
          console.error(error);
          this.showError("Error Suspending Service", error);
          this.reloadDetails();
        }
      );
    },
    activateSale() {
      this.saleActivate(this.sale.saleNumber).then(
        (success) => {
          this.sale = success.data.sale;
          this.reloadDetails();
        },
        (error) => {
          console.error(error);
          this.showError("Error Activating Service", error);
          this.reloadDetails();
        }
      );
    },
    deactivateSale() {
      this.saleDeactivateOptions(this.sale.saleNumber).then(
        (success) => {
          this.sale = success.data.sale;
          this.reloadDetails();
        },
        (error) => {
          console.error(error);
          this.showError("Error Deactivating Service", error);
          this.reloadDetails();
        }
      );
    },
    deactivateSaleAdvanced() {
      this.$http.get(this.$config.aimsAPI + "sales/" + this.sale.saleNumber + "/deactivationTerms").then(
        (success) => {
          this.$http.get(this.$config.aimsAPI + "sales/archivetimes").then(
            (res) => {
              var archiveText;
              if (this.sale.archive == "Default") {
                  archiveText = res.body.DEFAULT.toLowerCase();
              } else {
                  archiveText = this.sale.archive.toLowerCase();
              }
              
              return this.$swal({
                  title: "When should the service deactivate?",
                  input: "select",
                  inputOptions: success.data,
                  html: 'Your service will be archived ' + archiveText,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Deactivate',
                }).then((response) => {
                  if (response.value) {
                    var deactivateOption = response.value;

                    this.$http.get(this.$config.aimsAPI + "sales/archivetype/" + this.sale.archive).then( 
                      (response) => {
                        this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.sale.saleNumber + "/deactivate/" + deactivateOption + "?archive=" + response.bodyText).then(
                          (success) => {
                            this.sale = success.data.sale;

                            if (this.sale.archive === "Immediately" && this.sale.status === "Inactive") {
                              this.$router.replace({ name: "archivedservice" });
                            }

                            this.reloadDetails();
                          },
                          (error) => {
                            console.error(error);
                            this.showError("Error Deactivating Service", error);
                            this.reloadDetails();
                          });
                      });
                  }
                });
            },
            (error) => {
              console.error(error);
              this.showError("Error Deactivating Service", error);
              this.reloadDetails();
            }
          );
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Deactivation Terms", error);
        });
    },
    archiveSettings() {
      this.$http.get(this.$config.aimsAPI + "sales/archivetimes").then( 
        (success) => {
          this.setArchive(success.body, this.sale.archive).then(
            (success) => {
              if (success != null && success != "") {
                this.saleDefinition.sale.archive = success;
                this.$http.put(
                  this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/update", 
                  this.saleDefinition
                ).then(
                  (response) => {
                    this.showSuccess("Save Success");
                    this.saleDefinition = response.data;
                    
                    this.reloadDetails();
                  },
                  (error) => {
                    console.error(error);
                    this.showError("Error Saving Archive Settings", error);
                  }
                );
              } 
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Archive Times", error);
              this.reloadDetails();
            }
          );
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Archive Times", error);
          this.reloadDetails();
        }
      );
    },
    cancelDeactivation() {
      this.cancelDeactivate(this.sale.saleNumber).then(
        (success) => {
          this.sale = success.data.sale;
          this.reloadDetails();
        },
        (error) => {
          console.error(error);
          this.showError("Error Deactivating Service", error);
          this.reloadDetails();
        }
      );
    },
  },
  created() {
    document.title =
      this.$route.meta.title + " - " + this.$route.params.saleNumber;
    this.$root.$emit("SET_PAGE_TITLE_BADGES", []);
  }
};
</script>

<style scoped>
.card-body {
  padding-top: 0;
  margin-top: 0;
}
 
.serviceView {
  position: relative;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 2.5em;
  z-index: 500;
}

.suspensionCover {
  background-image: url('/img/services/suspend.png');
  background-repeat: repeat;
  background-position: bottom center;
  background-size: 15em;
  opacity: 0.5;
}

.clientCover {
  background-image: url('/img/services/clientblock.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 15em;
  opacity: 0;
}

.dropdown-item:hover {
  cursor: pointer;
}

i {
  margin-right: 3px;
}
</style>
