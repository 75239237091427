<template>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-default">
          <div class="card-body">
            <div class="row row-spacing">
              <div class="col-md-2"> 
                <label>Select user type</label>
                <select class="form-control" v-model="selectedUserType" @change="userTypeChange()">
                  <option v-for="(type,index) in availableTypes" :key="index" :value="type">{{type}}</option>
                </select>
              </div>
              <div class="col-md-8">
                <label>Search {{ selectedUserType }} users</label>
                <div class="input-group">  
                  <input type="search" class="form-control py-2" :placeholder="searchInputPlaceholderText" v-model="searchTerm">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
                    </div>
                </div>
              </div>
              <div class="col-md-2">
                <button class="btn btn-success py-2 float-right" style="margin-top: 30px" @click="addUser">{{ `Add ${selectedUserType} User`}}</button>
              </div>
            </div> 
            <hr>
            <div class="row row-spacing">
              <div class="col-md-12">
                <div class="table-repsonsive">
                  <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th v-if="selectedUserType == 'Staff'">Name</th>
                          <th>Email</th>
                          <th v-if="selectedUserType == 'Staff'" style="width:30%">Roles</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody v-if="filteredUsersList.length > 0">
                        <tr v-for="userType in filteredUsersList" :key="userType.user.objKey">
                          <td v-if="selectedUserType == 'Staff'">
                            {{ userType.user.firstNames }} {{ userType.user.lastName }}
                          </td>
                          <td>
                            <span class="mr-2"> {{ userType.user.emailAddress }}</span>
                            <span v-for="right in userType.rights" :key="right.name">
                            </span>
                          </td>
                          <td class="roles-col" v-if="selectedUserType == 'Staff'">
                            <div class="d-flex flex-wrap">
                              <span v-for="(role, index) in userType.rights" :key="index" class="badge-role badge" :class="{'badge-warning text-white': role.name === 'ADMIN', 'badge-info': role.name !== 'ADMIN'}">
                                {{ role.name === 'ADMIN' ? 'Admin' : role.description }}
                              </span>
                            </div>
                          </td>
                          <td class="text-center" v-allowed:view="['ADMIN']">
                            <button class="btn btn-success" v-tooltip="'Click to Edit'" @click="editUser(userType)"><i class="fa fa-pencil-square-o"></i></button>
                            <button class="btn btn-primary" v-tooltip="'Click to Delete'" @click="deleteUser(userType.user)"><i class="fa fa-trash"></i></button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="7">No Users available</td>
                        </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>                       
          </div>
        </div>
      </div>
      <UserManagementModal
        ref="openUserManangementModal"
        :modalTitle="modalTitle"
        :selectedUser="selectedUser"
        :selectedUserType="selectedUserType"
        :availableUserRights="availableUserRights"
        :assignedRights="assignedRights"
        :labourRates="labourRates"
        @saveUser="saveUser"
        @assignRightToUser="assignRightToUser"
        @removeAssignedUserRight="removeAssignedUserRight"
        @reloadUsers="getUserTypes"
      />
      <AddUserManagementModal 
        ref="openAddUserManagementModal"
        :modalTitle="modalTitle"
        :selectedUser="selectedUser"
        :selectedUserType="selectedUserType"
        :assignedRights="assignedRights"
        :availableUserRights="availableUserRights"
        :labourRates="labourRates"
        @createUser="createUser"
        @assignRightToUser="assignRightToUser"
        @removeAssignedUserRight="removeAssignedUserRight"
      />
    </div> 
</template>
<script>
import UserManagementModal from '@/components/Admin/Modals/UserManagementModal';
import AddUserManagementModal from '@/components/Admin/Modals/AddUserManagementModal';
import ClientFunctions from '@/pages/ClientFunctions.vue';

  export default {
    components: {
     UserManagementModal,
     AddUserManagementModal
    },
    extends: ClientFunctions,
    data() {
      return {
        selectedUserType: 'Staff',  //default
        availableTypes: ["Staff", "System"],
        usersList: [],
        searchInputPlaceholderText: 'Search Username, Firstnames, Lastname...',
        searchTerm: '',
        searchedUsersList: [],
        companyNames: [],
        companyName: '',
        modalTitle: '',
        selectedUser: {
          user: {},
          rights: []
        },
        labourRates: [],
        clientNumber: null,
        clientDetail: {},
        assignedRights: [],
        availableUserRights: [],
        userNumber: null,
        password: '',
        
        sortKey: 'created',
        reverse: false,
       }
    },
    mounted() {
      this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);

      if (this.selectedUserType === 'Staff') {
         this.getUserTypes();
         this.getLabourRates();
      } else {
        console.error('No user type selected');
      };
    },
    computed: {
        filteredUsersList() {
            this.searchedUsersList = this.usersList;         
            
            if (this.searchTerm && this.selectedUserType === 'Staff') {
              return this.searchedUsersList = this.searchedUsersList.filter((searchedUser) => 
                  searchedUser.user.objKey.toString().includes(this.searchTerm) ||
                  searchedUser.user.emailAddress.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                  searchedUser.user.firstNames.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                  searchedUser.user.lastName.toLowerCase().includes(this.searchTerm.toLowerCase())
              );
            }
            
            if (this.searchTerm && this.selectedUserType === 'System') {
              return this.searchedUsersList = this.searchedUsersList.filter((searchedUser) => 
                  searchedUser.user.objKey.toString().includes(this.searchTerm) ||
                  searchedUser.user.emailAddress.toLowerCase().includes(this.searchTerm.toLowerCase())
              );
            }

            return this.searchedUsersList;
        }
    },
    methods: {
      getUserTypes: function() {
          const hostUrl = this.$config.aimsAPIv2;
          let userType =  this.selectedUserType.toLowerCase();

          this.$http.get(`${hostUrl}users/${userType}`).then(
            (response) => {
              this.usersList = response.data;
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Staff users", error);
            }
          )
      },
      getLabourRates: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}users/labourrates`).then(
            (response) => {
              this.labourRates = response.data;
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Labour rates", error);
            }
          )
      },
      userTypeChange: function() {
        switch (this.selectedUserType) {
          case 'Staff':
            this.searchTerm = '',
            this.getUserTypes();
            this.searchInputPlaceholderText = 'Search by User Number, Username, Firstname, Lastname...';            
            break;

          case 'System':
            this.searchTerm = '',
            this.getUserTypes();
            this.searchInputPlaceholderText = 'Search by User Number, Username...';
            break;
        
          default:
            this.getUserTypes();
            break;
        };
      },
      editUser: function(data) {              
        this.selectedUser = this.deepCopyObject(data);  
        this.$refs.openUserManangementModal.open();
          if (this.selectedUserType === 'Staff') {
            this.modalTitle = 'Staff User Management';
            const userNumber = this.selectedUser.user.staffUserNumber;
            this.getAvailableRightsForUsers(userNumber); 
          } else {
            this.modalTitle = 'System User Management';
            const userNumber = this.selectedUser.user.systemUserNumber;
            this.getAvailableRightsForUsers(userNumber); 
          } 
      },
      getAvailableRightsForUsers: function(userNumber) {      
        const hostUrl = this.$config.aimsAPIv2;
        const userType = this.selectedUserType.toLowerCase();

        this.$http.get(`${hostUrl}users/${userType}/${userNumber}/rights`).then(
          (response) => {
              this.availableUserRights = response.data;    
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching available user rights", error);
          }
        )
      },
      assignRightToUser: function(rightObj) {        
        const hostUrl = this.$config.aimsAPIv2; 
        let userType = this.selectedUserType.toLowerCase();
        const rightEnumName = rightObj.name;       

        if (userType === 'staff') {
          this.userNumber = this.selectedUser.user.staffUserNumber;
        } else {
          this.userNumber = this.selectedUser.user.systemUserNumber;
        }

        this.$http.post(`${hostUrl}users/${userType}/${this.userNumber}/rights/${rightEnumName}`).then(
          (response) => {
            this.selectedUser = response.data;
            this.assignedRights = this.selectedUser.rights;
            this.getAvailableRightsForUsers(this.userNumber);
            this.showSuccess(`${rightObj.description} - right added`);
            this.getUserTypes();
          },
          (error) => {
            console.error(error);
            this.showError("Error assigning right to user", error);
          }
        )
      },
      removeAssignedUserRight: function(rightObj) {
        const hostUrl = this.$config.aimsAPIv2;
        const rightEnumName = rightObj.name;
        let userType = this.selectedUserType.toLowerCase();

        if (userType === 'staff') {
          this.userNumber = this.selectedUser.user.staffUserNumber;
        } else {
          this.userNumber = this.selectedUser.user.systemUserNumber;
        }
        
        this.$http.delete(`${hostUrl}users/${userType}/${this.userNumber}/rights/${rightEnumName}`).then(
          (response) => {
            this.selectedUser = response.data;
            this.assignedRights = this.selectedUser.rights;
            this.getAvailableRightsForUsers(this.userNumber);
            this.showSuccess(`${rightObj.description} - right removed`);
            this.getUserTypes();
          },
          (error) => {
            console.error(error);
            this.showError(`Error removing ${rightObj.description} right`, error);
          }
        )
      },
      saveUser: function(user) {     
        const hostUrl = this.$config.aimsAPIv2;
        const userType = this.selectedUserType.toLowerCase();
        this.$http.put(`${hostUrl}users/${userType}`, user).then(
          (repsonse) => {
            this.showSuccess(`${this.selectedUserType} user updated`);
            this.getUserTypes();
          },
          (error) => {
            console.error(error);
            this.showError(`Error saving ${this.selectedUserType} user`, error);
          }
        )
      },
      addUser() {
        this.$refs.openAddUserManagementModal.open();
        this.modalTitle = `Add new ${this.selectedUserType} user`;
        this.assignedRights = [];
        if (this.selectedUserType === 'Staff') {
            this.selectedUser = {
                user: {
                  class: `adept.aims.auth.${this.selectedUserType}User`,
                },
                rights: [],
            }
        } else {
            this.selectedUser = {
                user: {
                   class: `adept.objdb.auth.${this.selectedUserType}User`,
                },
                rights: [],
            }
        }
      },
      createUser: function(user) {
        const hostUrl = this.$config.aimsAPIv2;
        const userType = this.selectedUserType.toLowerCase();

        this.$http.post(`${hostUrl}users/${userType}`, user).then(
          (response) => {
            this.selectedUser = response.data;
            
            if (userType === 'staff') {
              this.userNumber = this.selectedUser.user.staffUserNumber;
            } else {
              this.userNumber = this.selectedUser.user.systemUserNumber;
            }

            this.getAvailableRightsForUsers(this.userNumber);
            this.getUserTypes();
          },
          (error) => {
            console.error(error);
            this.showError("Error creating user", error);
          }
        )
      },
      deleteUser: function(user) {     
        this.$swal({
              title: "Are you sure you want to delete this User?",
              text: `${user.firstNames} - ${user.emailAddress}`,
              type: "warning",
              showCancelButton: true,
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
          }).then((result) => {
              if (result.value) {
                  const hostUrl = this.$config.aimsAPIv2;
                  let userType = this.selectedUserType.toLowerCase();

                  if (userType === 'staff') {
                      this.userNumber = user.staffUserNumber;
                    } else {
                      this.userNumber = user.systemUserNumber;
                  }

                  this.$http.delete(`${hostUrl}users/${userType}/${this.userNumber}`).then(
                      (response) => {
                        this.showSuccess("Successfully deleted user");
                        this.getUserTypes();
                      },
                      (error) => {
                        this.showError("Error deleting user", error);
                        console.error(error);
                      }
                  );
              }
          }
        );      
      },
      sortBy(sortKey) {
          this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
          this.sortKey = sortKey;
      },
    }
  }
</script>

<style>
.badge-role {
  margin: 1px !important;
}

.roles-col {

}
</style>