<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <h4>Filter Tickets</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control" :placeholder="searchPlaceholderText" v-model="searchTerm">
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <v-select :clearable="true" v-model="status" :options="statuses">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <div class="input-group">
                                <DateInput 
                                    type="date" 
                                    format="YYYY-MM-DD"
                                    :placeholder="'Select starting date range'"
                                    style="width: 100%"
                                    v-model="createdDate"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table table-hover table-responsive table-bordered">
            <thead>
                <tr class="dark-row">
                    <th @click="sortBy('ticketNumber')" class="sort-col th-width">
                        Ticket Number
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ticketNumber' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ticketNumber' && !reverse"></i>
                    </th>
                    <th @click="sortBy('description')" class="sort-col">
                        Subject
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
                    </th>
                    <th v-allowed:view="['CLIENT']" @click="sortBy('clientFirstName')" class="sort-col th-width">
                        Ticket Creator
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'clientFirstName' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'clientFirstName' && !reverse"></i>
                    </th>
                    <th v-allowed:view="['STAFF']" @click="sortBy('techFirstName')" class="sort-col th-width">
                        Assigned Tech
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'techFirstName' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'techFirstName' && !reverse"></i>
                    </th>
                    <th class="th-width">Status</th>
                    <th class="th-width">Created</th>
                    <th class="th-options"></th>
                </tr>
            </thead>
            <tbody v-if="filteredNoReplyTickets.length > 0">
                <tr v-for="ticket in filteredNoReplyTickets" :key="ticket.ticketNumber" v-tooltip="'View Ticket in Helpdesk'">
                    <td>{{ ticket.ticketNumber }}</td>
                    <td class="td-pointer" @click="goToHelpDeskTicket(ticket.ticketNumber)">{{ ticket.description }}</td>
                    <td v-allowed:view="['CLIENT']">{{ showTicketClient(ticket) }}</td>
                    <td v-allowed:view="['STAFF']">{{ showTicketTech(ticket) }}</td>
                    <td><span :class="getStatusBadge(ticket.status)">{{ticket.status}}</span></td>
                    <td class="text-nowrap">{{dateTimeFormat(ticket.created)}}</td>
                    <td class="td-options td-button dropdown" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" @click="goToHelpDeskTicket(ticket.ticketNumber)">Go to</a>
                            <a class="dropdown-item" @click="copyUrlToClipboard(ticket.ticketNumber)">Copy URL</a>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="6">No Helpdesk Tickets to display</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
    components: {
        DateInput
    },
    props: {
        noReplyTickets: {
            type: Array,
            required: true
        },
        statuses: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            searchTerm: '',
            filteredNoReplyTicketList: [],
            status: '',
            createdDate: '',

            sortKey: 'ticketNumber',
            reverse: false,
        }
    },
    computed: {
        searchPlaceholderText() {
            return this.userContainsPermission(["CLIENT"]) ? "Search by Ticket Number, Subject or Ticket Creator..." : "Search by Ticket Number, Subject or Assigned Tech...";
        },
        filteredNoReplyTickets() {
            this.filteredNoReplyTicketList = this.noReplyTickets;

                if (this.searchTerm) {
                    this.filteredNoReplyTicketList = this.filteredNoReplyTicketList.filter((ticket) => 
                        ticket.ticketNumber.toString().includes(this.searchTerm) ||
                        ticket.clientFirstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                        ticket.clientLastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                        ticket.techFirstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                        ticket.techLastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                        ticket.description.toLowerCase().includes(this.searchTerm.toLowerCase())
                    );                
                };

                if (this.status) {
                    this.filteredNoReplyTicketList = this.filteredNoReplyTicketList.filter((ticket) => ticket.status === this.status); 
                }

                if (this.createdDate) {                
                    this.filteredNoReplyTicketList = this.filteredNoReplyTicketList.filter((ticket) => this.dateFormat(ticket.created) > this.dateFormat(this.createdDate)); 
                }

                if (this.reverse) {
                    return _.orderBy(this.filteredNoReplyTicketList, this.sortKey, 'asc');
                } else {
                    return _.orderBy(this.filteredNoReplyTicketList, this.sortKey, 'desc');
                }
        }
    },
    methods: {
        goToHelpDeskTicket: function(data) {
            window.open(this.$config.urlToHelpdesk + data, '_blank');
        },
        copyUrlToClipboard: function(data) {
            navigator.clipboard.writeText(this.$config.urlToHelpdesk + data);
        },
        getStatusBadge: function(status) {
            var badge = 'badge badge-success';

            if(status == 'Open'){
                badge = 'badge bg-green';
            }

            if(status == 'Cancelled'){
                badge = 'badge bg-red text-white';
            }

            if (status == 'Pending') {
                badge = 'badge bg-yellow'
            }

            return badge;
        },
        sortBy(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
            this.sortKey = sortKey;
        },
        showTicketTech: function(ticket) {
            if ((ticket.techFirstName && ticket.techLastName) !== null) {
                return `${ticket.techFirstName} ${ticket.techLastName}`;
            } else {
                ticket.techFirstName = 'No Tech assigned';
                ticket.techLastName = '';
            }
        },
        showTicketClient: function(ticket) {
            if ((ticket.clientFirstName && ticket.clientLastName) !== null) {
                return `${ticket.clientFirstName} ${ticket.clientLastName}`;
            } else {
                ticket.clientFirstName = 'No Client assigned';
                ticket.clientLastName = '';
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        width: calc(100px + 100em);
    }

    .th-width {
        width: calc(100px + 30em);
    }

    .th-options {
        width: 4%;
    }

}

.sort-col {
    cursor: pointer;
}

.th-options, .td-options {
    cursor: pointer !important;
        text-align: center;
        
}

.sort-col {
    cursor: pointer;
}

.td-pointer {
    cursor: pointer !important;
}

.table-hover td {
    cursor: default;
}

.td-button:hover {
    color: red;
}

.td-options .dropdown-menu .dropdown-item:hover {
    color: red;
}

</style>
