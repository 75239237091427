<template>
  <WidgetLayout
    :heading="'Services Summary'"
    :icon="'fa fa-server'"
    :showFooterButtons="false"
  >
    <div class="table-responsive">
      <DataLoader :loadingText="'Loading Services...'" v-if="!segmentsLoaded" />
      <table class="table table-hover" v-if="Object.keys(segments).length > 0">
        <thead class="text-left">
          <tr class="header-text">
            <th>Segment</th>
            <th>No. of Services</th>
            <th>Total (ex VAT)</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="segment in Object.keys(segments)"
            :key="segment"
            @click="viewSegment(segment)"
            v-tooltip="`View ${segment}`"
          >
            <td>
              <small>{{
                segment.includes("None") ? "Unspecified" : segment
              }}</small>
            </td>
            <td>
              <small>{{ segments[segment] }}</small>
            </td>
            <td>
              <small>{{ currencyFormat(getSegmentPrice(segment)) }}</small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </WidgetLayout>
</template>

<script>
import WidgetLayout from "./WidgetLayout";
import DataLoader from "@/components/GeneralComponents/DataLoader";

export default {
  components: {
    DataLoader,
    WidgetLayout,
  },
  data() {
    return {
      clientNumber: null,
      breakdown: {
        segmentPrices: [],
      },
      segments: [],
      segmentsLoaded: false,
      sales: [],
      saleSearchInput: "",
      productSegments: [],
    };
  },
  created() {
    this.clientNumber = this.$route.params.clientNumber;
  },
  async mounted() {
    await this.getSegments();
    await this.$http
      .get(
        this.$config.aimsAPI +
          "sales/client/" +
          this.clientNumber +
          "/price/breakdown"
      )
      .then(
        (response) => {
          this.breakdown = response.data;
        },
        (error) => {
          this.showError("Error fetching segment prices");
          console.error(error);
        }
      );
  },
  methods: {
    getSegmentPrice(segment) {
      const sp = this.breakdown.segmentPrices.find((p) => p.segment == segment);
      if (sp) {
        return sp.amountExclVat;
      }
    },
    getSegments() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "sales/client/" +
            this.clientNumber +
            "/segments"
        )
        .then(
          (response) => {
            this.segments = response.data;
            this.segmentsLoaded = true;
          },
          (error) => {
            console.error(error);
            this.showError(
              "Error Fetching Classification Segments for client",
              error
            );
          }
        );
    },
    viewSegment: function (segment) {
      if (this.userContainsPermission(["STAFF"])) {
        this.$router.push({
          name: "clientsales",
          params: { clientNumber: this.$route.params.clientNumber },
          query: { segment: segment },
        });
      } else {
        this.$router.push({
          name: "allservices",
          params: { clientNumber: this.$route.params.clientNumber },
          query: { name: segment },
        });
      }
    },
    viewSale: function (sale) {
      const productRoute = this.getProductDefinition(
        sale.product.productDefinition
      );
      if (productRoute == -1) {
        if (this.userContainsPermission(["STAFF"])) {
          window.open(
            `https://staff.adept.co.za/portal/staff#!sale.LIST/saleNumber=${sale.saleNumber}`
          );
        }
      } else {
        this.$router.push({
          name: productRoute.routeName,
          params: {
            saleNumber: sale.saleNumber,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.table-responsive {
  height: 255px !important;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-text {
  font-size: 0.84em;
}
</style>
