<template>
    <Modal
        :title="modalTitle"
        ref="createNewTicket"
        :saveNeeded="true"
        :saveText="'Create'"
        @save="uploadTicket()"
        :size="'modal-xl'"
    >
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <h4>Details</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Subject</label>
                            <input type="text" class="form-control" v-model="createTicketData.subject">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Request Type</label>
                            <v-select
                                name="lteProduct" :options="requestTypes" 
                                label="name" valueProp="value" 
                                class="mb-2" v-model="createTicketData.type"
                            >
                            </v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Request Detail</label>
                            <textarea class="form-control" rows="5" v-model="createTicketData.detail"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <h4>Attachments</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group image-upload-container">
                                <input type="file" id="file_upload" class="form-control" multiple placeholder="" @change="fileSelected">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">File Name</th>
                                        <th scope="col">File Size</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody v-if="showFiles">
                                    <tr v-for="file in formData.values()" :key="file.name">
                                        <th><label>{{file.name}}</label></th>
                                        <td><label>{{sizeBytesToKb(file.size)}} KB</label></td>
                                        <td style="text-align:center">
                                            <button                
                                                v-tooltip="'Remove File'"
                                                type="button"
                                                @click="removeFile(file.name)"
                                                class="btn btn-sm btn-primary"
                                            >
                                                <em class="fa fa-times fa-fw"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal,
    },
    props: {
        modalTitle: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            createTicketData: {
                subject: '',
                type: 0,
                detail: '',
            },
            requestTypes: [],

            formData: new FormData(),
            selectedFiles: [],
            showFiles: true,
        }
    },
    created() {
        this.loadRequestTypes();
    },
    methods: {
        open: function() {
            this.$refs.createNewTicket.open();
        },
        resetUpload: function () {
            this.formData = new FormData();
            this.createTicketData = {
                subject: '',
                type: 0,
                detail: '',
            }
        },
        loadRequestTypes() {
            this.$http.get(this.$config.aimsAPI + "helpdesk/requestTypes").then(
                (response) => {
                    for (let i = 0; i < Object.keys(response.data).length; i++) {
                        this.requestTypes.push({"name": Object.keys(response.data)[i], "value": Object.values(response.data)[i]})
                    }           
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching Request Types", error);
                }
            );
        },
        removeFile(data) {
            this.formData.delete(data);
            this.showFiles = false;
            this.showFiles = true;
        },

        fileSelected(event) {
            this.showFiles = false;
            this.showFiles = true;

            this.selectedFiles = event.target.files;
            let largeFiles = [];

            for (let i = 0; i < this.selectedFiles.length; i++) {
                if (!this.formData.has(this.selectedFiles[i].name)) {
                    if (this.selectedFiles[i].size / 1024 / 1024 < 15) {
                        this.formData.append(this.selectedFiles[i].name, this.selectedFiles[i]);
                    } else {
                        largeFiles.push(this.selectedFiles[i].name);
                    }
                }
            }

            if (largeFiles.length > 0) {
                let temp = "";
                for (let i = 0; i < largeFiles.length; i++) {
                    if (i == largeFiles.length - 1) {
                        temp += largeFiles[i]
                    } else if (i == largeFiles.length - 2) {
                        temp += largeFiles[i] + " and "
                    } else {
                        temp += largeFiles[i] + ", "
                    }
                }
                if (largeFiles.length > 1) {
                    temp += ' were too large.'
                } else {
                    temp += ' was too large.'
                }

                this.$swal({
                    title: 'Maximum file size is 15MB',
                    text: temp,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        },
        uploadTicket() {
            this.$refs.createNewTicket.isLoading();

            const formData = new FormData();
            let filesSelected = [];

            for (var value of this.formData.values()) {
                filesSelected.push(value);
            }
            
            for (var value of filesSelected.values()) {
                formData.append('attachments', value);
            }

            formData.append('request', JSON.stringify(this.createTicketData));

            this.$http.post(this.$config.aimsAPI + 'helpdesk/' + this.$route.params.clientNumber,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.$emit('updateTicketList', response.data);
                this.showSuccess("Ticket Processed Successfully");
                this.resetUpload();
                this.$refs.createNewTicket.close();
            },
            error => {
                this.resetUpload();
                console.error(error);
                this.showError('Error Processing Ticket', error);
            }).finally(() => {
                this.$refs.createNewTicket.close();
            });
        },
        sizeBytesToKb(value) {
            if (value && value > 0) {
                return (value / 1024).toFixed(2)
            } else {
                return 0;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.image-upload-container {
    height: 120px;
    margin-bottom: 2.2rem;
    margin-top: 1.8rem;
    border-radius: 2px;
    border: 2px dashed #23B7E5;
    background: #f1f1f1;
    padding: 0 .5em;

    .form-control {
        color: transparent;
        width: 100%;
        height: 100%;
        background: #f1f1f1;
        background-image: url("/img/components/drag-drop-image-upload@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        border: none;
        cursor: pointer;

        &::file-selector-button {
            display: none;
        }
    }
}
</style>