<template>
  <div>
    <LoginLayout
      :loginHeading="'Sign In'"
      :primaryButtonText="'Login'"
      :loginErrorMessage="loginError.message"
      :showErrorMessage="loginError.show"
      :showSwitchUser="showSwitchUser"
      :logUserIn="logUserIn"
      :showForgotPassword="showForgotPassword"
      @submit="loginToServicePanel"
      @switchUser="switchUser"
      @loginProvider="loginWithProvider"
    >
      <form class="" @keyup.enter="loginToServicePanel()">
        <div class="">
          <div class="row">
            <!-- <label>Email</label> -->
            <div class="col-12">
              <div class="input-group">
                <input type="text" class="form-control" :class="'input-bg' ? showSwitchUser: ''" placeholder="Email" v-model="loginCredentials.userName" :disabled="showSwitchUser" required>
                <div class="input-group-append">
                  <span class="input-group-text bg-white"><i class="fa fa-envelope-o"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <!-- <label>Password</label> -->
            <div class="col-12">
              <div class="input-group">
                <input type="password" class="form-control input-bg" ref="password" id="passwordInput" placeholder="Password" v-model="loginCredentials.password" required>
                <div class="input-group-append">
                  <span class="input-group-text bg-white"><i class="fa fa-lock"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row row-spacing">
          <div class="col-md-6">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" @click="showPassword()">
              <label class="form-check-label text-white">
                Show Password
              </label>
            </div>
          </div>
        </div> -->
      </form>
    </LoginLayout>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout';
  export default {
    components: {
      LoginLayout
    },
    data() {
      return {
        loginCredentials: {
          userName: "",
          password: "",
        },
        loginError: {
          message: "",
          show: false
        },
        logUserIn: false,
        showSwitchUser: false,
        showForgotPassword: true,
        rows: new Array(20).fill(0),
      }
    },
    mounted() {
      document.title = "Login | Service Panel"
      const redirectToken = this.$route.query.token;
      this.showSwitchUser === false ? this.$nextTick(() => this.$refs.password.focus()) : null;
      this.$localStorage.remove("otp");
      this.$localStorage.remove("qrc");
      
      /* if (!this.otpRequired && this.showSwitchUser === true) {
        return this.showForgotPassword = true;
      } */

      this.getProviderErr();
      
      if (redirectToken) {
        const redirectDetail = atob(redirectToken);
        const redirectDetails = redirectDetail.split(":");
        this.$localStorage.set("temp-token", redirectDetails[0]);
        
        this.$localStorage.remove("uli");

        this.goToSale(redirectDetails[1]);
      } else {
        this.fromRoute = this.$route.params.fromRoute;

        let previousUsername = this.$localStorage.get("uli");

        if (previousUsername) {
          this.loginCredentials.userName = atob(previousUsername);
          this.showSwitchUser = true;
        }

        if (this.$route.query.tokenIssue === "1") {
          this.loginError.message = "You have been logged out due to an Authentication issue, please try logging in again.";
          this.loginError.show = true;
        } else {
          const storedToken = this.$localStorage.get("aims-token");
          if (storedToken) {
            this.goToServicePanelDashboard();
          };
        };
      };
      
    },
    methods: {
      loginToServicePanel: function() {
        this.loginError.show = false;
        this.logUserIn = true;

        this.$http.post(this.$config.aimsAPIv2 + "login", this.loginCredentials).then(
          (response) => {
            this.logUserIn = false;
            
            this.$localStorage.set("uli", btoa(this.loginCredentials.userName));
            this.$localStorage.set("pli", btoa(this.loginCredentials.password));
            this.$localStorage.set("otp", response.body.status);

            if (response.body.status == "TOTP_ONBOARD") {
              this.$localStorage.set("qrc", response.body.qrCode);
            }

            if (response.body.status == "SUCCESSFUL") {
              this.$localStorage.set("aims-token", response.body.token);
              this.$localStorage.set("uli", btoa(this.loginCredentials.userName));

              this.$localStorage.remove("pli");
              this.$localStorage.remove("otp");
              this.$localStorage.remove("qrc");
              
              this.$router.replace({
                  name: "dashboard",
                });
            } else {
              this.$router.replace({
                name: "otp",
              });
            }
            
          },
          (response) => {
            if (response.status === 0) {
              response.data = {
                message: "Could not contact server"
              };
            }
            
            this.loginError.message = response.data.message;
            this.loginError.show = true;
            this.logUserIn = false;
          }
        );
      },
      loginWithProvider: function(provider) {
        this.$http.get(this.$config.aimsAPIv2 + "login/oauth2/url/" + provider).then(
          (response) => {
            window.location.href = response.data;
          },
          (error) => {
            console.error('Error fetching authorization URL:', error);
          }
        );
      },
      getProviderErr() {
        const provErr = this.$localStorage.get("prov-err");
        if (provErr) {
          this.loginError.show = true;
          this.loginError.message = provErr;
          this.$localStorage.remove("prov-err"); 
        };
      },
      switchUser: function() {
        this.loginCredentials.userName = "";
        this.loginCredentials.password = "";
        this.showSwitchUser = false;
        this.fromRoute = null;
        this.$localStorage.remove("uli");  
        this.$localStorage.remove("pli");  
        this.$localStorage.remove("otp");  
      },
      goToServicePanelDashboard: function() {
        this.$router.replace({
          name: "dashboard",
        })
      },
      goToSale: function(saleNumber) {
        this.initAuth();
        this.$router.replace({
          name: "search",
          query: {
            saleNumber: saleNumber,
          }
        })
      },
      /* showPassword: function() {
        let passwordInput = document.getElementById("passwordInput");
        if (passwordInput.type === "password") {
          passwordInput.type = "text";
        } else {
          passwordInput.type = "password";
        }
      }, */
    },
  }
</script>
<style scoped>
  .input-bg {
    background: #ffffff;
  }

  .background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 150vw;
    height: 150vh;
    overflow: hidden;
    transform: rotate(30deg) scale(2);
  }

  .background-row {
    width: 200%;
    height: 50px;
    background-image: url('/img/app-load-icon.png');
    background-repeat: repeat-x;
    background-size: contain;
    opacity: 0.3;
  }

  .move-right {
    animation: moveRight 20s linear infinite;
  }

  .move-left {
    animation: moveLeft 20s linear infinite;
  }

  @keyframes moveRight {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 100px; /* Adjust speed and distance */
    }
  }

  @keyframes moveLeft {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: -100px; /* Move in the opposite direction */
    }
  }

</style>
