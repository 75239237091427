<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <button 
                    class="btn btn-info" 
                    type="button" 
                    v-if="userEditRight" 
                    @click="createNewPackage"
                >
                    Create New Package
                </button>
            </div>
        </div>
        <div class="row row-spacing mb-3">
            <div class="col-md-12">
                <div class="table-responsive table-bordered" v-if="basicPackages.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Setup fee (excl. VAT)</th>
                                <th>MRC (excl. VAT)</th>
                                <th>Expired</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="basicPackage in basicPackages"
                                :key="basicPackage.basicPackageNumber"
                                :class="{ 'bg-primary': basicPackage.expired }"
                            >
                                <td>{{ basicPackage.description }}</td>
                                <td>{{ getSetupFeeExclVat(basicPackage.prices, period) }}</td>
                                <td>
                                    {{ getPrice(basicPackage.prices, period) }}
                                    <span
                                        v-if="userEditRight"
                                        style="cursor: pointer"
                                        @click="openPriceModal(basicPackage)"
                                        v-tooltip="'Edit Row Price'"
                                        class="pull-right"
                                    >
                                        <em class="fa fa-edit fa-fw text-green"></em>
                                    </span>
                                </td>
                                <td>
                                    <span class="badge" :class="isExpired(basicPackage)">
                                        {{ basicPackage.expired }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <button 
                                        type="button" 
                                        v-if="userEditRight" 
                                        class="btn btn-sm btn-info" 
                                        @click="editPackage(basicPackage)" 
                                        v-tooltip="'Edit Package'"
                                    >
                                        <em class="fa fa-edit fa-fw"></em>
                                    </button>
                                    <button 
                                        type="button" 
                                        v-if="userEditRight" 
                                        class="btn btn-sm btn-primary" 
                                        @click="deletePackage(basicPackage)" 
                                        v-tooltip="'Delete Package'"
                                    >
                                        <em class="fa fa-trash fa-fw"></em>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="alert alert-info" v-else>
                    No CyberRisk Aware Packages Available
                </div>
            </div>
        </div>
        <AddPriceModal
            ref="addPriceModal"
            :row-price="rowPrice"
            :new-price="newPrice"
            @addPrice="addPrice"
            @pricesAdded="getCyberRiskAwarePackages"
        />
        <BasicPriceModal 
            ref="packageModal" 
            :pricePackage="packagePrice" 
            @save-price="savePackage" 
        />
    </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";

import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import BasicPriceModal from '@/components/Admin/Modals/BasicPriceModal'

    export default {
        extends: PriceFunctions,
        components: {
            AddPriceModal,
            BasicPriceModal,
        },
        props: {
            productNumber: {
                type: Number,
                required: true,
            },
            userEditRight: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                basicPackages: [],
                period: this.getCurrentPeriod(),
                rowPrice: [],
                packagePrice: {},
                newPrice: {},
                modalTitle: '',
            }
        },
        mounted() {
            this.getCyberRiskAwarePackages();
        },
        methods: {
            getCyberRiskAwarePackages: function() {
                const hostUrl = this.$config.aimsAPI;
                const productNumber = this.productNumber;
                const packageClass = "CyberRiskAwarePackage";

                this.$http.get(`${hostUrl}pricing/packages/product/${productNumber}/${packageClass}`).then(
                    (response) => {
                        this.basicPackages = response.data;                        
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Fetching CyberRisk Aware Packages", error)
                    }
                );
            },
            openNewPackageModal: function() {
                this.$refs.modifyPackageModal.open();
            },
            createNewPackage: function() {
                const hostUrl = this.$config.aimsAPI;
                const packageClass = "CyberRiskAwarePackage";
                
                this.$http.get(`${hostUrl}pricing/new/package/${packageClass}`).then(
                    (response) => {
                        this.packagePrice = response.data;
                        this.packagePrice.productNumber = this.productNumber;                        
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Fetching Price Template", error);
                    }
                );  
                this.$refs.packageModal.open();
            },
            openPriceModal: function(basicPackage) {                              
                this.rowPrice = this.deepCopyObject(basicPackage.prices);
                this.newPrice = this.deepCopyObject({
                    cyberRiskAwarePackageNumber: basicPackage.cyberRiskAwarePackageNumber,
                    class: basicPackage.class + 'Price',
                    validFrom: 0,
                    validUntil: 0
                });
                this.newPrice.setupFeeExclVat = 0.00;
                this.$refs.addPriceModal.open();
            },
            packageAdded: function(basicPackage) {
                this.basicPackages.push(basicPackage);                
                this.openPriceModal(basicPackage);
            },
            editPackage: function(basicPackage) {
                this.packagePrice = this.deepCopyObject(basicPackage);
                this.$refs.packageModal.open();
            },
            savePackage: function() {
                const hostUrl = this.$config.aimsAPI;
                const packagePrice = this.packagePrice;

                if (packagePrice.cyberRiskAwarePackageNumber) {
                    this.$http.put(`${hostUrl}pricing/packages/update`, packagePrice).then(
                        (response) => {
                            this.showSuccess("Successfully Edited Package");
                            this.getCyberRiskAwarePackages();
                        },
                        (error) => {
                            this.showError("Error Saving Package", error);
                            console.error(error);
                        }
                    )
                } else {
                    this.$http.post(`${hostUrl}pricing/packages/create`, packagePrice).then(
                        (response) => {
                            this.showSuccess("Successfully Added Package");
                            response.data.prices = [];
                            this.openPriceModal(response.data);
                        },
                        (error) => {
                            this.showError("Error Saving Package", error);
                            console.error(error);
                        }
                    )
                }
            },
            deletePackage: function(basicPackage) {
                this.$swal({
                    title: "Are you sure you want to delete this Package?",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const objKey = basicPackage.objKey;
                    const packageClass = basicPackage.class;

                    this.$http.delete(`${hostUrl}pricing/pricepackages/${objKey}?objClassName=${packageClass}`).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.getCyberRiskAwarePackages();
                        },
                        (error) => {
                            this.showError("Error Deleting Price Package", error);
                        }
                        );
                    }
                });
            },
            addPrice: function(newPrice) {      
                this.rowPrice.forEach(price => {
                    if (price.validUntil == 0) {
                        if (newPrice.validFrom % 100 > 1) {
                            price.validUntil = newPrice.validFrom - 1;
                        } else {
                            price.validUntil = (newPrice.validFrom - 100) + 11;
                        }
                    }
                });
                this.rowPrice.push(newPrice);
                this.setNewPrice();
                this.newPrice.cyberRiskAwarePackageNumber = newPrice.cyberRiskAwarePackageNumber;
            },
            setNewPrice: function() {
                this.$http.get(this.$config.aimsAPI + "pricing/new/price/cyberRiskAwarePackagePrice").then(
                        (response) => {
                            this.newPrice = response.data;                            
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error Fetching Price Template", error);
                        }
                );
            },
            isExpired: function(basicPackage) {
                return basicPackage.expired ? 'badge-warning' : 'badge-green';
            },
        },
    }
</script>
