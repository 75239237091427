<template>
    <div class="card">
        <div class="col-md-12">
            <div class="row row-spacing" v-if="loggedInUserName">
                <div class="card-header">
                    <span class="h3 d-inline">Client List of, </span><h3 class="d-inline">{{ loggedInUserName }}</h3>
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Search</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search by Client number or Client name..." v-model="searchTerm">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label>Filter by Type</label>
                    <div class="form-group">
                        <v-select 
                            :clearable="true" 
                            :options="clientTypes" 
                            v-model="type" 
                            valueProp="description"
                            label="description"
                            placeholder="Select client type"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="col-md-4" v-if="showAllClients">
                    <label>Filter by Business Segment</label>
                    <div class="form-group">
                        <v-select 
                            :clearable="true"
                            :options="clientSegments"
                            v-model="segment"
                            valueProp="description"
                            label="description"
                            placeholder="Select business segment"
                        >
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card-body">
                    <table class="table table-hover table-bordered table-responsive">
                        <thead>
                            <tr class="dark-row">
                                <th @click="sortBy('client-number')">
                                    Client Number
                                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'client-number' && reverse"></i>
                                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'client-number' && !reverse"></i>
                                </th>
                                <th @click="sortBy('name')">
                                    Client Name
                                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'name' && reverse"></i>
                                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'name' && !reverse"></i>
                                </th>
                                <th @click="sortBy('type')">
                                    Type
                                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'type' && reverse"></i>
                                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'type' && !reverse"></i>
                                </th>
                                <th @click="sortBy('segment')" v-if="showAllClients">
                                    Business Segment
                                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'segment' && reverse"></i>
                                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'segment' && !reverse"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="filteredClients.length > 0">
                            <tr 
                                v-for="client in filteredClients"
                                :key="client.clientNumber"
                                @click="goToClient(client)"
                                v-tooltip="'Go to Client'"
                            >
                                <td>{{ client.clientNumber }}</td>
                                <td>{{ client.toString }}</td>
                                <td>{{ client.type }}</td>
                                <td v-if="showAllClients">{{ showClientSegments(client) }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="loadingState">
                            <tr>
                                <td colspan="4">
                                    <DataLoader 
                                        :loadingText="loadingText"
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="4">
                                    No clients to display
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataLoader from '@/components/GeneralComponents/DataLoader'

    export default {
        components: {
            DataLoader
        },
        props: {
            clientsList: {
                type: Array,
                required: true
            },
            loadingState: {
                type: Boolean,
                required: true
            },
            loadingText: {
                type: String,
                required: false
            },
            loggedInUserName: {
                type: String,
                required: false
            },
            showAllClients: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                clientTypes: [],
                clientSegments: [],
                type: '',
                segment: '',
                searchTerm: '',
                filteredClientsList: [],
                sortKey: '',
                reverse: false,
            }
        },
        mounted() {
            this.getClientEnums();
            this.getClientSegments();
        },
        computed: {
            filteredClients() {
                this.filteredClientsList = this.clientsList;

                    if (this.searchTerm) {                    
                        this.filteredClientsList = this.filteredClientsList.filter((client) =>                       
                            client.clientNumber.toString().includes(this.searchTerm) ||
                            client.toString.toLowerCase().includes(this.searchTerm.toLowerCase())
                        );                
                    };

                    if (this.type) {        
                        this.filteredClientsList = this.filteredClientsList.filter((client) => client.type === this.type); 
                    }

                    if (this.segment) {        
                        this.filteredClientsList = this.filteredClientsList.filter((client) => client.clientsegment.description === this.segment); 
                    }

                    return this.filteredClientsList;
            }
        },
        methods: {
            getClientEnums: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}clients/enums`).then(
                    (response) => {
                        this.clientTypes = response.data.type;
                    },
                    (error) => {
                        this.showError('Error fetching Client enums');
                        console.error(error);
                    }
                )
            },
            getClientSegments: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}clients/segments`).then(
                    (response) => {
                        this.clientSegments = response.data;
                    },
                    (error) => {
                        this.showError('Error fetching Client segments');
                        console.error(error);
                    }
                )
            },
            goToClient: function(client) {
                const clientLink =`${location.origin}/main/client/${client.clientNumber}/dashboard`;
                window.open(clientLink);
            },
            showClientSegments: function(client) {
               return Object.keys(client.clientsegment).length !== 0 ? client.clientsegment.description : client.clientsegment.description = 'No business segment assigned';
            },
            sortBy: function(sortKey) {
                this.sortKey = sortKey;                           

                if (sortKey === this.sortKey) {
                    this.reverse = !this.reverse;
                    this.filteredClients.reverse();
                } 
            },
        }
    }
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        width: calc(100px + 100em);
    }

    .th-width {
        width: calc(100px + 30em);
    }

    .sort-col {
        cursor: pointer;
    }
}

.table-responsive {
  max-height: calc(90vh - 250px) !important;
}
</style>