<template>
  <service-screen-layout :parts="parts">
    <template v-slot:details>
        <div class="row">
            <div class="col-md-7">
                <div class="card-header" >
                    <h4>Service Details</h4>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Service ID: </label>
                                <input type="text" class="form-control col-md-12" v-model="saleDefinition.definition.layer3service.serviceId">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">IPV4 Range: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.ipv4Range">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">IPV6 Range: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.ipv6Range">
                            </div>
                        </div>
                    </div>
                    <div class="row" v-allowed:view="['STAFF']">
                        <div class="col-md-12">
                            <div class="form-group">
                                <button class="btn btn-success" @click="saveSale()" type="button">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card card-default">
                    <div class="card-header">
                        <h4>{{saleDefinition.definition.layer3service.servicePackage.description}}</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                Details:
                            </div>
                            <div class="col-md-8">
                                <strong>{{saleDefinition.definition.layer3service.servicePackage.toString}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-slot:address>
        <div class="row">
            <div class="col-md-8">
                <div class="card-header" >
                    <h4>Address Details</h4>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Address Type: </label>
                                <v-select
                                    name="Address Type"
                                    label="description"
                                    valueProp="name"
                                    v-model="saleDefinition.definition.layer3service.serviceAddress.addressType"
                                    v-if="saleDefinition.product.productDefinition == 'za.co.adept.aims.product.layer3.OpenserveOfficeConnectService'"
                                    :options="saleDefinition.supportingEnums.OpenserveOfficeConnectAddress.addressType"
                                >
                                </v-select>
                                <v-select
                                    name="Address Type"
                                    label="description"
                                    valueProp="name"
                                    v-model="saleDefinition.definition.layer3service.serviceAddress.addressType"
                                    v-if="saleDefinition.product.productDefinition == 'za.co.adept.aims.product.layer3.LiquidBroadbandService'"
                                    :options="saleDefinition.supportingEnums.LiquidBroadbandAddress.addressType"
                                >
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label">Sub Premise: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.serviceAddress.subpremise">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label">Street Number: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.serviceAddress.streetNumber">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Street Name: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.serviceAddress.streetName">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Suburb: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.serviceAddress.suburb">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">City: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.serviceAddress.city">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label">Province: </label>
                                <select
                                    name="Province"
                                    class="form-control"
                                    v-model="saleDefinition.definition.layer3service.serviceAddress.province"
                                >
                                    <option value="Eastern Cape">Eastern Cape</option>
                                    <option value="Western Cape">Western Cape</option>
                                    <option value="Northern Cape">Northern Cape</option>
                                    <option value="North West">North West</option>
                                    <option value="Freestate">Freestate</option>
                                    <option value="Gauteng">Gauteng</option>
                                    <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                    <option value="Limpopo">Limpopo</option>
                                    <option value="Mpumalanga">Mpumalanga</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label">Postal Code: </label>
                                <input type="text" class="form-control col-md-12"
                                v-model="saleDefinition.definition.layer3service.serviceAddress.postalCode">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Country: </label>
                                <select
                                    name="Country"
                                    class="form-control"
                                    v-model="saleDefinition.definition.layer3service.serviceAddress.country"
                                >
                                    <option value="South Africa">South Africa</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-allowed:view="['STAFF']">
                        <div class="col-md-12">
                            <div class="form-group">
                                <button class="btn btn-success" @click="saveSale()" type="button">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
  </service-screen-layout>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
  },
  data() {
    return {
      parts: [
        {
          name: "details",
          icon: "fa fa-info-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "address",
          icon: "fa fa-truck",
          displayName: "Address",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
            layer3service: {
                serviceId: '',
                ipv4Range: '',
                ipv6Range: '',
                servicePackage: {},
                serviceAddress: {},
            },
        },
        product: {
            productDefinition: '',
        },
        supportingEnums: {
            OpenserveOfficeConnectAddress: {
                addressType: [],
            },
            LiquidBroadbandAddress: {
                addressType: [],
            }
        }
      },
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.$route.params.saleNumber).then(
        (success) => {
            this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
    },
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
  },
};
</script>