<template>
    <div>
      <slot>
        <div class="row row-spacing mb-2">
          <div class="col-md-auto">
            <button
              v-if="userEditRight"
              type="button"
              @click="
                pricePackage = deepCopyObject(emptyPackage);
                $refs.modifyPackageModal.open();
              "
              class="btn btn-info"
            >
              Add New Package
            </button>
          </div>
        </div>
      </slot>
      
      <div
        class="table-responsive table-bordered"
        v-if="virtualServerPackages.length > 0"
      >

            <table class="table" ref="table">
                <thead>
                    <tr class="text-nowrap">
                        <th>Description</th>
                        <th>Label</th>
                        <!-- <th>Data Bundle Size</th> -->
                        <th>Setup Fee</th>
                        <th>Price (excl. VAT)</th>
                        <th>Incl. VAT</th>
                        <th>Expired</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr :class="{'bg-primary' : virtualServerPackage.expired}" v-for="virtualServerPackage in virtualServerPackages" :key="virtualServerPackage.virtualServerPackageNumber">
                        <td>{{ virtualServerPackage.description }}</td>
                        <td>{{ virtualServerPackage.label }}</td>
                        <!-- <td class="text-nowrap">{{ sizeBytesToGb(virtualServerPackage.dataBundleSize) }} GB</td> -->
                        <td>
                            <span>{{  getSetupFee(virtualServerPackage.virtualserverpackageprices, period)  }}</span>
                        </td>
                        <td>
                            {{getPrice(virtualServerPackage.virtualserverpackageprices, period)}}
                            <span v-if="userEditRight" style="cursor: pointer" @click="openPriceModal(virtualServerPackage)" v-tooltip="'Edit Row Price'" class="pull-right">
                                <em class="fa fa-edit fa-fw text-green"></em>
                            </span>
                        </td>
                        <td class="text-nowrap">{{getPrice(virtualServerPackage.virtualserverpackageprices, period, true)}}</td>
                        <td>
                            <span
                                class="badge"
                                :class="
                                virtualServerPackage.expired ? 'badge-warning' : 'badge-green'
                                "
                                >{{ virtualServerPackage.expired }}
                            </span>
                        </td>
                        <td class="text-center">
                            <button v-if="userEditRight" v-tooltip="'Edit Package'" type="button" @click="editPackage(virtualServerPackage)" class="btn btn-sm btn-info m-1">
                                <em class="fa fa-edit fa-fw"></em>
                            </button>
                            <button v-if="userEditRight" v-tooltip="'Delete Package'" type="button" @click="deletePackage(virtualServerPackage)" class="btn btn-sm btn-primary">
                                <em class="fa fa-trash fa-fw"></em>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
      </div>
      <div v-else class="alert alert-info">No Prices Available to Display</div>
    
      <AddNewVirtualServerPackageModal 
            ref="modifyPackageModal" 
            :newPricePackage="pricePackage" 
            @packageAdded="packageAdded" 
            @packageEdited="$emit('get-virtualserver-packages')" />
      <AddPriceModal 
            ref="addPriceModal" 
            :row-price="rowPrice" 
            :new-price="newPrice" 
            @addPrice="addPrice" 
            @pricesAdded="$emit('get-virtualserver-packages')" />

    </div>
  </template>
  
  <script>
  import PriceFunctions from "@/components/Admin/PriceFunctions";
  import AddNewVirtualServerPackageModal from '@/components/Admin/Modals/AddNewVirtualServerPackageModal';
  import AddPriceModal from '@/components/Admin/Modals/AddPriceModal';

  export default {
    extends: PriceFunctions,
    components: {
        AddNewVirtualServerPackageModal,
        AddPriceModal
    },
    props: {
        virtualServerPackages: {
         type: Array,
          required: true,
        },
        productNumber: {
         type: Number,
         required: true,
        },
        emptyPackage: {
         type: Object,
          required: true,
        },
        userEditRight: {
          type: Boolean,
          required: true
        }     
    },
    data() {
      return {
        period: this.getCurrentPeriod(),
        rowPrice: [],
        pricePackage: {},
        newPrice: {}
      };
    },
    mounted() {

    },
    methods: {
        setNewPrice() {
            this.newPrice = this.deepCopyObject(this.emptyPackage.virtualserverpackageprice);
        },
        openPriceModal(virtualServerPackage) {
            this.setNewPrice();
            this.rowPrice = this.deepCopyObject(virtualServerPackage.virtualserverpackageprices || []);
            this.newPrice.virtualServerPackageNumber = virtualServerPackage.virtualServerPackageNumber;
            this.$refs.addPriceModal.open();
        },
        addPrice(newPrice) {
            this.rowPrice.forEach(price => {
                if (price.validUntil == 0) {
                    price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
                }
            });
            this.rowPrice.push(newPrice);
            this.setNewPrice();
            this.newPrice.virtualServerPackageNumber = newPrice.virtualServerPackageNumber;
        },
        packageAdded(vPackage) {
            vPackage.virtualserverpackageprices = [];
            this.virtualServerPackages.push(vPackage);
            this.openPriceModal(vPackage);
        },
        editPackage(vPackage) {
            this.pricePackage = this.deepCopyObject(vPackage);
            this.pricePackage.virtualRam = this.sizeBytesToGb(this.pricePackage.virtualRam);
            this.pricePackage.diskSpace = this.sizeBytesToGb(this.pricePackage.diskSpace);
            this.pricePackage.dataBundleSize = this.sizeBytesToGb(this.pricePackage.dataBundleSize);
            this.$refs.modifyPackageModal.open()
        },
        savePackage(virtualServerPackage) {
            this.saveData(virtualServerPackage).then(() => {
                    this.showSuccess('Successfully Saved Package');
                    this.loadvirtualServerPackages();
                },
                error => {
                    this.showError('Error saving Package', error)
                });
        },
        deletePackage(virtualServerPackage) {
            this.$swal({
                title: 'Are you sure you want to delete this Package?',
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.$http.delete(this.$config.aimsAPI + 'pricing/pricepackages/' + virtualServerPackage.objKey +
                        '?objClassName=' + virtualServerPackage.class).then(response => {
                        this.showSuccess(response.data.message);
                        this.$emit("get-virtualserver-packages");
                    }, error => {
                        this.showError("Error Deleting Price Package", error);
                    });
                }
            });
        }
    },
  };
  </script>
  