<template>
  <div>
    <div class="row row-spacing my-3">
      <div class="col-md-6">
        <button v-if="userEditRight" type="button" @click=" pricePackage = deepCopyObject(emptyPackage); $refs.modifyPackageModal.open();" class="btn btn-info">
          Add New Package
        </button>
      </div>
      <div class="col-md-6 text-right">
          <button class="btn bg-green" @click="showFilters">
            <i class="fa-solid fa-chevron-left" :class="!clicked ? 'fa-chevron-left' : 'fa-chevron-right'"></i> 
            Filter
          </button>
          <div class="filter-container" v-show="clicked">
            <div class="filter-wrapper">
              <div class="filter-body">
                <div class="row">
                    <div class="col-md-12">
                      <label class="form-check-label" for="exampleRadios1">
                        Show expired packages as well
                      </label>
                      <input class="form-check-input ml-2 mt-1" type="checkbox" name="exampleRadios" id="exampleRadios1" value="option1" @change="toggleShowExpiredPackages" :checked="isChecked">
                    </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                        <label><strong>Search Packages</strong></label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search (Description, Contract Term)..." v-model="searchTerm">
                            <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="table-responsive table-bordered" v-if="cappedPackages.length > 0 && packageSalesData.length > 0">
      <table class="table" ref="table">
        <thead>
          <tr class="text-nowrap">
            <th class="table-font-size table-header-width-6">Description</th>
            <th class="table-font-size table-header-width-6">Label</th>
            <th class="table-font-size table-header-width-3">Download / Upload</th>
            <th class="table-font-size table-header-width-1">Size</th>
            <th class="table-font-size table-header-width-4">Setup Fee</th>
            <th class="table-font-size table-header-width-4">Price (excl. VAT)</th>
            <th class="table-font-size table-header-width-4">Incl. VAT</th>
            <th class="table-font-size table-header-width-4">Term</th>
            <th class="table-font-size table-header-width-3">Expired</th>
            <th class="table-font-size table-header-width-2">Services</th>
            <th class="table-font-size table-header-width-1">#</th>
            <th class="table-font-size table-header-width-5"></th>
          </tr>
        </thead>
        <tbody>
          <tr :class="{ 'bg-primary': connectivityPackage.expired }" v-for="connectivityPackage in cappedPackages" :key="connectivityPackage.connectivityPackageNumber">
            <td>
              <strong>
                  <span class="font-size">{{ connectivityPackage.description }}</span>
              </strong>
            </td>
            <td>
                  <span class="font-size">{{ connectivityPackage.label }}</span>
            </td>
            <td>
              <span class="font-size">
                {{ speedBitsToMbits(connectivityPackage.downloadSpeed)}} / {{ speedBitsToMbits(connectivityPackage.uploadSpeed) }} Mbps
              </span>
            </td>
            <td class="text-nowrap">
              <span class="font-size">{{ connectivityPackage.size }} GB</span>
            </td>
            <td class="text-nowrap">
              <span class="font-size">{{ getSetupFee(connectivityPackage.connectivitypackageprices, period) }}</span>
            </td>
            <td>
              <span class="font-size">{{ getPrice(connectivityPackage.connectivitypackageprices, period) }}</span>
              <span v-if="userEditRight" style="cursor: pointer" @click="openPriceModal(connectivityPackage)" v-tooltip="'Edit Row Price'" class="pull-right">
                <em class="fa fa-edit fa-fw text-green"></em>
              </span>
            </td>
            <td class="text-nowrap">
              <span class="font-size">{{ getPrice(connectivityPackage.connectivitypackageprices, period, true) }}</span>
            </td>
            <td class="text-nowrap">
              <span class="font-size">{{ connectivityPackage.contractTerm }}</span>
            </td>
            <td>
              <span class="badge" :class="connectivityPackage.expired ? 'badge-warning' : 'badge-green'">{{ connectivityPackage.expired }}</span>
            </td>
            <td class="text-nowrap text-center">
                <strong>
                  <span class="font-size">{{ servicesAmount(connectivityPackage) }}</span>
                </strong>
              </td>
            <td>
              <span class="font-size">{{ connectivityPackage.connectivityPackageNumber }}</span>
            </td>
            <td class="text-center">
              <button v-if="userEditRight" v-tooltip="'Edit Package'" @click="editPackage(connectivityPackage)" type="button" class="btn btn-sm btn-info m-1">
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button
                v-if="userEditRight" v-tooltip="'Delete Package'" @click="deletePackage(connectivityPackage)" type="button" class="btn btn-sm btn-primary">
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-info col-sm-12" v-else>
      <strong>No price packages to display</strong>
    </div>
    <ModifyCappedPackageModal
      ref="modifyPackageModal"
      :pricePackage="pricePackage"
      :shapedPools="shapedPools"
      :contractTerms="contractTerms"
      @packageAdded="packageAdded"
      @packageEdited="$emit('get-connectivity-packages')"
    />
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="$emit('get-connectivity-packages')"
    />
  </div>
</template>

<script>
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import ModifyCappedPackageModal from "@/components/Admin/Modals/ModifyCappedPackageModal";
import PriceFunctions from "@/components/Admin/PriceFunctions";

export default {
  extends: PriceFunctions,
  props: {
    userEditRight: {
      type: Boolean,
      required: true
    },
    packages: {
      type: Array,
      required: true,
    },
    productNumber: {
      type: Number,
      required: true,
    },
    emptyPackage: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddPriceModal,
    ModifyCappedPackageModal,
  },
  data() {
    return {
      period: this.getCurrentPeriod(),
      pricePackage: {},
      newPrice: {},
      rowPrice: [],
      shapedPools: [],
      contractTerms: [],
      isChecked: false,
      filteredPackages: [],
      clicked: false,
      packageSalesData: [],
      packageSales: null,
      searchTerm: '',
      getPackageSaleAmounts: true,
    };
  },
  watch: {
    searchTerm(newVal, oldVal) {      
      if ((this.isChecked || !this.isChecked) && (oldVal !== newVal)) {
        this.getPackageSaleAmounts = false;
      } 
    },
    isChecked(newVal, _) {
      if ((newVal || !newVal) && !this.getPackageSaleAmounts) {
        this.getPackageSaleAmounts = true;
      }
    }
  },
  computed: {
    cappedPackages() {     
      if (this.isChecked) {        
        this.filteredPackages = this.packages;         
      } else {
        this.filteredPackages = this.packages.filter(p => p.expired === false);
      }
      this.searchPackages(this.searchTerm); 
      this.getServiceAmounts(this.filteredPackages);
      return this.filteredPackages;
    },
  },
  mounted() {
    this.getShapedPools();
    this.getContractTerms();
  },
  methods: {
    getShapedPools: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}shapedpools`).then(
        (response) => {
          this.shapedPools = response.data;
        },
        (error) => {
          this.showError("Error fetching Shaped Pools", error);
          console.error(error);
        }
      );
    },
    getContractTerms: function() {
      const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}pricing/contractterms`).then(
        (response) => {
          this.contractTerms = response.data;
        },
        (error) => {
          this.showError("Error fetching Contract Terms", error);
          console.error(error);
        }
      );
    },
    getServiceAmounts: function(data) {     
      const hostUrl = this.$config.aimsAPI; 
      
      if (!this.searchTerm || this.getPackageSaleAmounts) {  
        this.$http.post(`${hostUrl}pricing/linkedservices/amount`, data).then(
          (response) => {
            this.packageSalesData = response.data;      
          },
          (error) => {
            this.showError("Error fetching package sales data", error);
            console.error(error);
          }
        );        
      }      
    },
    servicesAmount: function(packageData) {
      let saleAmount;        
      this.packageSalesData.filter(data => {
        if (data.connectivityPackageNumber === packageData.connectivityPackageNumber) {
          saleAmount = data.sales;
        }
      });   
      return saleAmount;
    },
    editPackage: function(connectivityPackage) {
      this.pricePackage = this.deepCopyObject(connectivityPackage);
      this.$refs.modifyPackageModal.open();
    },
    packageAdded: function(connectivityPackage) {
      connectivityPackage.connectivitypackageprices = [];
      connectivityPackage.shapedpool = this.shapedPools.find(
        (sp) => sp.shapedPoolNumber == connectivityPackage.shapedPoolNumber
      );
      this.packages.push(connectivityPackage);
      this.openPriceModal(connectivityPackage);
    },
    setNewPrice: function() {
      this.newPrice = this.deepCopyObject(
        this.emptyPackage.connectivitypackageprice
      );
    },
    openPriceModal: function(connectivityPackage) {
      this.setNewPrice();
      this.rowPrice = this.deepCopyObject(
        connectivityPackage.connectivitypackageprices
      );
      this.newPrice.connectivityPackageNumber =
        connectivityPackage.connectivityPackageNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice: function(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          if (newPrice.validFrom % 100 > 1) {
            price.validUntil = newPrice.validFrom - 1;
          } else {
            price.validUntil = newPrice.validFrom - 100 + 11;
          }
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.connectivityPackageNumber =
        newPrice.connectivityPackageNumber;
    },
    deletePackage: function(pricePackage) {
      this.$swal({
        title: "Are you sure you want to delete connectivity price package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          const hostUrl = this.$config.aimsAPI;

          this.$http.delete(`${hostUrl}pricing/pricepackages/${pricePackage.objKey}?objClassName=${pricePackage.class}`).then(
              () => {
                this.showSuccess("Successfully deleted price package");
                this.$emit("get-connectivity-packages");
              },
              (error) => {
                this.showError(
                  "Error Deleting Connectivity Pricing Package",
                  error
                );
              }
            );
        }
      });
    },
    speedBitsToMbits: function(value) {
      if (value && value > 0) {
        return value / 1000000;
      } else {
        return 0;
      }
    },
    toggleShowExpiredPackages: function() {
      this.isChecked = !this.isChecked;
    },
    showFilters: function() {
      this.clicked = !this.clicked;
    },
    searchPackages: function(searchTerm) {
      if (searchTerm) {
          this.filteredPackages = this.filteredPackages.filter(p => p.description.toLowerCase().includes(searchTerm.toLowerCase()) || p.contractTerm.toLowerCase().includes(searchTerm.toLowerCase()));
      }
    }
  },
};
</script>

<style scoped>
.filter-container {
  position: relative;
  z-index: 999;
}

.filter-container .filter-wrapper {
  position: absolute;
  z-index: 999;
  margin-top: .5em;
  /* background-color: #f5f7fa; */
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 2px;
  width: 350px;
  right: 90;
  top: -42;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.filter-container .filter-wrapper .filter-body {
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 10px;
  text-align: left;
}

th.table-font-size {
  font-size: .95rem;
}

th.table-header-width-1 {
  width: 10px;
}

th.table-header-width-2 {
  width: 20px;
}

th.table-header-width-3 {
  width: 50px;
}

th.table-header-width-4 {
  width: 60px;
}

th.table-header-width-5 {
  width: 95px;
}

th.table-header-width-6 {
  width: 180px;
}

.font-size {
  font-size: .9rem;
}
</style>