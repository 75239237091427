<template>
  <div class="card card-default p-3">
    <div class="row">
      <div class="col-md-12">
        <h4>Location</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="input-group">
          <gmap-autocomplete
            id="gmap-autocomplete"
            style="width: 100%"
            :options="placesOptions"
            @place_changed="setPlace"
          >
            <template v-slot:default="slotProps">
              <input
                type="text"
                class="form-control"
                ref="input"
                v-on:listeners="slotProps.listeners"
                v-on:attrs="slotProps.attrs"
              />
            </template>
          </gmap-autocomplete>
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fa-solid fa-location-dot"></i>
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-0 mt-1">
          <small class="mr-1">Press enter key after entering coordinates</small>
          <i class="fa-solid fa-circle-info" v-tooltip="'Only applicable if you are using coordinates to get the location'"></i>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-12">
        <gmap-map
          :center="mapControls.center"
          :zoom="mapControls.zoom"
          style="width: 100%; height: 300px"
          :options="mapOptions"
        >
          <GmapMarker
            :position="mapControls.center"
            :clickable="true"
            :draggable="true"
          />
        </gmap-map>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addressStructure: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentPlace: null,
      googleMapsGeocoder: null,
      placesOptions: {
        componentRestrictions: {
          country: "za",
        },
        fields: [
          "address_components",
          "geometry",
          "icon",
          "name",
          "formatted_address",
        ],
        strictBounds: false,
      },
      mapControls: {
        zoom: 20,
        maxZoom: 12,
        center: {
          lat: 0,
          lng: 0,
        },
      },
      mapOptions: {
        mapType: "roadmap",
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
    };
  },
  mounted() {
    this.geolocate();
  },
  computed: {
    addressObj: {
      get: function () {
        return this.addressStructure;
      },
      set: function (newValue) {
        this.addressStructure = newValue;
      },
    },
  },
  methods: {
    setPlace: function (place) {
      const containsAddress = place.hasOwnProperty("formatted_address");

      if (containsAddress) {
        const addressParts = place.address_components;
        const addressCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        
        this.$emit("addressChanged", this.createAddressObject(this.addressObj, addressParts, addressCoordinates));        
      } else {
        if (!this.googleMapsGeocoder) {
          this.googleMapsGeocoder = new google.maps.Geocoder();
        }

        const latlngStr = place.name.split(",", 2);
        const latlng = {
          lat: parseFloat(latlngStr[0]),
          lng: parseFloat(latlngStr[1]),
        };

        this.mapControls.center = new google.maps.LatLng(
          latlngStr[0],
          latlngStr[1]
        );        

        this.googleMapsGeocoder.geocode(
          { location: latlng },
          function (results, status) {
            if (status === "OK" && results[0]) {
              $("#gmap-autocomplete").val(results[0].formatted_address);
              const addressParts = results[0].address_components;
              this.$emit("addressChanged", this.createAddressObject(this.addressObj, addressParts, latlng));
            }
          }.bind(this)
        );
      }

      if (place && place.geometry) {
          return (this.mapControls.center = place.geometry.location);
      }

      this.currentPlace = place;
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapControls.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    createAddressObject: function (obj, addressPartsArr, coordinatesObj) {
      let addressObj = obj;

      addressPartsArr.forEach((part) => {
        if (part.types.includes("street_number")) {          
          addressObj.streetNumber = part.long_name;
        }

        if (part.types.includes("route")) {
          addressObj.streetName = part.long_name;
        }

        if (part.types.includes("sublocality")) {
          addressObj.suburb = part.long_name;
        }

        if (part.types.includes("locality")) {
          addressObj.city = part.long_name;
        }

        if (part.types.includes("administrative_area_level_1")) {
          addressObj.province = part.long_name;
        }

        if (part.types.includes("country")) {
          addressObj.country = part.long_name;
        }

        if (part.types.includes("postal_code")) {
          addressObj.postalCode = part.long_name;
        }

        addressObj.latitude = coordinatesObj.lat;
        addressObj.longitude = coordinatesObj.lng;
      });

      return addressObj;
    },
  },
};
</script>

<style scoped></style>
