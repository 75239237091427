<template>
  <div>
    <LoginLayout
      :loginHeading="'Enter 2FA Code'"
      :primaryButtonText="'Login'"
      :loginErrorMessage="loginError.message"
      :showErrorMessage="loginError.show"
      :showSwitchUser="true"
      :logUserIn="logUserIn"
      :customImage="qrcode"
      @submit="loginToServicePanel"
      @switchUser="switchUser"
    >
      <form class="mt-3" @submit.prevent="loginToServicePanel()" @keyup.enter="loginToServicePanel()" autocomplete="off">
        <div class="row row-spacing">
          <div class="col-md-12">
             <p class="form-text text-white" v-if="qrcode">Scan the QRCode and enter your 2FA Code to login.</p>

             <p class="form-text text-white" v-if="!qrcode">Enter the 6-digit code from your two factor authenticator app</p>
             <p class="form-text text-white" v-if="!qrcode">If you've lost access to your 2FA Code, contact your Admin.</p>
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <label class="text-white">2FA Code</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control input-bg" ref="otpInput" v-model="loginCredentials.otp">
              <div class="input-group-append">
                <span class="input-group-text bg-white"><i class="fa fa-lock"></i></span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </LoginLayout>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout';
  export default {
    components: {
      LoginLayout
    },
    data() {
      return {
        loginCredentials: {
          userName: "",
          password: "",
          otp: null
        },
        loginError: {
          message: "",
          show: false
        },
        qrcode: '',
        logUserIn: false,
        otpStatus: '',
      }
    },
    mounted() {
      document.title = "Login | Service Panel"
      const redirectToken = this.$route.query.token

      this.loginCredentials.userName = atob(this.$localStorage.get("uli"));
      this.loginCredentials.password = atob(this.$localStorage.get("pli"));
      this.otpStatus = this.$localStorage.get("otp");

      if (this.otpStatus == "TOTP_ONBOARD") {
          this.qrcode = this.$localStorage.get("qrc");
      }
      
      if (redirectToken) {
        const redirectDetail = atob(redirectToken);
        const redirectDetails = redirectDetail.split(":");
        this.$localStorage.set("temp-token", redirectDetails[0]);
        
        this.$localStorage.remove("uli");

        this.goToSale(redirectDetails[1]);
      } else {
        this.fromRoute = this.$route.params.fromRoute;

        if (this.$route.query.tokenIssue === "1") {
          this.loginError.message = "You have been logged out due to an Authentication issue, please try logging in again.";
          this.loginError.show = true;
        } else {
          const storedToken = this.$localStorage.get("aims-token");
          if (storedToken) {
            this.goToServicePanelDashboard();
          };
        };
      };
      
      this.$refs.otpInput.focus();
    },
    methods: {
      loginToServicePanel: function() {
        this.loginError.show = false;
        this.logUserIn = true;

        this.$http.post(this.$config.aimsAPIv2 + "login", this.loginCredentials).then(
          (response) => {
            this.logUserIn = false;

            if (response.body.status == "SUCCESSFUL") {

              this.$localStorage.set("aims-token", response.body.token);
              this.$localStorage.set("uli", btoa(this.loginCredentials.userName));

              this.$localStorage.remove("pli");
              this.$localStorage.remove("otp");
              this.$localStorage.remove("qrc");

              if (this.$localStorage.get("OG_URL")) {
                this.$router.replace(this.$localStorage.get("OG_URL"));
              } else {
                this.$router.replace({
                  name: "dashboard",
                });
              }

            }
          },
          (response) => {
            if (response.status === 0) {
              response.data = {
                message: "Could not contact server"
              };
            }
            
            this.loginError.message = response.data.message;
            this.loginError.show = true;
            this.logUserIn = false;
          }
        );
      },
      switchUser: function() {
        this.$router.replace({
          name: "login",
        })
      },
      goToServicePanelDashboard: function() {
        this.$router.replace({
          name: "dashboard",
        })
      },
      goToSale: function(saleNumber) {
        this.initAuth();
        this.$router.replace({
          name: "search",
          query: {
            saleNumber: saleNumber,
          }
        })
      },
    },
  }
</script>
<style scoped>
  .input-bg {
    background: #ffffff;
  }
</style>
