<template>
  <div>
    <div class="row row-spacing">
      <div class="col-md-2" v-if="heading">
        <h3>{{ heading }}</h3>
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6">
            <div class="input-group">
              <select class="form-control" v-model="packageSelected" :disabled="this.hasPending()">
                <option
                  v-for="packy in availablePackages"
                  :key="packy.objKey"
                  :value="packy.objKey"
                >
                  {{ packy.description }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <button class="btn btn-success" type="button" @click="immediateChangePackage()" :disabled="this.hasPending()">Immediate Regrade</button>
          </div>
          <div class="col-md-2">
            <button class="btn btn-success" type="button" @click="scheduleChangePackage()" :disabled="this.hasPending()">Schedule Regrade</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-12">
        <div class="table-bordered table-responsive">
          <table class="table">
            <thead>
              <tr class="dark-row">
                <th>Regrade To</th>
                <th>Description</th>
                <th>Created</th>
                <th>Status</th>
                <th>Scheduled</th>
              </tr>
            </thead>
            <tbody v-if="packageChanges.length > 0">
              <tr
                v-for="(packageChange, index) in editedPackages"
                :key="index"
                :class="{ 'new-table-row': packageChange.isNew }"
              >
                <td>{{ packageChange.connectivityPackageNumber}} </td>  
                <td>{{ packageChange.packageChange.description }}</td>
                <td>{{ packageChange.created }}</td>
                <td>{{ packageChange.status }}</td>
                <td>{{ packageChange.billingPeriod }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="8">No Regrades requested</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: false,
    },
    availablePackages: {
      type: Array,
      required: true,
    },
    packageChanges: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      packageSelected: null,
      editedPackages: []
    };
  },
  mounted() {
     for (const item of this.packageChanges) {
          item.description = this.packageDescription(item.connectivityPackageNumber); 
      };
      this.editedPackages = this.packageChanges;
    // console.log("availablePackages");
    // console.log(this.availablePackages);
  },
  methods: {
    packageDescription(pNumber) {
      let filtered = this.availablePackages;
      let found = filtered.find((packy) => packy.objKey === pNumber);
      return found.description;
    },
    scheduleChangePackage: function () {
      if (this.packageSelected) {
        const packy = this.availablePackages.find(
          (packy) => packy.objKey === this.packageSelected
        );
        this.$http
          .post(this.$config.aimsAPI + "pricing/packageprice", packy)
          .then((response) => {
            const result = response.data;
            const priceInclVAT =
              "Incl VAT " +
              this.timesVAT(parseFloat(result.priceExclVat));
            this.$swal({
              title: "Regrade effective 1st of next month?",
              text: priceInclVAT,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Regrade",
            }).then((result) => {
              if (result.value) {
                this.$emit("packageChange", packy, false);
              }
            });
          });
      }
    },
    immediateChangePackage: function () {
      if (this.packageSelected) {
        const packy = this.availablePackages.find(
          (packy) => packy.objKey === this.packageSelected
        );
        this.$http
          .post(this.$config.aimsAPI + "pricing/packageprice", packy)
          .then((response) => {
            const result = response.data;
            const priceInclVAT =
              "Incl VAT " +
              this.timesVAT(parseFloat(result.priceExclVat));
            this.$swal({
              title: "Immediate Regrades will have additional billing for this month?",
              text: priceInclVAT,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Regrade",
            }).then((result) => {
              if (result.value) {
                this.$emit("packageChange", packy, true);
              }
            });
          });
      }
    },
    hasPending: function() {
      for (const item of this.packageChanges) {
        if (item.status.toLowerCase().includes("pending".toLocaleLowerCase())) {
          return true;
        }         
      }
      return false;   
    }
  },
};
</script>

<style lang="scss" scoped>
.dark-row {

  th {
    background-color: #3a3f51;
    color: #FFFFFF;

    position: sticky;
    top: 0;
    z-index: 2;
  }
}
</style>
