<template>
  <div>
    <LoginLayout
      :loginHeading="'Change Password'"
      :primaryButtonText="'Change Password'"
      :changePasswordErrorMessage="changePasswordError.message"
      :showErrorMessage="changePasswordError.show"
      @submit="changePassword"
    >
     <form class="mt-3" @keyup.enter="changePassword()">
        <div class="row row-spacing">
          <div class="col-md-12">
            <label class="text-white">Enter your new password</label>
            <div class="input-group mb-3">
              <input type="password" v-model="loginCredentials.password" class="form-control" id="passwordInputNew" placeholder="Enter your password..." autocomplete="off" required>
              <div class="input-group-append">
                <span class="input-group-text bg-white"><i class="fa fa-lock"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <label class="text-white">Confirm your new password</label>
            <div class="input-group mb-3">
              <input type="password" v-model="confirmPassword" class="form-control" id="passwordInputConfirm" placeholder="Confirm your password..." autocomplete="off" required>
              <div class="input-group-append">
                <span class="input-group-text bg-white"><i class="fa fa-lock"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-6">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" @click="showPassword()">
              <label class="form-check-label text-white">
                Show Password
              </label>
            </div>
          </div>
        </div>
     </form>
    </LoginLayout>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout';
  export default {
    components: {
      LoginLayout
    },
    data() {
      return {
        loginCredentials: {
          password: "",
        },
        confirmPassword: "",
        changePasswordError: {
          message: "",
          show: false
        },
        changingPassword: false,
      }
    },
    mounted() {
      document.title = "Change Password | Service Panel"
    },
    methods: {
      changePassword: function() {
        this.changePasswordError.show = false;
        this.changingPassword = true;
        const hostUrl = this.$config.aimsAPI;
        const token = this.$route.params.token;
        const userCredentials = this.loginCredentials;

        if (this.loginCredentials.password != this.confirmPassword) {
          this.changePasswordError.message = "Passwords do not match";
          this.changePasswordError.show = true;
          this.changingPassword = false;          
        } else {
          this.$http.post(`${hostUrl}passwordrecovery/changepassword?token=${token}`, userCredentials).then(
            (response) => {
              this.showSuccess(response.data.message);
              this.$router.push({
                name: "login"
              });
            },
            (error) => {
              if (error.status === 0) {
                error.data = {
                  message: "Could not contact server"
                };
              };

              this.changePasswordError.message = error.data.message;
              this.changePasswordError.show = true;
            }).finally(() => {
              this.changingPassword = false;
            });
        };
      },
      showPassword: function() {
        let passwordInputNew = document.getElementById("passwordInputNew");
        let passwordInputConfirm = document.getElementById("passwordInputConfirm");

        if (passwordInputNew.type === "password" || passwordInputConfirm.type === "password") {
          passwordInputNew.type = "text";
          passwordInputConfirm.type = "text";
        } else {
          passwordInputNew.type = "password";
          passwordInputConfirm.type = "password";
        }
      },
    },
  }
</script>
