<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Billing Month:</label>
                        <v-select
                            :options="billingMonths"
                            v-model="selectedBillingMonth"
                            :get-option-label="getLabel"
                            @input="getAllAwsInvoiceEntries(selectedBillingMonth)"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Exchange Rate:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa-solid fa-arrow-right-arrow-left"></i></div>
                            </div>
                            <input type="number" class="form-control" v-model="rateOfExchange" :disabled="disableExchangeRate">
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row" v-if="showUploadButton">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <form class="mb-1">
                                    <input type="file" class="form-control" :class="{'upload-validation' : showValidation}" @change="fileSelected">
                                </form>
                                <span class="upload-validation-msg">{{ validationMessage }}</span>
                            </div>
                            <div class="col-md-6">
                                <button class="btn btn-success" @click="uploadFile()" :disabled="!isCorrectFileType || isUploading">Upload File</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="showTable">
                <AwsBillingCaptureTable 
                    :awsInvoiceEntries="awsInvoiceEntries"
                    :totalRetail="totalRetail"
                    :isPreviousBillingMonth="isPreviousBillingMonth"
                    :isEditable="true"
                    :isUploading="isUploading"
                />
            </div>
            <div class="row" v-else>
                <div class="col-md-12">
                    <div>
                        <p><strong>Select a billing month and enter exchange rate</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AwsBillingCaptureTable from './AwsBillingCaptureTable.vue';

    export default {
        components: {
            AwsBillingCaptureTable
        },
        data() {
            return {
                currentPeriod: this.getCurrentPeriod(),
                billingMonths: [],
                selectedBillingMonth: 0,
                exchangeRate: 0,
                awsInvoiceEntries: [],
                awsInvoice: {},
                awsInvoiceEntry: {},
                invoiceEntry: {},
                modalTitle: '',
                isPreviousBillingMonth: false,
                totalRetail: 0,
                isUploading: false,
                awsBillingFile: null,
                isCorrectFileType: false,
                validationMessage: "",
                hasExchangeRate: false,
            }
        },
        created() {
            this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
        },
        watch: {
            exchangeRate(newValue, oldValue) {                
                if (Number(newValue) === 0) {
                    this.hasExchangeRate = false;
                } else {
                    this.hasExchangeRate = true;
                }
            }
        },
        computed: {
            showValidation() {
                return !this.isCorrectFileType && this.awsBillingFile !== null;
            },
            showUploadButton() {              
                return this.selectedBillingMonth === this.billingMonths[0] && this.awsInvoiceEntries.length === 0 && this.hasExchangeRate;
            },
            disableExchangeRate() {
                return this.selectedBillingMonth !== this.billingMonths[0] || !this.selectedBillingMonth || this.awsInvoiceEntries.length > 0
            },
            showTable() {
                return (this.selectedBillingMonth && this.hasExchangeRate) || (this.selectedBillingMonth !== this.billingMonths[0] && !this.hasExchangeRate);
            },
            rateOfExchange: {
                get: function () {                    
                    return this.exchangeRate;
                },
                set: function (newValue) {
                    this.exchangeRate = newValue;
                }
            }
        },
        mounted() {
            this.getAwsBillingMonths();          
        },
        methods: {
            getExchangeRate: function(billingMonth) {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}aws/exchangeRate/${billingMonth}`).then(
                    (response) => {
                        this.exchangeRate = response.data;
                    },
                    (error) => {
                        console.error(error);
                        this.showError('Error fetching AWS exchange rate', error);
                    }
                );
            },
            getAwsBillingMonths: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.get(`${hostUrl}aws/billingmonths`).then(
                    (response) => {
                        this.billingMonths = response.data;                        
                    },
                    (error) => {
                        console.error(error);
                        this.showError('Error fetching AWS billing months', error);
                    }
                );
            },
            getAllAwsInvoiceEntries: function(billingMonth) {        
                const hostUrl = this.$config.aimsAPI;               

                this.$http.get(`${hostUrl}aws/invoice/entries/${billingMonth}/all`).then(
                    (response) => {                  
                        this.awsInvoiceEntries = response.data.entries;
                        
                        this.totalRetail = response.data.totalRetail;                        
                        this.isPreviousBillingMonth = this.billingMonths[0] == billingMonth;
                        this.getExchangeRate(billingMonth);
                    },
                    (error) => {
                       console.error(error);
                        this.showError('Error fetching AWS invoice entries', error);
                    }
                );
            },
            getLabel: function(period) {
                return this.prettifyBillingPeriod(period);
            },
            fileSelected: function(file) {      
                this.awsBillingFile = file.target.files[0];
                this.validateFileType(this.awsBillingFile);
            },
            validateFileType: function(uploadedFile) {
                if (uploadedFile.name.substring(uploadedFile.name.length-3, uploadedFile.name.length).toLowerCase() === 'csv' && this.awsBillingFile !== null) {
                    this.isCorrectFileType = true; 
                    this.validationMessage = '';
                } else {
                    this.isCorrectFileType = false; 
                    this.validationMessage = 'Invalid file type, only files with the following extensions are allowed: .csv'
                }
            },
            uploadFile: function() {
                this.isUploading = true;
                const hostUrl = this.$config.aimsAPI;
                const headers = { headers: {'Content-Type': 'multipart/form-data'} };
                const formData = new FormData();
                formData.append('file', this.awsBillingFile);

                this.$http.post(`${hostUrl}aws/invoice/upload?period=${this.selectedBillingMonth}&roe=${this.exchangeRate}`, formData, headers).then(
                    (response) => {
                        this.showSuccess("File upload success");
                        this.getAllAwsInvoiceEntries(this.selectedBillingMonth);
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Uploading File", error);
                    }
                ).finally(() => {
                    this.isUploading = false;
                    this.awsBillingFile = null;
                    $('input[type=file]').val(null);
                });      
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upload-validation {
        border: 1px solid #e2231a;
        color: #e2231a;
    }

    .upload-validation-msg {
        color: #e2231a;
        font-size: .9em;
    }

    .table-responsive {
        max-height: calc(90vh - 250px) !important;
    }
</style>