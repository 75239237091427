<template>
  <div>
      <WidgetLayout 
      :heading="'Helpdesk Tickets'"
      :icon="'fa fa-ticket'"
      :showFooterButtons="isClient"
      :showFooter="isClient"
      :showPrimaryButton="false"
      :infoButtonText="'View Tickets'"
      @infoButtonEvent="viewTicket"
      @primaryButtonEvent="openNewTicketModal"
    >
        <div :class="isClient ? 'table-responsive small-table': 'table-responsive'">
            <table class="table table-hover" v-if="tickets.length > 0">
              <thead class="text-left">
                <tr>
                  <th>Ticket</th>
                  <th>Subject</th>
                  <th>Status</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ticket in tickets" :key="ticket.ticketNumber" @click="viewTicketInHelpdesk(ticket.ticketNumber)" v-tooltip="'View Ticket in Helpdesk'">
                  <td>
                    <small>{{ ticket.ticketNumber }}</small>
                  </td>
                  <td>
                    <small>{{ ticket.description }}</small>
                  </td>
                  <td>
                    <span :class="getStatusBadge(ticket.status)">{{
                      ticket.status
                    }}</span>
                  </td>
                  <td class="text-nowrap">
                    <small>{{ momentAgo(ticket.created) }}</small>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else><span>No active tickets</span></div>
          </div>
    </WidgetLayout>
    <CreateHelpdeskTicket 
      ref="openCreateNewTicketModal"
      :modalTitle="modalTitle"
    />
  </div>
</template>

<script>
import WidgetLayout from './WidgetLayout';
import CreateHelpdeskTicket from '../Admin/Modals/CreateHelpdeskTicket';

export default {
  components: {
    WidgetLayout,
    CreateHelpdeskTicket
  },
  data() {
    return {
      tickets: [],
      modalTitle: '',
    };
  },
  mounted() {
    const clientNumber = this.$route.params.clientNumber;
    this.$http
      .get(this.$config.aimsAPI + "helpdesk/" + clientNumber + "/tickets/list")
      .then(
        (response) => {
          this.tickets = response.data;
        },
        (error) => {
          this.showError("Error fetching Helpdesk tickets", error);
          console.error(error);
        }
      );
  },
  computed: {
    isClient() {
      return this.userContainsPermission(["CLIENT"]);
    },
  },
  methods: {
    getStatusBadge(status) {
      var badge = "badge badge-success";
      if (status == "Open") {
        badge = "badge bg-green";
      }
      if (status == "Cancelled") {
        badge = "badge bg-red text-white";
      }
      if (status == "Pending") {
        badge = "badge bg-yellow";
      }
      return badge;
    },
    viewTicketInHelpdesk: function(data) {
      window.open(this.$config.urlToHelpdesk + data, '_blank');
    },
    viewTicket: function() {
      this.$router.push({
          name: 'helpdesktickets',
          params: {
              clientNumber: this.$route.params.clientNumber
          }
      });
    },
    openNewTicketModal: function() {
      this.$refs.openCreateNewTicketModal.open();
      this.modalTitle = 'New Ticket';
    }
  },
};
</script>

<style scoped>
.table-responsive.small-table {
  height: 200px !important;
}
.table-responsive {
  height: 255px !important;
}
</style>