<template>
    <div>
      <slot>
        <div class="row row-spacing mb-2">
          <div class="col-md-auto">
            <button v-if="userEditRight" type="button" class="btn btn-info" @click="addNewPackage()">
             Add New Package
            </button>
          </div>
        </div>
      </slot>
      <div
        class="table-responsive table-bordered"
        v-if="packages.length > 0"
      >
        <table class="table">
            <thead>
            <tr class="text-nowrap">
                <th>Description</th>
                <th>Label</th>
                <th>Setup Fee</th>
                <th>Price (excl. VAT)</th>
                <th>Expired</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="splaPackage in packages"
                :key="splaPackage.splaPackageNumber"
            >
                <td> 
                  <input disabled type="text" class="form-control" v-model="splaPackage.description"/>
                </td>
                <td>
                <input
                    :disabled="!userEditRight"
                    type="text"
                    class="form-control"
                    v-model="splaPackage.label"
                />
                </td>
                <td>
                    <span>{{  getSetupFee(splaPackage.splapackageprices, period)  }}</span>
                  </td>
                <td>
                {{
                    getPrice(splaPackage.splapackageprices, period)
                }}
                <span
                    v-if="userEditRight"
                    style="cursor: pointer"
                    @click="openPriceModal(splaPackage)"
                    v-tooltip="'Edit Row Price'"
                    class="pull-right"
                >
                    <em class="fa fa-edit fa-fw text-green"></em>
                </span> 
                </td>
                <td>
                <select
                    :disabled="!userEditRight"
                    v-model="splaPackage.expired"
                    class="form-control custom-select"
                >
                    <option>false</option>
                    <option>true</option>
                </select>
                </td>
                <td class="text-center">
                <button
                    v-if="userEditRight"
                    v-tooltip="'Save Row'"
                    type="button"
                    @click="savePackage(splaPackage)"
                    class="btn btn-sm btn-info m-1"
                >
                    <em class="fa fa-save fa-fw"></em>
                </button>
                <button
                    v-if="userEditRight"
                    v-tooltip="'Delete Row'"
                    type="button"
                    @click="deletePackage(splaPackage)"
                    class="btn btn-sm btn-primary"
                >
                    <em class="fa fa-trash fa-fw"></em>
                </button>
                </td>
            </tr>
            </tbody>
        </table>
      </div>
      <div v-else class="alert alert-info">No Prices Available to Display</div>
        
      <BasicPriceModal ref="packageModal" :pricePackage="newPricePackage" @save-price="savePackage" />
      
      <AddPriceModal
        ref="addPriceModal"
        :row-price="rowPrice"
        :new-price="newPrice"
        @addPrice="addPrice"
        @pricesAdded="$emit('get-spla-packages')"
        />
    </div>
  </template>
  
  <script>
  import PriceFunctions from "@/components/Admin/PriceFunctions";
  import BasicPriceModal from "@/components/Admin/Modals/BasicPriceModal";
  import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
  export default {
    extends: PriceFunctions,
    props: {
        userEditRight: {
          type: Boolean,
          required: true
        },
        packages: {
          type: Array,
          required: true,
        },
        emptySplaPackage: {
          type: Object,
          required: true,
        },
        productNumber: {
          type: Number,
          required: true,
        }
    },
    components: {
      BasicPriceModal,
      AddPriceModal
    },
    data() {
      return {
        newPricePackage: {},
        rowPrice: [],
        newPrice: {},
        period: this.getCurrentPeriod(),
      };
    },
    mounted() {
       
    },
    methods: {
      setNewPrice() {
        this.newPrice = this.deepCopyObject(this.emptySplaPackage.splapackageprice);
      },
      addNewPackage() {
        this.newPricePackage = this.deepCopyObject({
          productNumber: this.productNumber,
          class: "za.co.adept.aims.virtualserver.SplaPackage",
        });
        this.$refs.packageModal.open();
      },
      packageAdded(splaPackage) {
        splaPackage.splapackageprices = [];
        this.packages.push(splaPackage);
        this.openPriceModal(splaPackage);
      },
      openPriceModal(splaPackage) {
        this.setNewPrice();
        this.rowPrice = this.deepCopyObject(splaPackage.splapackageprices || []);
        this.newPrice.splaPackageNumber = splaPackage.splaPackageNumber;
        this.$refs.addPriceModal.open();
      },
      addPrice(newPrice) {
        this.rowPrice.forEach((price) => {
            if (price.validUntil == 0) {
              if (newPrice.validFrom % 100 > 1) {
                  price.validUntil = newPrice.validFrom - 1;
              } else {
                  price.validUntil = newPrice.validFrom - 100 + 11;
              }
            }
        });
        this.rowPrice.push(newPrice);
        this.setNewPrice();
        this.newPrice.splaPackageNumber = newPrice.splaPackageNumber;
      },
      savePackage(splaPackage) {  
          this.saveData(splaPackage).then(() => {
              this.showSuccess("Successfully Saved Package");
              this.$emit("get-spla-packages");
          },
          (error) => {
              this.showError("Error saving Package", error);
          }
        );
      },
      deletePackage(splaPackage) {
        this.$swal({
            title: "Are you sure you want to delete this price package?",
            type: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.value) {
            this.$http
                .delete(
                this.$config.aimsAPI +
                    "pricing/pricepackages/" +
                    splaPackage.objKey +
                    "?objClassName=" +
                    splaPackage.class
                )
                .then(
                (response) => {
                    this.showSuccess(response.data.message);
                    this.$emit("get-spla-packages");
                },
                (error) => {
                    this.showError("Error Deleting Price Package", error);
                }
                );
            }
        });
      }
    },
  };
  </script>
  