<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">

        <!-- wizard for Home PBX Service -->
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="partsPbx"
          :onComplete="onComplete"
          :validateStep="validateStep"
          v-if="homePbx"
        >
          <template slot="pbx" v-if="filledInSaleDefinition.requiredObjs.pbx">
            <form novalidate data-vv-scope="pbx">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="row">
                    <div class="col-xl-8 col-lg-12">
                      <div
                        class="form-group"
                        :class="{
                          'has-error': errors.has('pbx.description'),
                        }"
                      >
                        <label>Description:</label>
                        <input
                          type="text"
                          class="form-control"
                          v-validate="'required'"
                          name="description"
                          placeholder="Description:"
                          v-model="filledInSaleDefinition.requiredObjs.pbx.description"
                        />
                        <span class="has-error" v-if="errors.has('pbx.description')">{{
                          errors.first("pbx.description")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template slot="sip">
            <form novalidate data-vv-scope="sip">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.registrar'),
                    }"
                  >
                    <label>Registrar:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="registrar"
                      placeholder="Registrar:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.registrar"
                    />
                    <span class="has-error" v-if="errors.has('sip.registrar')">{{
                      errors.first("sip.registrar")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.username'),
                    }"
                  >
                    <label>Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="username"
                      placeholder="Username:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.username"
                    />
                    <span class="has-error" v-if="errors.has('sip.username')">{{
                      errors.first("sip.username")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.password'),
                    }"
                  >
                    <label>Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="password"
                      placeholder="Password:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.password"
                    />
                    <span class="has-error" v-if="errors.has('sip.password')">{{
                      errors.first("sip.password")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
          <template slot="did">
            <form novalidate data-vv-scope="did">

              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.number'),
                    }"
                  >
                    <label>DID Number:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="number"
                      placeholder="Number:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.number"
                    />
                    <span class="has-error" v-if="errors.has('did.number')">{{
                      errors.first("did.number")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.ported'),
                    }"
                  >
                    <label>Ported:</label>
                    <select
                      class="form-control"
                      v-validate="'required'"
                      name="ported"
                      placeholder="Ported:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.ported"
                    >
                      <option value=false>No</option>
                      <option value=true>Yes</option>
                    </select>
                    <span class="has-error" v-if="errors.has('did.ported')">{{
                      errors.first("did.ported")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
          <!--
          <template slot="extensions">
            <form novalidate data-vv-scope="extensions">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="row">
                    <div class="col-xl-8 col-lg-12">
                      <div
                        class="form-group"
                        :class="{
                          'has-error': errors.has('pbx.extensions'),
                        }"
                      >
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                              <input
                                  type="text"
                                  class="form-control"
                                  v-model="newExtension.number"
                              />
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <button type="button" class="btn btn-info" v-show="!isAddable" @click="addExtension()">Add Extension</button>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="card-body col-md-12">
                            <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Extension</th>
                                    <th>Number</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="text-align:center;">1</td>
                                    <td >202</td>
                                    <td style="text-align:center;">
                                        <button type="button" class="btn btn-info" @click="editExtension(index)"><i class="fa fa-edit"></i></button>
                                        <button v-show='!isDeletable' type="button" class="btn btn-red" @click="deleteExtension(extension)"><i class="fa fa-trash" style="color: white;"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                          <span class="has-error" v-if="errors.has('pbx.extensions')">{{
                            errors.first("pbx.extensions")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
          -->
        </service-wizard-layout>

        <!-- wizard for Internal Voice Extension Service -->
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="partsVoiceExtension"
          :onComplete="onComplete"
          :validateStep="validateStep"
          v-else-if="isVoiceExtension"
        >
          <template slot="sip">
            <form novalidate data-vv-scope="sip">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.registrar'),
                    }"
                  >
                    <label>Registrar:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="registrar"
                      placeholder="Registrar:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.registrar"
                    />
                    <span class="has-error" v-if="errors.has('sip.registrar')">{{
                      errors.first("sip.registrar")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.username'),
                    }"
                  >
                    <label>Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="username"
                      placeholder="Username:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.username"
                    />
                    <span class="has-error" v-if="errors.has('sip.username')">{{
                      errors.first("sip.username")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.password'),
                    }"
                  >
                    <label>Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="password"
                      placeholder="Password:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.password"
                    />
                    <span class="has-error" v-if="errors.has('sip.password')">{{
                      errors.first("sip.password")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>

        <!-- wizard for Home Voice Service -->
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
          v-else
        >
          <template slot="sip">
            <form novalidate data-vv-scope="sip">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.registrar'),
                    }"
                  >
                    <label>Registrar:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="registrar"
                      placeholder="Registrar:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.registrar"
                    />
                    <span class="has-error" v-if="errors.has('sip.registrar')">{{
                      errors.first("sip.registrar")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.username'),
                    }"
                  >
                    <label>Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="username"
                      placeholder="Username:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.username"
                    />
                    <span class="has-error" v-if="errors.has('sip.username')">{{
                      errors.first("sip.username")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.password'),
                    }"
                  >
                    <label>Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="password"
                      placeholder="Password:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.password"
                    />
                    <span class="has-error" v-if="errors.has('sip.password')">{{
                      errors.first("sip.password")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
          <template slot="did">
            <form novalidate data-vv-scope="did">

              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.number'),
                    }"
                  >
                    <label>DID Number:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="number"
                      placeholder="Number:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.number"
                    />
                    <span class="has-error" v-if="errors.has('did.number')">{{
                      errors.first("did.number")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.ported'),
                    }"
                  >
                    <label>Ported:</label>
                    <select
                      class="form-control"
                      v-validate="'required'"
                      name="ported"
                      placeholder="Ported:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.ported"
                    >
                      <option value=false>No</option>
                      <option value=true>Yes</option>
                    </select>
                    <span class="has-error" v-if="errors.has('did.ported')">{{
                      errors.first("did.ported")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
  components: {
    FormWizard,
    TabContent,
    ServiceWizardLayout,
    DateInput,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDeletable() {
      if (true) {
        return false;
      }
        return true;
    },
    isAddable() {
      if (true) {
        return false;
      }
        return true;
    },
  },
  data() {
    return {
      partsPbx: [
        {
          name: "pbx",
          displayName: "PBX",
          icon: "fa fa-cubes",
        },
        {
          name: "sip",
          displayName: "SIP Trunk",
          icon: "fa fa-info",
        },
        {
          name: "did",
          displayName: "DID Information",
          icon: "fa fa-phone",
        },
        /*
        {
          name: "extensions",
          displayName: "Extensions",
          icon: "fa fa-plug",
        },
        */
      ],
      partsVoiceExtension: [
        {
          name: "sip",
          displayName: "SIP Trunk",
          icon: "fa fa-info",
        },
      ],
      parts: [
        {
          name: "sip",
          displayName: "SIP Trunk",
          icon: "fa fa-info",
        },
        {
          name: "did",
          displayName: "DID Information",
          icon: "fa fa-phone",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
            sipTrunk: {},
            didNumber: {},
        },
      },
      portedOptions: [{
          portedValue: true,
          portedLabel: "Yes"
      }, {
          portedValue: false,
          portedLabel: "No"
      }
      ],
      homePbx: false,
      newExtension: {
        number: "",
      },
      isVoiceExtension: false,
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    if (this.filledInSaleDefinition.product.productDefinition === 'za.co.adept.aims.product.voip.InternalVoiceExtension') {
      this.isVoiceExtension = true;
    } else {
      this.filledInSaleDefinition.requiredObjs.didNumber.ported = false;
    }

    if (this.filledInSaleDefinition.requiredObjs.pbx) {
      this.homePbx = true;
    }
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    addExtension() {
      if (this.newExtension.number == null || this.newExtension.number == "") {
        return this.$swal({
          title: "Error",
          text: "You need to insert a number",
          type: 'warning',
          confirmButtonText: 'Ok',
        });
      } else {
        //Add extension
        this.newExtension.number = "";
      }
    },
    deleteExtension(data) {
        //Delete extension
    },
    editExtension(id) {
      return this.$swal({
          title: "Edit Extension Number",
          input: "text",
          showCancelButton: true,
          inputPlaceholder: this.extensionList[id].number
      }).then((result) => {
          if (result.dismiss) {
              return false;
          }
          if (result.value == null || result.value == "") {
              return this.$swal({
                  title: "Error",
                  text: "You need to insert a number",
                  type: 'warning',
                  confirmButtonText: 'Ok',
              }).then((result) => {
                  this.editExtension(id)
              });
          }
          if (result.value) {
              this.extensionList[id].number = result.value;
              //Edit extension
          }
      });
    },
  },
};
</script>

<style>
</style>
