<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:sip_trunk>
      <div class="row">
        <div class="col-md-7">
          <PbxDetails
            ref="pbxDet"
            :pbx="saleDefinition.sale.pbx.pbxPackage"
            :pbxDescriptions="descriptionsList"
            :description="saleDefinition.sale.pbx.description"
            :licenceKey="saleDefinition.sale.pbx.licenceKey"
            :renewalDate="saleDefinition.sale.pbx.renewalDate"
            :disabledControls="isDisabled"
          />
          
        </div>
        <div class="col-md-5" style="padding-top: 25px">
          <div class="card card-default">
            <div class="card-header">
              <h4>3CX Cloud PBX Package Details</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">Supporting Package: </div>
                    <div class="col-md-7">
                      <strong v-if="saleDefinition.sale.pbxSupport && saleDefinition.sale.pbxSupport.pbxSupportPackage">
                        {{saleDefinition.sale.pbxSupport.pbxSupportPackage.description}}
                      </strong>
                      <strong v-else>
                        N/A
                      </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">Call Cabinet: </div>
                    <div class="col-md-7">
                      <strong v-if="saleDefinition.sale.recording && saleDefinition.sale.recording.recordingPackage">
                        {{saleDefinition.sale.recording.recordingPackage.description}}
                      </strong>
                      <strong v-else>
                        N/A
                      </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">Concurrent Calls: </div>
                    <div class="col-md-7">
                      <strong>
                        {{saleDefinition.sale.pbx.pbxPackage.concurrentCalls}}
                      </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">DIDs: </div>
                    <div class="col-md-7">
                      <strong>
                        {{saleDefinition.sale.didNumbers.length}}
                      </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">Extensions: </div>
                    <div class="col-md-7">
                      <strong>
                        {{saleDefinition.sale.pbx.extensions.length}}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <VoipDetails
        :sipTrunk="saleDefinition.sale.sipTrunk"
        @save-sale="saveSaleDefinition"
        :disabledControls="isDisabled"
      />
    </template>
    <template v-slot:support>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>Support Packages</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <label>Packages</label>
                <div class="input-group">
                  <v-select
                    name="package"
                    placeholder="No Package Selected"
                    v-model="supportSelected"
                    :options="supportList"
                    :disabled="isDisabled"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group row-spacing col-auto">
                <button type="button" @click="saveSupportPackage()" class="btn btn-success">Save Package</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:call>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>Call Cabinet</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <label>Packages</label>
                <div class="input-group">
                  <v-select
                    name="package"
                    placeholder="No Package Selected"
                    v-model="recordingSelected"
                    :options="recordingList"
                    :disabled="isDisabled"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group row-spacing col-auto">
                <button type="button" @click="saveRecordingPackage()" class="btn btn-success">Save Package</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:did>
      <MultipleDid 
        :didList="saleDefinition.sale.didNumbers"
        @update-dids="updateDids"
        @add-new-did="addNewDid"
        @delete-did="deleteDid"
        @save-sale="saveSaleDefinition"
      />
    </template>
    <template v-slot:extension>
      <VoipExtensions 
        :extensionList="saleDefinition.sale.pbx.extensions"
        :pbx="saleDefinition.sale.pbx.pbxPackage"
        @update-extensions="updateExtensions"
        @add-new-extension="addNewExtension"
        @add-extensions="addExtensionRange"
        @delete-extension="deleteExtension"
      />
    </template>
    <template v-slot:admin>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
            <h4>Admin Details</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <label>Admin URL</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="saleDefinition.sale.pbx.adminUrl"
                    :disabled="isDisabled"
                  />
                </div>
              </div>
              <div class="col-md-6" v-if="userContainsPermission(['ADMIN', 'MANAGER', 'TECHNICAL_STAFF'])">
                <label>Admin Secret Key</label>
                <div class="input-group">
                  <input 
                    type="password" 
                    class="form-control" 
                    v-model="saleDefinition.sale.pbx.secretKey"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row row-spacing">
              <div class="col-md-6">
                <label>Admin Username</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="saleDefinition.sale.pbx.adminUsername"
                    :disabled="isDisabled"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label>Admin Password</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="saleDefinition.sale.pbx.adminPassword"
                    :disabled="isDisabled"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row row-spacing">
              <div class="col-md-12 row-spacing">
                <div class="form-row justify-content-between" v-allowed:view="['STAFF']">
                  <div class="form-group col-auto">
                    <button type="button" @click="saveSaleDefinition" class="btn btn-success">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <Otp 
            ref="otpComponent"
            :secretKey="saleDefinition.sale.pbx.secretKey"
          />
        </div>
      </div>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

import VoipDetails from "@/components/ObjParts/SingleConstructed/VoipDetails";
import PbxDetails from "@/components/ObjParts/SingleConstructed/PbxDetails";
import VoipExtensions from "@/components/ObjParts/MultipleCaptured/VoipExtensions";
import MultipleDid from "@/components/ObjParts/MultipleCaptured/MultipleDid";

import Otp from "@/components/GeneralComponents/Otp";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    VoipDetails,
    VoipExtensions,
    MultipleDid,
    PbxDetails,
    Otp
  },
  watch: {
    /*
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.definition.sipTrunk.sipTrunkNumber != newSaleDef.definition.sipTrunk.sipTrunkNumber) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
    */
  },
  computed: {
    isDisabled() {
      return (!this.userContainsPermission(['STAFF']) && this.clientReadOnly)
    }
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "sip_trunk",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
        {
          name: "did",
          icon: "fa fa-phone-square",
          displayName: "DID",
          permissions: ["*"],
        },
        {
          name: "extension",
          icon: "fa fa-plug",
          displayName: "Extensions",
          permissions: ["*"],
        },
        {
          name: "support",
          icon: "fa fa-plus",
          displayName: "Packages",
          permissions: ["*"],
        },
        {
          name: "call",
          icon: "fa fa-phone",
          displayName: "Call Cabinet",
          permissions: ["*"],
        },
        {
          name: "admin",
          icon: "fa fa-cogs",
          displayName: "Admin",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          sipTrunk: {},
          pbx: {
            pbxPackage: {},
            extensions: [],
          },
          pbxSupport: {},
          didNumbers: {},
        },
        meta: {
          pbx: {
            children: {
              pbxPackage: {
                partMeta: {
                  options: []
                }
              },
              extensions: {
                objMeta: {
                  new: {}
                }
              }
            }
          },
          pbxSupport: {
            children: {
              pbxSupportPackage: {
                partMeta: {
                  options: []
                }
              }
            }
          },
          recording: {
            children: {
              recordingPackage: {
                partMeta: {
                  options: []
                }
              }
            }
          }
        },
      },
      descriptionsList: [],
      supportList: [],
      recordingList: [],
      supportSelected: '',
      recordingSelected: '',
      callsValue: 0,
      clientReadOnly: false,
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.fetchSaleDefinition(this.saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
        console.log(success.data);

        if ((this.saleDefinition.sale.product.clientAccessRestriction === 'Client Read-Only')
               || (this.saleDefinition.sale.product.clientAccessRestriction === 'Client Usage Allowed'))  {
            this.clientReadOnly = true;
        }
        
        if (this.saleDefinition.sale.pbxSupport && this.saleDefinition.sale.pbxSupport.pbxSupportPackage) {
          this.supportSelected = this.saleDefinition.sale.pbxSupport.pbxSupportPackage.description;
        }
        if (this.saleDefinition.sale.recording && this.saleDefinition.sale.recording.recordingPackage) {
          this.recordingSelected = this.saleDefinition.sale.recording.recordingPackage.description;
        }

        for (let i = 0; i < this.saleDefinition.meta.pbx.children.pbxPackage.partMeta.options.length; i++) {
          this.descriptionsList.push(this.saleDefinition.meta.pbx.children.pbxPackage.partMeta.options[i].description);
        }
        for (let i = 0; i < this.saleDefinition.meta.pbxSupport.children.pbxSupportPackage.partMeta.options.length; i++) {
          this.supportList.push(this.saleDefinition.meta.pbxSupport.children.pbxSupportPackage.partMeta.options[i].description);
        }
        for (let i = 0; i < this.saleDefinition.meta.recording.children.recordingPackage.partMeta.options.length; i++) {
          this.recordingList.push(this.saleDefinition.meta.recording.children.recordingPackage.partMeta.options[i].description);
        }
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    loadSaleDefinition() {
      this.fetchSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.saleDefinition.sale.pbx.description = this.$refs.pbxDet.description;
      this.saleDefinition.sale.pbx.licenceKey = this.$refs.pbxDet.licenceKey;
      this.saleDefinition.sale.pbx.renewalDate = this.$refs.pbxDet.renewalDate;

      for (let i = 0; i < this.saleDefinition.meta.pbx.children.pbxPackage.partMeta.options.length; i++) {
        if (this.$refs.pbxDet.pbx.description == this.saleDefinition.meta.pbx.children.pbxPackage.partMeta.options[i].description) {
          this.saleDefinition.sale.pbx.pbxPackage = this.saleDefinition.meta.pbx.children.pbxPackage.partMeta.options[i].description;
          this.saleDefinition.sale.pbx.cx3CloudPbxPackageNumber = this.saleDefinition.meta.pbx.children.pbxPackage.partMeta.options[i].objKey;
        }
      }
      
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPIv2 +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Voice Service", error);
              }
            );
        }
      });
    },
    saveSupportPackage() {
      for (let i = 0; i < this.saleDefinition.meta.pbxSupport.children.pbxSupportPackage.partMeta.options.length; i++) {
        if (this.supportSelected == this.saleDefinition.meta.pbxSupport.children.pbxSupportPackage.partMeta.options[i].description) {
          if (this.saleDefinition.sale.pbxSupport) {
            this.saleDefinition.sale.pbxSupport.class = "za.co.adept.aims.voip.support.PbxSupport";
            this.saleDefinition.sale.pbxSupport.pbxSupportPackage = this.saleDefinition.meta.pbxSupport.children.pbxSupportPackage.partMeta.options[i];
            this.saleDefinition.sale.pbxSupport.pbxSupportPackageNumber = this.saleDefinition.meta.pbxSupport.children.pbxSupportPackage.partMeta.options[i].pbxSupportPackageNumber;
          } else {
            this.saleDefinition.sale.pbxSupport = this.saleDefinition.meta.pbxSupport.objMeta.new;
            this.saleDefinition.sale.pbxSupport.pbxSupportPackageNumber = this.saleDefinition.meta.pbxSupport.children.pbxSupportPackage.partMeta.options[i].pbxSupportPackageNumber;
            this.saleDefinition.sale.pbxSupport.saleNumber = this.saleNumber;
          }
        }
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPIv2 +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Voice Service", error);
              }
            );
        }
      });
    },
    saveRecordingPackage() {
      for (let i = 0; i < this.saleDefinition.meta.recording.children.recordingPackage.partMeta.options.length; i++) {
        if (this.recordingSelected == this.saleDefinition.meta.recording.children.recordingPackage.partMeta.options[i].description) {
          if (this.saleDefinition.sale.recording) {
            this.saleDefinition.sale.recording.recordingPackage = this.saleDefinition.meta.recording.children.recordingPackage.partMeta.options[i];
            this.saleDefinition.sale.recording.callCabinetPackageNumber = this.saleDefinition.meta.recording.children.recordingPackage.partMeta.options[i].callCabinetPackageNumber;
          } else {
            this.saleDefinition.sale.recording = this.saleDefinition.meta.recording.objMeta.new;
            this.saleDefinition.sale.recording.callCabinetPackageNumber = this.saleDefinition.meta.recording.children.recordingPackage.partMeta.options[i].callCabinetPackageNumber;
            this.saleDefinition.sale.recording.saleNumber = this.saleNumber;
          }
        }
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPIv2 +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Voice Service", error);
              }
            );
        }
      });
    },
    updateExtensions: function(data) {
      this.saleDefinition.sale.pbx.extensions = data;
      this.saveSaleDefinition();
    },
    addNewExtension: function(data) {
      var extensionObj = this.saleDefinition.meta.pbx.children.extensions.objMeta.new;
      extensionObj.number = data;
      extensionObj.cx3CloudPbxNumber = this.saleDefinition.sale.pbx.cx3CloudPbxNumber;
      this.saleDefinition.sale.pbx.extensions.push(extensionObj);
      this.saveSaleDefinition();
    },
    addExtensionRange: function(data) {
      return this.$swal({
                title: "New Extensions",
                text: "You are about to add " + data.length + " extensions",
                type: 'info',
                confirmButtonText: 'Ok',
                showCancelButton: true,
            }).then((result) => {
                if (result.dismiss) {
                    return false;
                }
                if (result.value) {
                    for (let i = 0; i < data.length; i++) {
                      var extensionObj = Object.assign({}, this.saleDefinition.meta.pbx.children.extensions.objMeta.new);
                      extensionObj.number = data[i];
                      extensionObj.cx3CloudPbxNumber = this.saleDefinition.sale.pbx.cx3CloudPbxNumber;
                      this.saleDefinition.sale.pbx.extensions.push(extensionObj);
                    }
                    this.saveSaleDefinition();
                }
            });
    },
    deleteExtension: function(data) {
      if (this.saleDefinition.sale.pbx.extensions.length > 1) {
        for (let i = 0; i < this.saleDefinition.sale.pbx.extensions.length; i++) {
          if (this.saleDefinition.sale.pbx.extensions[i] == data) {
            this.deleteObjPart(this.saleDefinition.sale.pbx.extensions[i])
              .then(
                (response) => {
                  this.showSuccess("Delete Success");
                  this.loadSaleDefinition()
                    .then((response) => {
                      this.saleDefinition.sale.pbx.extensions = this.saleDefinition.sale.pbx.extensions.filter(ext => (ext != data));
                    }, (error) => {
                      console.error(error);
                      this.showError("Error Deleting Extension", error);
                    });
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Deleting Extension", error);
              });
          }
        }
      } else {
        return this.$swal({
          title: "Error",
          text: "You can't delete the last extension",
          type: 'warning',
          confirmButtonText: 'Ok',
        });
      }
    },
    updateDids: function(data) {
      this.saleDefinition.sale.didNumbers = data;
      this.saveSaleDefinition();
    },
    addNewDid: function(data) {
      var didObj = this.saleDefinition.meta.didNumbers.objMeta.new;
      didObj.number = data.number;
      didObj.ported = data.ported;
      this.saleDefinition.sale.didNumbers.push(didObj);
      this.saveSaleDefinition();
    },
    deleteDid: function(data) {
      if (this.saleDefinition.sale.didNumbers.length > 1) {
        for (let i = 0; i < this.saleDefinition.sale.didNumbers.length; i++) {
          if (this.saleDefinition.sale.didNumbers[i] == data) {
            this.deleteObjPart(this.saleDefinition.sale.didNumbers[i])
              .then(
                (response) => {
                  this.showSuccess("Delete Success");
                  this.loadSaleDefinition()
                    .then((response) => {
                      this.saleDefinition.sale.didNumbers = this.saleDefinition.sale.didNumbers.filter(ext => (ext != data));
                    }, (error) => {
                      console.error(error);
                      this.showError("Error Deleting DID Number", error);
                    });
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Deleting DID Number", error);
              });
          }
        }
      } else {
        return this.$swal({
          title: "Error",
          text: "You can't delete the last DID Number",
          type: 'warning',
          confirmButtonText: 'Ok',
        });
      }
    },
  },
};
</script>