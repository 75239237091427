<template>
    <div class="d-flex justify-content-center align-items-center h-100 flex-column">
        
        <div class="logo">
            <img
                src="/img/services/default.png"
                class="logo-img"
            />
        </div>

        <div class="card border-none mt-5">
            <div class="col-md-12">
                <div class="row">
                    <div class="card-left d-flex align-items-center"
                        :class="{ open: activeCard === 'left' && isLoginRoute }"
                        @mouseenter="setActiveCard('left')"
                    >
                    <!-- LOGIN SCREEN -->
                        <div class="card-body" v-if="isLoginRoute">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <button type="button" class="btn btn-provider w-75 p-2" @click="loginProvider('microsoft')">
                                        <img
                                            src="/img/provider-logins/microsoft.png"
                                            class="provider-icon"
                                        />
                                        <span class="providerLabel">Sign in with Microsoft</span>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 d-flex justify-content-center">
                                    <button type="button" class="btn btn-provider w-75 p-2" @click="loginProvider('google')">
                                        <img
                                            src="/img/provider-logins/google.png"
                                            class="provider-icon"
                                        />
                                        <span class="providerLabel">Sign in with Google</span>
                                    </button>
                                </div>
                            </div>
                        </div>        
                        
                    <!-- FORGOT PASSWORD -->
                        <div class="card-body" v-else>
                            <div class="row row-spacing" v-if="emailSent === false || showErrorMessage">
                                <div class="col-12">
                                    <p class="form-text text-white text-center">Enter the email address linked to your account and a link will be emailed to you!</p>
                                </div>
                            </div>
                            <div class="row row-spacing" v-if="emailSent === true && !showErrorMessage">
                                <div class="col-12">
                                    <p class="form-text text-white text-center">A reset link has been sent to your email. Check your <strong>spam folder</strong> if you're unable to see it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-right container d-flex justify-content-center align-items-center"
                        :class="{ open: activeCard === 'right' || !isLoginRoute }"
                        @mouseenter="setActiveCard('right')"
                    >

                        <div class="card-body">
                            <div class="login-form d-flex justify-content-center align-items-center">
                                <div class="w-75">
                                    <slot></slot>
                                </div>
                            </div>
                            <div class="card-buttons d-flex mt-3" :class="showSwitchUser ? 'justify-content-between' : 'justify-content-center'">
                                <button type="button" class="btn btn-primary p-2" :class="showSwitchUser ? 'w-50' : 'w-75'" @click="submitEvent()" @keyup.enter="submitEvent()" :disabled="logUserIn || sendingMail">{{ primaryButtonText }}</button>
                                <button v-if="showSwitchUser" type="button" class="btn btn-info w-50 p-2" @click="switchUser()">Switch User</button>
                            </div>
                            <div class="forgot-password-link" v-if="showForgotPassword">
                                <router-link target="_blank" :to="{ name: 'forgotpassword' }">Forgot Password</router-link>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div class="error-container mt-4" v-if="showErrorMessage">
            <p v-if="loginErrorMessage"><i class="fa fa-exclamation-triangle"></i> {{ loginErrorMessage }}</p>
            <p v-if="sendingMailErrorMessage"><i class="fa fa-exclamation-triangle"></i> {{ sendingMailErrorMessage }}</p>
            <p v-if="changePasswordErrorMessage"><i class="fa fa-exclamation-triangle"></i> {{ changePasswordErrorMessage }}</p>
        </div>
        <div class="empty-error-container mt-4" v-else>
            .
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            loginHeading: {
                type: String,
                required: true,
            },
            primaryButtonText: {
                type: String,
                required: true,
            },
            loginErrorMessage: {
                type: String,
                required: false
            },
            sendingMailErrorMessage: {
                type: String,
                required: false
            },
            changePasswordErrorMessage: {
                type: String,
                required: false
            },
            showErrorMessage: {
                type: Boolean,
                required: false
            },
            showSwitchUser: {
                type: Boolean,
                required: false
            },
            logUserIn: {
                type: Boolean,
                required: false
            },
            customImage: {
                type: String,
                required: false
            },
            showForgotPassword: {
                type: Boolean,
                default: false
            },
            sendingMail: {
                type: Boolean,
                required: false
            },
            emailSent: {
                type: Boolean,
                required: false
            },
        },
        data() {
            return {
                activeCard: 'right'
            }
        },
        computed: {
            isLoginRoute() {
                return this.$route.path === '/login';
            }
        },
        methods: {
            setActiveCard(card) {
              this.activeCard = card;
            },
            submitEvent: function() {
                this.$emit('submit');
            },
            switchUser: function() {
                this.$emit('switchUser');
            },
            navigateToForgotPassword: function() {
                this.$emit('navigateToForgotPassword')
            },
            loginProvider: function(provider) {
                this.$localStorage.set("prov", provider);
                this.$emit('loginProvider', provider)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .card-left {
        background-color: #3A3F51;
        border: none;
        border-radius: 2em 0em 0em 2em;
        padding: 2em 1em 2em 1em;
        width: 350px;
        min-height: 350px;
        max-height: 450px;
        transition: 0.4s ease-in-out;
    }

    .card-right {
        background-color: #EDF1F2;
        border: none;
        border-radius: 0em 2em 2em 0em;
        padding: 2em 1em 2em 1em;
        width: 350px;
        min-height: 350px;
        max-height: 450px;
        transition: 0.4s ease-in-out;

        .forgot-password-link {
            position: absolute;
            bottom: 1.5em; 
            right: 2em; 
            
            a {
                color: #656565 !important;
                &:hover {
                    color: #e2231a !important;
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }
    }

    .error-container {
        background-color: #e2231a;
        color: #ffffff;
        padding: .8em;
        border-radius: 5px;
        z-index: 1;

        p {
            margin: 0;
        }
    }

    .btn-provider {
        display: flex;
        justify-content: center;
        background-color: #f9f9f9;
    }

    .btn-provider:hover {
        background-color: #e3e3e3;
    }
    
    .provider-icon {
        width: 24px;
        height: 24px;
        margin-left: 12px; 
        margin-right: 12px;
    }

    .providerLabel {
        font-size: 15px;
        color: #4b5168;
    }

    .fa-brands {
        color: #1f1d4d;
    }

    .border-none {
        border: none;
        background-color: transparent;
        border-radius: 2em 2em 2em 2em;
        box-shadow: 12px 12px 5px 0px rgba(0, 0, 0, 0.5);
    }

    .card-left.open {
        width: 450px;
        background-color: #42485c;
    }

    .card-right.open {
        width: 450px;
        background-color: #ffffff;
    }

    .empty-error-container {
        color: transparent;
        background-color: transparent;
        padding: 0.8em;
        border-radius: 5px;
        visibility: hidden;

        p {
            margin: 0;
        }
    }

    .logo-img {
        height: 50px;
    }
</style>