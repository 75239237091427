<template>
  <div class="new-service-container">
    <div class="new-service-wrapper">
      <div class="col-md-12">
        <div class="row row-spacing">
          <div class="col-auto">
            <button
              class="btn btn-primary"
              style="margin-top: 2em"
              :disabled="!clientNumber"
              type="button"
              @click="$emit('change-active-view', 'SERVICE_LIST');
              productSelected = emptySaleDefinition = null;"
            >
              Cancel
            </button>
          </div>
          <div class="col-md-4"  v-if="!productSelected || !emptySaleDefinition">
            <div class="input-group" style="margin-top: 1.6em">
              <input type="text" class="form-control" placeholder="Search for Product..." v-model="searchProductsInput">
              <div class="input-group-append">
                <div class="input-group-text"><i class="fa fa-search"></i></div>
              </div>
            </div>
          </div>
           <div class="col-md-2" v-if="!productSelected && !emptySaleDefinition">
            <div class="form-group">
              <label>Filter by Segment</label>
              <select class="form-control" v-model="segmentSelected">
                <option value="any">Any</option>
                <option v-for="segment in segments" :key="segment">
                  {{ segment }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2" v-if="!productSelected && !emptySaleDefinition">
            <div class="form-group">
              <label>Filter by Provider</label>
              <select class="form-control" v-model="providerSelected">
                <option value="any">Any</option>
                <option v-for="provider in providers" :key="provider">
                  {{ provider }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2" v-if="!productSelected && !emptySaleDefinition">
            <div class="form-group">
              <label>Filter by Type</label>
              <select class="form-control" v-model="productTypeSelected">
                <option value="any">Any</option>
                <option v-for="type in productTypes" :key="type">
                  {{ type }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <hr />
        <div class="row row-spacing" v-if="!productSelected && !emptySaleDefinition">
          <div class="col-md-12">
            <div class="row">
              <div
                class="col-md-4"
                v-for="product in filteredProducts"
                :key="product.productNumber"
                @click="productSelectionChanged(product)"
              >
                <div class="card product-card text-center">
                  <div class="card-header">
                    <h4>{{ product.description }}</h4>
                  </div>
                  <div class="card-body py-0 d-block">
                    <div class="col-md-12">
                      <div class="row row-spacing justify-content-center">
                        <ProviderLogo
                          :providerName="product.vendor.name"
                          :logoImgStyle="'height: auto; width: 70%'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row row-spacing justify-content-center mb-2">
                        <span class="badge bg-gray mx-1">{{ product.classificationType }}</span>
                        <span class="badge bg-gray mx-1">{{ product.segment.name }}</span>
                        <span class="badge bg-gray mx-1">{{ product.subsegment.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-spacing" v-if="productSelected && emptySaleDefinition">
          <div class="col-md-12 col-xl-12">
            <component
              v-if="showWizard == 'AxxessLteWizard'"
              :is="showWizard"
              :emptySaleDefinition="emptySaleDefinition"
              :product="productSelected"
              @createSale="createSale"
            />
            <component
              v-else
              :is="showWizard"
              :emptySaleDefinition="emptySaleDefinition"
              @createSale="createSale"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LteWizard from "@/components/Services/Wizards/LteWizard";
import AxxessLteWizard from "@/components/Services/Wizards/AxxessLteWizard";
import AxxessFtthWizard from "@/components/Services/Wizards/AxxessFtthWizard";
import OpenserveWizard from "@/components/Services/Wizards/OpenserveWizard";
import OpenserveMplsVpnWizard from "@/components/Services/Wizards/OpenserveMplsVpnWizard";
import PppoeCappedWizard from "@/components/Services/Wizards/PppoeCappedWizard";
import ActiveEthernetWizard from "@/components/Services/Wizards/ActiveEthernetWizard";
import PppoeUncappedWizard from "@/components/Services/Wizards/PppoeUncappedWizard";
import FttbWizard from "@/components/Services/Wizards/FttbWizard";
import DomainRegistrationWizard from "@/components/Services/Wizards/DomainRegistrationWizard";
import CPanelHostingWizard from "@/components/Services/Wizards/CPanelHostingWizard";
import DomainHostingOnlyWizard from "@/components/Services/Wizards/DomainHostingOnlyWizard";
import InternalDnsWizard from "@/components/Services/Wizards/InternalDnsWizard";
import L2tpStaticIpWizard from "@/components/Services/Wizards/L2tpStaticIpWizard";
import Layer2LinkWizard from "@/components/Services/Wizards/Layer2LinkWizard";
import NCentralWizard from "@/components/Services/Wizards/NCentralWizard";
import ZimbraMailWizard from "@/components/Services/Wizards/ZimbraMailWizard";
import Layer2Wizard from "@/components/Services/Wizards/Layer2Wizard";
import RouterRentalWizard from "@/components/Services/Wizards/Rental/RouterRentalWizard";
import EquipmentRentalWizard from "@/components/Services/Wizards/Rental/EquipmentRentalWizard";
import VirtualServerWizard from "@/components/Services/Wizards/VirtualServerWizard";
import SplaServiceWizard from "@/components/Services/Wizards/SplaServiceWizard";
import MplsOverInternetWizard from "@/components/Services/Wizards/MplsOverInternetWizard";
import MplsOverLteWizard from "@/components/Services/Wizards/MplsOverLteWizard";
import MplsVpnWizard from "@/components/Services/Wizards/MplsVpnWizard";
import MetroConnectMplsWizard from "@/components/Services/Wizards/MetroConnectMplsWizard";
import SolarwindsBackupWizard from "@/components/Services/Wizards/SolarwindsBackupWizard";
import ZimbraSharedMailWizard from "@/components/Services/Wizards/ZimbraSharedMailWizard";
import CabinetRentalWizard from "@/components/Services/Wizards/CabinetRentalWizard";
import LiquidLayer2Wizard from "@/components/Services/Wizards/LiquidLayer2Wizard";
import CabinetPowerWizard from "@/components/Services/Wizards/CabinetPowerWizard";
import MimecastMailWizard from "@/components/Services/Wizards/MimecastMailWizard";
import HomeVoiceWizard from "@/components/Services/Wizards/HomeVoiceWizard";
import WanatelPbxWizard from "@/components/Services/Wizards/WanatelPbxWizard";
import cx3pbxwizard from "@/components/Services/Wizards/Cx3PbxWizard";
import DataCentreInternetWizard from "@/components/Services/Wizards/DataCentreInternetWizard";
import GoogleWorkspaceWizard from "@/components/Services/Wizards/GoogleWorkspaceWizard";
import CrossConnectWizard from "@/components/Services/Wizards/CrossConnectWizard";
import InternationalDidWizard from "@/components/Services/Wizards/InternationalDidWizard";
import LiquidSubscriptionWizard from "@/components/Services/Wizards/LiquidSubscriptionWizard";
import Layer3Wizard from "@/components/Services/Wizards/Layer3Wizard";
import Office365BackupWizard from "@/components/Services/Wizards/Office365BackupWizard";
import Office365Wizard from "@/components/Services/Wizards/Office365Wizard";
import SslCertificateWizard from "@/components/Services/Wizards/SslCertificateWizard";
import CyberRiskAwareWizard from "@/components/Services/Wizards/CyberRiskAwareWizard";
import AuthSmtpWizard from "@/components/Services/Wizards/AuthSmtpWizard";
import SynaqWizard from "@/components/Services/Wizards/SynaqWizard";
import WebsiteStarterWizard from "@/components/Services/Wizards/WebsiteStarterWizard";
import WebsitePhpWizard from "@/components/Services/Wizards/WebsitePhpWizard";
import AwsWizard from "@/components/Services/Wizards/AwsWizard.vue";
import GenericApnWizard from "@/components/Services/Wizards/GenericApnWizard.vue";
import HostedVlanServiceWizard from "@/components/Services/Wizards/HostedVlanServiceWizard";
import DeviceManagementWizard from "@/components/Services/Wizards/DeviceManagementWizard";
import SoftwareServiceWizard from "@/components/Services/Wizards/SoftwareServiceWizard";
import SlaServiceWizard from "@/components/Services/Wizards/SlaServiceWizard";
import ProviderLogo from "@/components/GeneralComponents/ProviderLogo";

import LegacyDomainRegistrationWizard from "@/components/Services/Wizards/Legacy/LegacyDomainRegistrationWizard";
import LegacySslCertificateWizard from "@/components/Services/Wizards/Legacy/LegacySslCertificateWizard";
import LegacyRemoteDomainWizard from "@/components/Services/Wizards/Legacy/LegacyRemoteDomainWizard";
import LegacyGenericWizard from '@/components/Services/Wizards/Legacy/LegacyGenericWizard'
import LegacyDeviceManagementWizard from "@/components/Services/Wizards/Legacy/LegacyDeviceManagementWizard";
import LegacyParkFibreWizard from "@/components/Services/Wizards/Legacy/LegacyParkFibreWizard";
import LegacyHostedVlanNetworkWizard from "@/components/Services/Wizards/Legacy/LegacyHostedVlanNetworkWizard";

export default {
  props: {
    availableProducts: {
      type: Array,
      required: true,
    },
    clientNumber: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    LteWizard,
    AxxessLteWizard,
    AxxessFtthWizard,
    OpenserveWizard,
    OpenserveMplsVpnWizard,
    PppoeCappedWizard,
    ActiveEthernetWizard,
    PppoeUncappedWizard,
    FttbWizard,
    L2tpStaticIpWizard,
    Layer2LinkWizard,
    NCentralWizard,
    ZimbraMailWizard,
    DomainRegistrationWizard,
    DomainHostingOnlyWizard,
    InternalDnsWizard,
    CPanelHostingWizard,
    ProviderLogo,
    Layer2Wizard,
    RouterRentalWizard,
    EquipmentRentalWizard,
    VirtualServerWizard,
    SplaServiceWizard,
    SoftwareServiceWizard,
    MplsOverInternetWizard,
    MplsOverLteWizard,
    MplsVpnWizard,
    MetroConnectMplsWizard,
    SolarwindsBackupWizard,
    ZimbraSharedMailWizard,
    GoogleWorkspaceWizard,
    CrossConnectWizard,
    InternationalDidWizard,
    CabinetRentalWizard,
    LiquidLayer2Wizard,
    CabinetPowerWizard,
    MimecastMailWizard,
    HomeVoiceWizard,
    WanatelPbxWizard,
    cx3pbxwizard,
    LiquidSubscriptionWizard,
    DataCentreInternetWizard,
    LegacyDomainRegistrationWizard,
    LegacySslCertificateWizard,
    LegacyRemoteDomainWizard,
    LegacyGenericWizard,
    LegacyDeviceManagementWizard,
    LegacyParkFibreWizard,
    LegacyHostedVlanNetworkWizard,
    HostedVlanServiceWizard,
    Layer3Wizard,
    DeviceManagementWizard,
    Office365BackupWizard,
    Office365Wizard,
    SslCertificateWizard,
    CyberRiskAwareWizard,
    AuthSmtpWizard,
    SynaqWizard,
    WebsiteStarterWizard,
    WebsitePhpWizard,
    AwsWizard,
    GenericApnWizard,
    SlaServiceWizard
  },
  computed: {
    filteredProducts() {
      let filteredProducts = this.availableProducts;
      if (this.searchProductsInput && this.searchProductsInput.length > 0) {
        filteredProducts = filteredProducts.filter(
          (p) =>
            p.description
              .toLowerCase()
              .indexOf(this.searchProductsInput.toLowerCase()) != -1
        );
      }
      if (this.segmentSelected != "any") {
        filteredProducts = filteredProducts.filter(
          (p) => p.segment.name === this.segmentSelected
        );
      }
      if (this.productTypeSelected != "any") {
        filteredProducts = filteredProducts.filter(
          (p) => p.classificationType === this.productTypeSelected
        );
      }
      if (this.providerSelected != "any") {
        filteredProducts = filteredProducts.filter(
          (p) => p.vendor.name === this.providerSelected
        );
      }
      return filteredProducts;
    },
    productTypes() {
      return new Set(
        this.availableProducts
          .map((p) => p.classificationType)
          .filter((s) => !s.includes("None"))
      );
    },
    segments() {
      return new Set(
        this.availableProducts
          .map((p) => p.segment.name)
          .filter((s) => !s.includes("None"))
      );
    },
    providers() {
      return new Set(
        this.availableProducts
          .map((p) => p.vendor.name)
          .filter((s) => !s.includes("None"))
      );
    },
  },
  data() {
    return {
      productSelected: null,
      emptySaleDefinition: null,
      searchProductsInput: "",
      segmentSelected: "any",
      providerSelected: "any",
      productTypeSelected: "any",
      showWizard: "",
    };
  },
  methods: {
    productSelectionChanged(product) {
      this.productSelected = product;
      let productDefs = [];
      for (let i = 0; i < this.$root.productDefinitions.length; i++) {
        productDefs.push(this.$root.productDefinitions[i].product);
      }

      if (this.productSelected.definitionType == "Old" && !productDefs.includes(product.productDefinition)) {
        this.redirectToAimsAddService(
          this.clientNumber,
          this.productSelected.getProductNumber
        );
      }

      const productDef = this.$root.productDefinitions.find(
        (p) => p.product === this.productSelected.productDefinition
      );

      if (!productDef) {
        this.showWarning(
          "Product Definition not Found",
          "Product definition entry for product " +
            this.productSelected.description +
            " not found"
        );
        return;
      }

      if (!productDef.createWizard) {
        this.showWarning(
          "Product wizard not found",
          "Could not find Product Wizard for product " +
            this.productSelected.description
        );
        return;
      }

      this.showWizard = productDef.createWizard;
      this.emptySaleDefinition = null;

      this.$http
        .get(
          this.$config.aimsAPI +
            "sales/definition/client/" +
            this.clientNumber +
            "/product/" +
            this.productSelected.productNumber
        )
        .then(
          (response) => {
            this.emptySaleDefinition = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Sale Requirements", error);
          }
        );
    },
    redirectToAimsAddService() {
      this.$swal({
        title: "Old Product Suite selected.",
        text: "You will be redirected to AIMS Add Service.",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          window.open(
            "https://staff.adept.co.za/portal/staff#!sale.LIST/clientNumber=" +
              this.clientNumber +
              "&productNumber=" +
              this.productSelected.productNumber
          );
        }
      });
    },
    createSale(saleDefRequirements) {
      this.$emit("createSale", saleDefRequirements);
    },
  },
};
</script>
<style lang="scss" scoped>
  .card.product-card {
    border: 1px solid #e4eaec;
    height: 180px;
    width: auto;

    .card-footer {
      border: none;
      background-color: transparent;
    }
  }
</style>
