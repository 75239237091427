<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row row-spacing">
                    <div class="col-md-4">
                        <label>Search Tickets</label>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control" :placeholder="searchPlaceholderText" v-model="searchTerm">
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Filter by status</label>
                        <div class="form-group">
                            <v-select :clearable="true" v-model="status" :options="statuses">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label class="mr-1">Filter by date</label>
                        <small>(90 days by default)</small>
                        <div class="form-group">
                            <div class="input-group">
                                <DateInput 
                                    type="date" 
                                    format="YYYY-MM-DD"
                                    :placeholder="'Select starting date range'"
                                    style="width: 100%"
                                    v-model="createdDate"
                                    @input="$emit('getAllTickets', createdDate)"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="isClient" class="col-md-3">
                 <div class="card card-default">
                    <div class="card-body text-center">
                        <h4 class="mt-2">Create a ticket</h4>
                        <button class="btn btn-primary mb-2" @click="openNewTicketModal">New Ticket</button>
                    </div>
                 </div>
            </div> -->
        </div>
        <table class="table table-hover table-responsive table-bordered">
            <thead>
                <tr class="dark-row">
                    <th @click="sortBy('ticketNumber')" class="sort-col th-width">
                        Ticket Number
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ticketNumber' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ticketNumber' && !reverse"></i>
                    </th>
                    <th @click="sortBy('description')" class="sort-col">
                        Subject
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
                    </th>
                    <th v-allowed:view="['CLIENT']" @click="sortBy('clientFirstName')" class="sort-col th-width">
                        Ticket Creator
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'clientFirstName' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'clientFirstName' && !reverse"></i>
                    </th>
                    <th v-allowed:view="['STAFF']" @click="sortBy('techFirstName')" class="sort-col th-width">
                        Assigned Tech
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'techFirstName' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'techFirstName' && !reverse"></i>
                    </th>
                    <th @click="sortBy('status')" class="sort-col th-width">Status
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'status' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'status' && !reverse"></i>
                    </th>
                    <th  @click="sortBy('created')" class="sort-col th-width">Created
                        <i class="fa fa-sort-asc float-right" v-if="sortKey == 'created' && reverse"></i>
                        <i class="fa fa-sort-desc float-right" v-if="sortKey == 'created' && !reverse"></i>
                    </th>
                    <th class="th-options"></th>
                </tr>
            </thead>
            <tbody v-if="filteredTickets.length > 0">
                <tr v-for="ticket in filteredTickets" :key="ticket.ticketNumber">
                    <td>{{ ticket.ticketNumber }}</td>
                    <td class="td-pointer" @click="goToHelpDeskTicket(ticket.ticketNumber)">{{ ticket.description }}</td>
                    <td v-allowed:view="['CLIENT']">{{ showTicketClient(ticket) }}</td>
                    <td v-allowed:view="['STAFF']">{{ showTicketTech(ticket) }}</td>
                    <td><span :class="getStatusBadge(ticket.status)">{{ticket.status}}</span></td>
                    <td class="text-nowrap">{{dateTimeFormat(ticket.created)}}</td>
                    <td class="td-options td-button dropdown" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" @click="goToHelpDeskTicket(ticket.ticketNumber)">Go to</a>
                            <a class="dropdown-item" @click="copyUrlToClipboard(ticket.ticketNumber)">Copy URL</a>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="6">No Helpdesk Tickets to display</td>
                </tr>
            </tbody>
        </table>
        <CreateHelpdeskTicket 
            ref="openCreateNewTicketModal"
            :modalTitle="modalTitle"
            @updateTicketList="updateTicketList"
        />
    </div>
</template>

<script>
import CreateHelpdeskTicket from '@/components/Admin/Modals/CreateHelpdeskTicket'; 
import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

export default {
    components: {
        CreateHelpdeskTicket,
        DateInput
    },
    props: {
        ticketList: {
            type: Array,
            required: true
        },
        statuses: {
            type: Array,
            required: true
        },
        createdDate: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            modalTitle: '',
            searchTerm: '',
            filteredTicketList: [],
            status: '',
            sortKey: 'ticketNumber',
            reverse: false,
        }
    },
    computed: {
        isClient() {
         return this.userContainsPermission(["CLIENT"]);
        },
        searchPlaceholderText() {
            return this.userContainsPermission(["CLIENT"]) ? "Search by Ticket Number, Subject or Ticket Creator..." : "Search by Ticket Number, Subject or Assigned Tech...";
        },
        filteredTickets() {
            this.filteredTicketList = this.ticketList;
            
            if (this.searchTerm) {                    
                this.filteredTicketList = this.filteredTicketList.filter((ticket) =>                       
                    ticket.ticketNumber.toString().includes(this.searchTerm) ||
                    ticket.clientFirstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    ticket.clientLastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    ticket.techFirstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    ticket.techLastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    ticket.description.toLowerCase().includes(this.searchTerm.toLowerCase())
                );                
            };

            if (this.status) {
                this.filteredTicketList = this.filteredTicketList.filter((ticket) => ticket.status === this.status); 
                this.setDefaultDate();
            }

            if (this.reverse) {
                return _.orderBy(this.filteredTicketList, this.sortKey, 'asc');
            } else {
                return _.orderBy(this.filteredTicketList, this.sortKey, 'desc');
            }
        }
    },
    watch: {
        status(newVal, oldVal) {
            if (newVal === 0) this.$emit('getAllTickets');
        }
    },
    methods: {
        goToHelpDeskTicket: function(data) {
            window.open(this.$config.urlToHelpdesk + data, '_blank');
        },
        copyUrlToClipboard: function(data) {
            navigator.clipboard.writeText(this.$config.urlToHelpdesk + data);
        },
        getStatusBadge: function(status) {
            var badge = 'badge badge-success';

            if (status == 'Open'){
                badge = 'badge bg-green';
            }

            if (status == 'Cancelled'){
                badge = 'badge bg-red text-white';
            }

            if (status == 'Pending') {
                badge = 'badge bg-yellow'
            }

            return badge;
        },
        openNewTicketModal: function() {
            this.$refs.openCreateNewTicketModal.open();
            this.modalTitle = 'New Ticket';
        },
        updateTicketList(data) {
            this.ticketList.push(data);
        },
        sortBy(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
            this.sortKey = sortKey;
        },
        showTicketTech: function(ticket) {
            if ((ticket.techFirstName && ticket.techLastName) !== null) {
                return `${ticket.techFirstName} ${ticket.techLastName}`;
            } else {
                ticket.techFirstName = 'No Tech assigned';
                ticket.techLastName = '';
            }
        },
        showTicketClient: function(ticket) {
            if ((ticket.clientFirstName && ticket.clientLastName) !== null) {
                return `${ticket.clientFirstName} ${ticket.clientLastName}`;
            } else {
                ticket.clientFirstName = 'No Client assigned';
                ticket.clientLastName = '';
            }
        },
        setDefaultDate: function() {            
            this.createdDate = this.dateTimeFormat(this.filteredTicketList.at(-1).created);            
        }
    }
}
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        width: calc(100px + 100em);
    }

    .th-width {
        width: calc(100px + 30em);
    }

    .th-options {
        width: 4%;
    }

}
.th-options, .td-options {
    cursor: pointer !important;
        text-align: center;
        
}

.sort-col {
    cursor: pointer;
}

.td-pointer {
    cursor: pointer !important;
}

.table-hover td {
    cursor: default;
}

.td-button:hover {
    color: red;
}

.td-options .dropdown-menu .dropdown-item:hover {
    color: red;
}

</style>