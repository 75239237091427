<template>
    <div class="card">
      <div class="card-header mt-3">
        <div class="col-md-12">
          <div class="row mb-3">
            <h3>{{ getTitle() }}</h3>
            <h3 v-if="creditMode" style="color: green">[{{ creditSummary }}]</h3>
          </div>
          <div class="row justify-content-between align-items-center">
            <div class="form-group">
                <button v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']" class="btn btn-info " type="button" @click="processDocument()" v-if="documentBody.status === 'OPEN'">
                    Process
                </button>
                <button class="btn btn-default" type="button" @click="emailDialog()" v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']">
                    Email
                </button>
                <button class="btn btn-default" type="button" @click="downloadInvoicePdf()">
                    Download PDF
                </button>
                <button v-allowed:view="['STAFF']" class="btn btn-default" type="button" @click="downloadInvoicePdfOriginal()" v-if="documentBody.status === 'PROCESSED' && documentBody.printed === true">
                    Download Original
                </button>
                <button v-allowed:view="['CLIENT', 'ACCOUNTS_STAFF', 'ADMIN']" class="btn btn-default" type="button" @click="$refs.purchaseOrderDialog.open()">
                    Purchase Order
                </button>
            </div>
            <div class="form-group">
                <button v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']" class="btn btn-success" type="button" @click="enterCreditMode()" v-if="documentBody.status === 'PROCESSED' && creditMode === false && documentBody.type !== 'CREDITNOTE'">
                    Credit
                </button>
                <button v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']" class="btn btn-primary" type="button" @click="documentDelete()" v-if="documentBody.status === 'OPEN' && creditMode === false">
                    Delete
                </button>
            </div>
            <div class="form-group text-right" v-if="creditMode">
                <button class="btn btn-default" type="button" @click="cancelCreditMode()">
                    Cancel Credit
                </button>
                <button  class="btn btn-success" type="button" @click="openCreditDetail()">
                    Issue Credit
                </button>
            </div>
          </div>
        </div>
        <hr class="my-1">
      </div>
      <div class="card-body pt-0">
            <DocumentInfo 
            :documentBody="documentBody"
            :documentLines="documentBody.documentlines"
            :purchaseOrder="purchaseOrder"
            :creditMode="creditMode"
            :editDescription="editDescription"
            :hover="hover"
            :debitOrderClient="debitOrderClient"
            @updateDescription="updateDescription"
            @setHover="setHover"
            @shouldEditDescription="shouldEditDescription"
            @documentReload="loadDocument"
          />
          <hr class="mb-3 mt-1">
          <DocumentLine 
            ref="documentline"
            :documentBody="documentBody"
            :documentLines="documentBody.documentlines"
            :creditMode="creditMode"
            :hasBreakdowns="hasBreakdowns"
            :addVatExcl="addVatExcl"
            :addVatIncl="addVatIncl"
            @selectAll="selectAll"
            @selectSection="selectSection"
            @insertLineOtherWay="insertLineOtherWay"
            @showBillingBreakdown="showBillingBreakdown"
            @showSale="showSale"
            @toggleDocLineEdit="toggleDocLineEdit"
            @saveDocumentLine="saveDocumentLine"
            @deleteDocumentLine="deleteDocumentLine"
            @addVatExclusiveAmount="addVatExclusiveAmount"
            @addVatInclusiveAmount="addVatInclusiveAmount"
          />
      </div>
      <modal
        :title="'Email Contacts'"
        ref="emailDialogModal"
        size="modal-xl"
        save-text="Send Email"
        @save="sendEmail()"
        dismiss-text="Close"
      >
        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="person in clientContacts"
                  :key="person.personNumber"
                  @click="contactClicked(person.emailAddress)"
                >
                  <td>{{ person.firstNames }}</td>
                  <td>{{ person.lastName }}</td>
                  <td>{{ person.emailAddress }}</td>
                  <td>
                    <input
                      type="checkbox"
                      :checked="isSelected(person.emailAddress)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <input
              type="text"
              class="form-control"
              v-model="additionalContacts"
              placeholder="Comma separate additional email addresses"
            />
          </div>
        </div>
      </modal>
      <modal
        title="Add Purchase Order To Invoice"
        ref="purchaseOrderDialog"
        @save="savePurchaseOrder()"
        :delete-needed="isNew"
        @delete="deletePurchaseOrder()"
      >
        <div class="form-group row">
          <label class="col-md-5 col-form-label">Purchase Order Number:</label>
          <div class="col-md-7">
            <input
              type="text"
              class="form-control"
              v-model="purchaseOrder.purchaseOrder"
            />
          </div>
        </div>
      </modal>
      <DocumentLineBreakdown ref="billingBreakdownModal" />
      <CreditDetailModal
        ref="creditDetailModal" 
        :creditDetail="newCreditDetail"
        @createCreditDetail="issueCreditNote()"
      />
    </div>
  </template>
  
  <script>
  import DocumentLineBreakdown from "@/components/Documents/DocumentLineBreakdown";
  import CreditDetailModal from "@/components/Admin/Modals/CreditDetailModal.vue";
  import Modal from "@/components/GeneralComponents/Modal";
  import DocumentLine from "@/components/Documents/DocumentLine";
  import DocumentInfo from "@/components/Documents/DocumentInfo";

  export default {
    components: {
      DocumentLineBreakdown,
      CreditDetailModal,
      Modal,
      DocumentLine,
      DocumentInfo,
    },
    computed: {
      creditLines() {
        return this.documentBody.documentlines.filter((d) => d.credit === true);
      },
      creditLinesTotal() {
        let total = 0;
        this.creditLines.forEach((d) => (total += d.transactionAmount));
        return total;
      },
      creditSummary() {
        return (
          "Crediting " +
          this.creditLines.length +
          " lines : - " +
          this.currencyFormat(this.creditLinesTotal)
        );
      },
      isNew() {
        return this.purchaseOrder.objKey ? true : false;
      }
    },
    data() {
      return {
        documentBody: {
          documentlines: [],
        },
        originalBody: {
          originalLines: [],
        },
        purchaseOrder: {},
        clientContacts: [],
        selectedContacts: [],
        additionalContacts: "",
        hasBreakdowns : true,
        creditMode: false,
        debitOrderClient: false,
        searchText: "",
        editDescription: false,
        hover: false,
        addVatExcl: false,
        addVatIncl: false,
        documentNumber: null,
        newCreditDetail: { 
          ticketNumber: '',
          reason: '',
          lines: []
        },
      };
    },
    mounted: function () {
      this.documentNumber = this.$route.params.documentNumber;
      this.loadDocument();      
    },
    methods: {
      loadDocument: function() {
        const hostUrl = this.$config.aimsAPI;
        const documentNumber = this.documentNumber;

        this.$http.get(`${hostUrl}documents/${documentNumber}/documentlines`).then(
            (response) => {
              this.originalBody = response.data;      
              this.originalBody.documentlines.forEach((d) => {
                d.credit = false;
              });     
              this.documentBody = this.deepCopyObject(this.originalBody);

              this.getPurchaseOrder();
              this.checkForBreakdowns();
              this.checkClientPaymentType();
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Document and Lines", error);
            }
          );
      },
      getTitle: function() {
        const documentNumber = this.documentNumber;

        if (this.documentBody.type === 'CREDITNOTE') {
            return `Credit Note # ${documentNumber}`;
        } else {
            return `Invoice # ${documentNumber}`;
        }
      },
      setHover: function(boolVal) {
        this.hover = boolVal;
      },
      shouldEditDescription: function(boolVal) {
        this.editDescription = boolVal;
      },
      checkForBreakdowns: function() {
        this.hasBreakdowns = false;
        this.originalBody.documentlines.forEach((dl) => {
            if (dl.type === 'AIMS_INITIAL_SALE' || dl.type === 'AIMS_SALE') {
               this.hasBreakdowns = true; 
            }
        });
      },
      checkClientPaymentType: function() { 
        const hostUrl = this.$config.aimsAPI;
        const clientNumber = this.documentBody.clientNumber;
        
        this.$http.get(`${hostUrl}clients/${clientNumber}`).then(
            (response) => {
              this.debitOrderClient = (response.data.paymenttype === 'Debited');  
            },
            (error) => {
              this.showError("Error checking Client Payment Type", error);
              console.error(error);
            }
          );
      },
      processDocument: function() {
        this.$swal({
          title: `Are you sure you want to process document ${this.documentBody.documentNumber}?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Process",
        }).then((result) => {
          if (result.value) {
            const hostUrl = this.$config.aimsAPI;
            const documentNumber = this.documentNumber;

            this.$http.put(`${hostUrl}documents/${documentNumber}/process`).then(
                (response) => {
                  this.originalBody = response.data;
                  this.originalBody.documentlines.forEach((d) => {
                    d.credit = false;
                  });  
                  this.documentBody = this.deepCopyObject(this.originalBody);
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Processing Document", error);
                }
            );
          }
        });
      },
      enterCreditMode: function() {
        this.creditMode = true;
      },
      cancelCreditMode: function() {
        this.creditMode = false;
        this.documentBody.documentlines.forEach((d) => (d.credit = false));
      },
      openCreditDetail: function() {
        this.newCreditDetail = { 
          ticketNumber: '',
          reason: '',
          lines: []
        },
        this.$refs.creditDetailModal.open();
      },
      issueCreditNote: function() {
        const markup = `<table class="table table-bordered">
                          <thead>
                            <th>Line</th>
                            <th>Amount</th>
                          </thead>
                            <tbody>
                            ${this.creditLines.map((line) =>
                                 `<tr>
                                    <td>${line.description}</td>
                                    <td>${this.currencyFormat(
                                      line.transactionAmount
                                    )}</td>
                                  </tr>`
                              ).join("")}
                          </tbody>
                        </table>`;
  
        this.$swal({
          title: "Are you sure you want to credit these lines?",
          html: markup,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Credit",
        }).then((result) => {
          if (result.value) {
            this.newCreditDetail.lines = this.creditLines;

            const hostUrl = this.$config.aimsAPI;
            const documentNumber = this.documentNumber;

            this.$http.post(`${hostUrl}documents/${documentNumber}/creditnote/detail`, this.newCreditDetail).then(
                (response) => {
                  this.showSuccess("Credit Note Issued");
                  this.$router.push({
                    name: "clientdocuments",
                    params: {
                      clientNumber: this.documentBody.clientNumber,
                    },
                  });
                },
                (error) => {
                  console.error(error);
                  this.showError("Error issuing credit note", error);
                }
            );
            }
          });
      },
      documentDelete: function() {
        this.$swal({
          title: `Are you sure you want to delete the document ${this.documentNumber}?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.value) {
            const hostUrl = this.$config.aimsAPI;
            const documentNumber = this.documentNumber;

            this.$http.delete(`${hostUrl}documents/${documentNumber}`).then(
                (response) => {
                  this.showSuccess("Document deleted");
                  this.$router.push({
                    name: "clientdocuments",
                    params: {
                      clientNumber: this.documentBody.clientNumber,
                    },
                  });
                },
                (error) => {
                  console.error(error);
                  this.showError("Error deleting document", error);
                }
            );
          } 
        });
      },
      toggleDocLineEdit: function(docline, toggle, addVatExcl) {
        if (addVatExcl === true) {
            let transactionAmount = Number(docline.transactionAmount);
            docline.transactionAmount = parseFloat(transactionAmount + docline.vatAmount);
        }

        this.$set(docline, "editMode", toggle);

        this.addVatExcl = false;
        this.addVatIncl = false;
      },
      selectAll: function(credit) {
        this.documentBody.documentlines.forEach((d) => {
          d.credit = credit;
        });
      },
      selectSection: function(docline) {
        let temp = Number.MAX_SAFE_INTEGER;
        let sameSection = false;

        for (let i = 0; i < this.documentBody.documentlines.length; i++) {
          if (this.documentBody.documentlines[i] == docline) {
            this.documentBody.documentlines[i].credit = docline.credit;
            sameSection = true;
            temp = i;
          }
          if (i > temp && sameSection) {
            if (this.documentBody.documentlines[i].type == 'REMARK') {
              sameSection = false;
            } else {
              this.documentBody.documentlines[i].credit = docline.credit;
            }
          }
        }
      },
      insertLineOtherWay: function(linetype) {
        const hostUrl =  this.$config.aimsAPI;
        const documentNumber = this.documentNumber;
        const documentLineType = linetype;

        this.scrollToLine();

        this.$http.put(`${hostUrl}documents/${documentNumber}/create/${documentLineType}`).then(
            (response) => {
              this.documentBody = response.data;
              this.toggleDocLineEdit(this.documentBody.documentlines[this.documentBody.documentlines.length - 1], true);
            },
            (error) => {
              console.error(error);
              this.showError("Error Adding Document Line", error);
            }
          );
      },
      insertLine: function(linetype) {
        let emptyDocLine;
        switch (linetype) {
          case "REMARK":
            {
              emptyDocLine = {
                class: "adept.aims.classes.billing.DocumentLine",
                type: linetype,
                documentNumber: this.documentNumber,
                objKey: 0,
                description: "Additional Remark",
                transactionAmount: 0.0,
                vatAmount: 0.0,
              };
            }
            break;
          case "ADHOC_SALE":
            {
              emptyDocLine = {
                class: "adept.aims.classes.billing.DocumentLine",
                type: linetype,
                documentNumber: this.documentNumber,
                objKey: 0,
                description: "Additional Item",
                transactionAmount: 0.0,
                vatAmount: 0.0,
              };
            }
            break;
          default: {
            this.showWarning(`Could not find implementation for line type ${linetype}`);
            return;
          }
        }
        emptyDocLine.editMode = true;
        this.documentBody.documentlines.unshift(emptyDocLine);
      },
      updateDescription: function(desc) {
        const hostUrl = this.$config.aimsAPI;
        const documentNumber = this.documentNumber;

        this.$http.put(`${hostUrl}documents/${documentNumber}/update/description`, desc).then(
            (response) => {
              this.documentBody.note = response.data;
              this.showSuccess("Description updated");
              this.editDescription = this.hover = false;
            },
            (error) => {
              this.showError("Error updating description", error);
              console.error(error);
            }
          );
      },
      addVatExclusiveAmount: function(data) {        
        data.transactionAmount = data.transactionAmount - data.vatAmount;
        this.addVatExcl = true;
      },
      addVatInclusiveAmount: function() {        
        this.addVatIncl = true;        
      },
      async saveDocumentLine(docLine) {
        const hostUrl = this.$config.aimsAPIv2;
        
        if (this.addVatExcl === true) {
          await this.$http.put(`${hostUrl}documents/documentline/vatinclamount`, docLine).then(
              (response) => {
                docLine = response.data;
                this.addVatIncl = false;
                this.addVatExcl = false;
              },
              (error) => {
                console.error(error);
              }
            );
  
          docLine.editMode = false;
  
          await this.$http.put(this.$config.aimsAPI + "documents/documentline/save", docLine).then(
            (response) => {
              let returnedResult = response.data;
              this.showSuccess("Document line saved");
              this.addVatIncl = false;
              this.addVatExcl = false;
  
              const indexOfLine = this.documentBody.documentlines.findIndex(
                (doc) => doc.objKey === returnedResult.documentlineSaved.objKey
              );
              this.documentBody.documentlines[indexOfLine] =
                returnedResult.documentlineSaved;
  
              this.documentBody.totalAmount = returnedResult.totalAmount;
  
              //this is a temporary fix, to make it behave more smoothly
              this.loadDocument();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving Document Line", error);
            }
          );
        } else {
          docLine.editMode = false;
          this.$http.put(this.$config.aimsAPI + "documents/documentline/save", docLine).then(
              (response) => {
                let returnedResult = response.data;
                this.showSuccess("Document line saved");
                this.addVatIncl = false;
                this.addVatExcl = false;
  
                const indexOfLine = this.documentBody.documentlines.findIndex(
                  (doc) => doc.objKey === returnedResult.documentlineSaved.objKey
                );
                this.documentBody.documentlines[indexOfLine] =
                  returnedResult.documentlineSaved;
  
                this.documentBody.totalAmount = returnedResult.totalAmount;
  
                //this is a temporary fix, to make it behave more smoothly
                this.loadDocument();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Document Line", error);
              }
            );
        }
      },
      deleteDocumentLine: function(dl) {
        const dlindex = this.documentBody.documentlines.findIndex(
          (documentline) => documentline.documentLineNumber === dl
        );
        this.$swal({
          title: "Are you sure you want to delete this document line ?",
          text: this.documentBody.documentlines[dlindex].description,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.value) {
            const hostUrl = this.$config.aimsAPI;

            this.$http.put(`${hostUrl}documents/documentline/delete`, this.documentBody.documentlines[dlindex]).then(
                (response) => {
                  this.originalBody = response.data;
                  this.originalBody.documentlines.forEach((d) => {
                    d.credit = false;
                  });  
                  this.documentBody = this.deepCopyObject(this.originalBody);
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Deleting Document Line", error);
                }
              );
          }
        });
      },
      downloadInvoicePdf: function() {
        const hostUrl = this.$config.aimsAPI;
        const documentNumber = this.documentNumber;

        this.$http.get(`${hostUrl}documents/${documentNumber}/pdf`, { responseType: "arraybuffer", }).then(
            (response) => {
              const contentType = response.headers.get("Content-Type");
              let blob = new Blob([response.data], {
                type: contentType,
              });
              const fileName = response.headers
                .get("Content-Disposition")
                .split("filename = ")[1];
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
            },
            (error) => {
              console.error(error);
              this.showError("Error Downloading Document PDF", error.data);
            }
          );
      },
      downloadInvoicePdfOriginal: function() {
        const hostUrl = this.$config.aimsAPI;
        const documentNumber = this.documentNumber;
  
        this.$http.get(`${hostUrl}documents/${documentNumber}/original`, { responseType: "arraybuffer", }).then(
            (response) => {
              const contentType = response.headers.get("Content-Type");
              let blob = new Blob([response.data], {
                type: contentType,
              });
              const fileName = response.headers
                .get("Content-Disposition")
                .split("filename = ")[1];
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
            },
            (error) => {
              console.error(error);
              this.showError("Error Downloading Document PDF", error.data);
            }
          );
      },
      getPurchaseOrder: function() {
        const hostUrl = this.$config.aimsAPI;
        const documentNumber = this.documentNumber;

        this.$http.get(`${hostUrl}documents/${documentNumber}/purchaseorder`).then(
            (response) => {
              this.purchaseOrder = response.data;
            },
            (error) => {
              this.showError("Error fetching Purchase Order", error);
              console.error(error);
            }
          );
      },
      savePurchaseOrder: function() {
        const hostUrl = this.$config.aimsAPI;
        const documentNumber = this.documentNumber;

        this.$http.put(`${hostUrl}documents/${documentNumber}/purchaseorder/save`, this.purchaseOrder).then(
            (response) => {
              this.showSuccess(response.data.message);
              this.loadDocument();
              this.$refs.purchaseOrderDialog.close();
            },
            (error) => {
              this.showError("Error updating Purchase Order", error);
              console.error(error);
            }
          );
      },
      deletePurchaseOrder: function() {
        this.$swal({
          title: "Are you sure you want to delete this Purchase Order?",
          text: this.purchaseOrder.purchaseOrder,
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((response) => {
          if (response.value) {
            const hostUrl = this.$config.aimsAPI;
            const documentNumber = this.documentNumber;

            this.$http.delete(`${hostUrl}documents/${documentNumber}/purchaseorder`).then(
                (response) => {
                  this.showSuccess(response.data.message);
                  this.loadDocument();
                  this.$refs.purchaseOrderDialog.close();
                },
                (error) => {
                  this.showError("Error deleting Purchase Order", error);
                  console.error(error);
                }
              );
          }
        });
      },
      emailDialog: function() {
        const hostUrl = this.$config.aimsAPI;
        const clientNumber = this.documentBody.clientNumber;

        this.$http.get(`${hostUrl}documents/contacts/${clientNumber}`).then(
            (response) => {
              this.clientContacts = response.data;
              this.selectedContacts = [];
              this.$refs.emailDialogModal.open();
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Client Contacts", error);
            }
          );
      },
      sendEmail: function() {
        let addArray = this.additionalContacts.split(",");
        addArray.forEach((e) => {
          this.contactClicked(e);
        });
  
        if (this.selectedContacts.length > 0) {
          this.$refs.emailDialogModal.isLoading();
          const hostUrl = this.$config.aimsAPI;
          const documentNumber = this.documentNumber;

          this.$http.put(`${hostUrl}documents/${documentNumber}/email`, this.selectedContacts).then(
              (response) => {
                this.showSuccess("Email Sent");
                this.$refs.emailDialogModal.close();
              },
              (error) => {
                console.error(error);
                this.showError("Emailing Document");
              }
            )
            .finally(() => {
              this.$refs.emailDialogModal.isLoading();
            });
        }
        this.additionalContacts = "";
        this.selectedContacts = [];
      },
      contactClicked: function(emailAddress) {
        if (emailAddress.length > 0) {
          const indx = this.selectedContacts.indexOf(emailAddress);
          if (indx >= 0) {
            this.selectedContacts.splice(indx, 1);
          } else {
            this.selectedContacts.push(emailAddress);
          }
        }
      },
      isSelected: function(emailAddress) {        
        const contactIndex = this.selectedContacts.findIndex((contactEmail) => contactEmail === emailAddress);
        if (contactIndex >= 0) {
          return true;
        } else {
          return false;
        }
      },
      showBillingBreakdown: function(documentLine) {
        this.$refs.billingBreakdownModal.open(documentLine.documentLineNumber);
      },
      showSale: function(saleNo) {
        const hostUrl = this.$config.aimsAPI;
        
        this.$http.get(`${hostUrl}sales/${saleNo}/getProductDefinition`).then(
            (response) => {
              let salesProdDef = response.data;
  
              const productRoute = this.getProductDefinition(salesProdDef);
  
              if (productRoute === -1) {
                window.open(
                  "https://staff.adept.co.za/portal/staff#!sale.LIST/saleNumber=" +
                    saleNo
                );
              } else {
                this.$router.push({
                  name: productRoute.routeName,
                  params: {
                    saleNumber: saleNo,
                  },
                });
              }
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Sale's Navigation", error);
            }
          );
      },
      scrollToLine: function() {
        let divLines = this.$refs.documentline.$refs.docuLines;
        divLines.scrollTop = divLines.scrollHeight;
      }
    },
  };
  </script>
  
  <style scoped>
  .table>thead>tr>th {
    padding: 1em;
    background-color: #3a3f51;
    color: #FFFFFF; 
  
    position: sticky;
    top: 0;
    z-index: 2;
  } 
  .pull-right {
    float: right;
  }
  </style>
  