<template>
  <service-screen-layout
    v-if="isCappedOrUncappedService"
    :parts="productParts"
  >
  <template v-slot:account>
    <div class="row">
      <div class="col-md-8">
        <AccountDetails 
          :connectivitypackage="connectivityPackage"
          :account="saleDefinition.definition.pppoe"
          :sale="saleDefinition.sale"
          @save-sale="saveSaleDefinition"
        />
      </div>
      <div class="col-md-4" v-if="isCappedService">
        <ConnectivityStatus 
          v-if="hasSessions"
          v-allowed:view="['STAFF', 'CLIENT']"
          :connectivityService="saleDefinition.definition.cappedService"
          :session="session"
        />
        <ConnectivityPackage 
          :bundle="saleDefinition.definition.cappedService.connectivityPackage"
        />
      </div>
      <div class="col-md-4" v-else>
        <ConnectivityStatus 
          v-if="hasSessions"
          v-allowed:view="['STAFF', 'CLIENT']"
          :connectivityService="saleDefinition.definition.uncappedService"
          :session="session"
        />
        <ConnectivityPackage 
          :bundle="saleDefinition.definition.uncappedService.connectivityPackage"
        />
      </div>
    </div>
  </template>
  <template v-slot:ipaddresses>
    <div class="row">
      <div class="col-md-12">
        <AllocatedIPAddresses 
          :editable="false"
          :heading="'Assigned Addresses'"
          :addresses="combinedAllocatedNetworks"
        />
      </div>
    </div>
  </template>
  <template v-slot:usage>
    <ConnectivityUsageGraph
      :currentDailyUsageData="currentDailyUsageData"
      :graphTitle="saleDefinition.product.description"
      :usageType="usageType"
      :timePeriod="usageCaption"
      :usageYear="usageYear"
      :saleStartYear="saleStartYear"
      :usagePeriod="usagePeriod"
      :totalUsage="totalUsage"
      :saleStartPeriod="saleStartPeriod"
      :saleStartDate="saleDefinition.sale.startDate"
      @previousMonthUsage="previousMonthUsage"
      @nextMonthUsage="nextMonthUsage"
      @hourlyUsage="hourlyUsage"
      @dailyUsage="dailyUsage"
      @yearlyUsage="yearlyUsage"
      @previousYearlyUsage="previousYearlyUsage"
      @nextYearlyUsage="nextYearlyUsage"
    />
  </template>
  <template v-slot:packagechange v-if="isUncappedService">
    <div class="row">
        <div class="col-md-12">
          <PackageChanges
            :heading="'Regrade'"
            @packageChange="packageChange"
            :availablePackages="saleDefinition.selectableOptions.connectivityPackage"
            :packageChanges="saleDefinition.definition.uncappedService.packageChangeInstructions"
          />
        </div>
      </div>
  </template>
  <template v-slot:allocateddata v-if="isCappedService">
    <div class="row">
        <div class="col-md-12">
          <AllocatedTopups
            :heading="'Topups'"
            :showStatus="true"
            @topupAdded="topupAdded"
            :availableTopups="saleDefinition.selectableOptions.topup"
            :allocatedTopups="saleDefinition.definition.cappedService.allocatedTopups"
          />
          <AllocatedBundles
            bundleKeyName="connectivityPackageNumber"
            :heading="'Monthly Data'"
            :availableBundles="saleDefinition.selectableOptions.connectivityPackage"
            :allocatedBundles="saleDefinition.definition.cappedService.allocatedBundles"
          />
        </div>
      </div>
  </template>
  <template v-slot:notification-settings v-if="isCappedService">
    <div class="row">
      <div class="col-md-12">
        <UsageRecipients
            :usageRecipients="saleDefinition.definition.cappedService.usageRecipients"
            @recipientAdded="recipientAdded"
            @recipientRemoved="recipientRemoved"
          />
      </div>
    </div>
  </template>
  <template v-slot:sessions>
    <div class="row">
      <div class="col-md-12">
        <RadiusSessions
          :sessions="saleDefinition.definition.pppoe.sessions"
          cpeAddressName="CPE Address"
          cpeAddressField="cpeAddress"
        />
      </div>
    </div>
  </template>
  <template v-slot:line v-if="saleDefinition.definition.line">
    <div class="row">
        <div class="col-md-12">
          <ConnectivityLine
              v-allowed:view="['STAFF', 'CLIENT']"
              :line="saleDefinition.definition.line"
              @saveSale="saveSaleDefinition"
          />
        </div>
    </div>
  </template>
  <template v-slot:router v-if="saleNumber">
    <div class="row">
      <div class="col-md-12">
        <RouterConfig
          :routerConfig="saleDefinition.definition.routerConfig"
          :saleNumber="saleNumber"
          @routerConfigSaved="routerConfigSavedHandler"
          @reloadSale="getSaleDefinition"
        />
      </div>
    </div>
  </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import AccountDetails from "@/components/ObjParts/SingleConstructed/AccountDetails";
import AllocatedBundles from "@/components/ObjParts/MultipleLoaded/AllocatedBundles";
import AllocatedTopups from "@/components/ObjParts/MultipleCaptured/AllocatedTopups";
import PackageChanges from "@/components/ObjParts/MultipleCaptured/PackageChanges";
import RadiusSessions from "@/components/ObjParts/MultipleLoaded/RadiusSessions";
import ConnectivityPackage from "@/components/ObjParts/SingleConstructed/ConnectivityPackage";
import ConnectivityStatus from "@/components/ObjParts/SingleConstructed/ConnectivityStatus";
import ConnectivityAddress from "@/components/ObjParts/SingleConstructed/ConnectivityAddress";
import AllocatedIPAddresses from "@/components/ObjParts/MultipleCaptured/AllocatedIPAddresses";
import UsageRecipients from "@/components/ObjParts/MultipleCaptured/UsageRecipients";
import ConnectivityLine from "@/components/ObjParts/SingleCaptured/ConnectivityLine";
import RouterConfig from "@/components/ObjParts/OptionalCaptured/RouterConfig";
import ConnectivityUsageGraph from "@/components/Graphs/ConnectivityUsageGraph.vue";

export default {
  extends: SaleFunctions,
  components : {
    ServiceScreenLayout,
    AccountDetails,
    AllocatedBundles,
    AllocatedTopups,
    PackageChanges,
    RadiusSessions,
    ConnectivityPackage,
    ConnectivityStatus,
    ConnectivityAddress,
    AllocatedIPAddresses,
    UsageRecipients,
    ConnectivityLine,
    RouterConfig,
    ConnectivityUsageGraph
  },
  data() {
    return {
      parts: [
        {
          name: "account",
          icon: "fa fa-user-circle",
          displayName: "Account",
          permissions: ["*"],
        },
        {
          name: "ipaddresses",
          icon: "fa-solid fa-location-dot",
          displayName: "IP Addresses",
          permissions: ["STAFF"],
        },
        {
          name: "usage",
          icon: "fa fa-area-chart",
          displayName: "Usage",
          permissions: ["*"],
        },
        {
          name: "packagechange",
          icon: "fa fa-arrows-v",
          displayName: "Regrade",
          permissions: ["*"],
        },
        {
          name: "allocateddata",
          icon: "fa fa-database",
          displayName: "Topups and Data",
          permissions: ["*"],
        },
        {
          name: "notification-settings",
          icon: "fa fa-bullhorn",
          displayName: "Notifications",
          permissions: ["*"],
        },
        {
          name: "sessions",
          icon: "fa fa-clock-o",
          displayName: "Sessions",
          permissions: ["STAFF"],
        },
        {
          name: "line",
          icon: "fa fa-link",
          displayName: "Line",
          permissions: ["*"],
        },
        {
          name: "router",
          icon: "fa fa-cogs",
          displayName: "Router",
          permissions: ["STAFF"],
        }
      ],
      saleNumber: null,
      saleDefinition: {
        product: {
          classificationProvider: "",
        },
        sale: {},
        definition: {
          routerConfig: {},
          pppoe: {
            allocatedIPv4Network: {},
            allocatedIPv6Network: {},
            sessions: [],
          },
        },
        selectableOptions: {
          bundle: [],
          topup: [],
        },
        supportingEnums: {
          account: {
            cappedType: [],
          },
        },
        supportingObjs: {
          dyndns: {},
          discount: {},
          dyndnsdomain: {},
          routerConfig: {},
        },
      },
      usageType: "",
      usagePeriod: null,
      usageYear: null,
      usageCaption: "",
      newUsageRecipient: "",
      totalUsage: 0,
      currentDailyUsageData: [],
      netmasksV4: [32],
      netmasksV6: [64],
      assignChoices: {
        assignIPFamily: null,
        assignIPNetmask: null,
      },
      filteredNetMaskList: [],
      listUsagePeriods: [],
      saleStartPeriod: null,
      saleStartYear: null,
    }
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition();
    this.usagePeriod = this.getCurrentPeriod();    
    this.usageYear = ("" + this.getCurrentPeriod()).substring(0, 4); 
  },
  computed: {
    isCappedOrUncappedService() {
      return this.saleDefinition.definition.cappedService || this.saleDefinition.definition.uncappedService;
    },
    connectivityPackage() {
      return this.saleDefinition.definition.cappedService ? this.saleDefinition.definition.cappedService.connectivityPackage : this.saleDefinition.definition.uncappedService.connectivityPackage;
    },
    isUncappedService() {
      return this.saleDefinition.definition.uncappedService;
    },
    isCappedService() {
      return this.saleDefinition.definition.cappedService;
    },
    hasSessions() {
      return this.saleDefinition.definition.pppoe.sessions.length > 0;
    },
    session() {
      return this.saleDefinition.definition.pppoe.sessions.reduce((a, b) => a.lastUpdate > b.lastUpdate ? a : b);
    },
    combinedAllocatedNetworks() {      
      const allocatedNetworks = [];
      allocatedNetworks.push(this.saleDefinition.definition.pppoe.allocatedIPv4Network, this.saleDefinition.definition.pppoe.allocatedIPv6Network);
      return allocatedNetworks;
    },
    productParts() {
      if (!this.saleDefinition.definition.line) {
        this.parts = this.parts.filter((p) => p.name != "line");
      }
      if (!this.saleDefinition.definition.cappedService) {
        this.parts = this.parts.filter(
          (p) => p.name != "allocateddata" && p.name != "notification-settings"
        );
      } else if (this.saleDefinition.definition.cappedService) {
        this.parts = this.parts.filter(
          (p) => p.name != "packagechange"
        );
      }
      return this.parts;
    }
  },
  methods: {
    getSaleDefinition: function() {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (response) => {
          this.saleDefinition = response.data;
          console.log(this.saleDefinition);
          this.saleStartPeriod = this.dateToPeriod(
            this.saleDefinition.sale.startDate
          );
          this.saleStartYear = this.saleStartPeriod.substring(0, 4); 
          this.saleDefinition.definition.pppoe.fullUsername = `${this.saleDefinition.definition.pppoe.username}@${this.saleDefinition.definition.pppoe.pppoeRealm.name}`;
          this.translateCurrentDailyUsageData(this.saleDefinition.definition.pppoe.dailySummary);
          this.getCurrentMonthDataUsage();
        },
        (error) => {
          error(error);
          this.showError("Error fetching Sale Definition", error);
        }
      )
    },
    getCurrentMonthDataUsage: function() {
      if (this.usagePeriod === this.getCurrentPeriod()) {
        const hostUrl = this.$config.aimsAPI;
        const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
        const usagePeriod = this.usagePeriod;

        this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${usagePeriod}/daily`).then(
            (response) => {                            
              this.translateCurrentDailyUsageData(response.data);
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Usage", error);
            }
          );
      }
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const hostUrl = this.$config.aimsAPI;
          const saleNumber = this.saleNumber;

          this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.saleDefinition.definition.pppoe.fullUsername = this.saleDefinition.definition.pppoe.username + "@" + this.saleDefinition.definition.pppoe.pppoeRealm.name;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving FTTH Account", error);
              }
            );
        }
      });
    },
    routerConfigSavedHandler: function(routerConfig) {
      this.saleDefinition.definition.routerConfig = routerConfig;
      this.saveSaleDefinition();
    },
    topupAdded: function (topupAdded) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;
      const accountTopup = {
        class: "za.co.adept.aims.connectivity.ConnectivityTopupAllocation",
        userReadOnly: false,
      };

      accountTopup.connectivityTopupNumber = topupAdded.objKey;
      accountTopup.cappedConnectivityServiceNumber = this.saleDefinition.definition.cappedService.cappedConnectivityServiceNumber;
      accountTopup.pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, accountTopup).then(
          (response) => {
            response.data.isNew = true;
            response.data.topup = this.deepCopyObject(topupAdded);
            this.saleDefinition.definition.cappedService.allocatedTopups.unshift(response.data);
            this.saleDefinition.definition.cappedService.currentlyCapped = false;
            this.showSuccess("Topup successfully added");
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Topup", error);
          }
        );
    },
    packageChange: function (packageChange, immediate) {
      const hostUrl = this.$config.aimsAPIv2;
      const saleNumber = this.saleNumber;
     
      let changePackageInstruction = this.deepCopyObject(this.saleDefinition.supportingObjs.packageChangeInstructions);
      changePackageInstruction.uncappedConnectivityServiceNumber =  this.saleDefinition.definition.uncappedService.uncappedConnectivityServiceNumber;
      changePackageInstruction.connectivityPackageNumber = packageChange.objKey;
      if (immediate) {
        changePackageInstruction.billingPeriod = this.getCurrentPeriod();
      } else {
        changePackageInstruction.billingPeriod = this.increaseBillingPeriod(this.getCurrentPeriod());
      }
      this.createObjPart(changePackageInstruction, true).then(
        (response) => {
          this.showSuccess("Regrade successfully submitted");
          this.saleDefinition.definition.uncappedService.packageChangeInstructions.unshift(response.data);
        },
        (error) => {
          console.error(error);
          this.showError("Error with Regrade Instruction", error);
        }
      );
    },
    recipientAdded: function(recipientNumber) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;
      const newUsageRecipient = {};

      newUsageRecipient.class = "za.co.adept.aims.connectivity.CappedServiceUsageRecipient";
      newUsageRecipient.cappedConnectivityServiceNumber = this.saleDefinition.definition.cappedService.cappedConnectivityServiceNumber;
      newUsageRecipient.recipient = recipientNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, newUsageRecipient).then(
          (response) => {
            if (this.saleDefinition.definition.cappedService) {
              this.saleDefinition.definition.cappedService.usageRecipients.push(response.data);
            } else {
              this.saleDefinition.definition.uncappedService.usageRecipients.push(response.data);
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Usage Recipient", error);
          }
        );
    },
    recipientRemoved: function(usageRecipient) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, usageRecipient).then(
          (response) => {
            if (this.saleDefinition.definition.cappedService) {
              const index = this.saleDefinition.definition.cappedService.usageRecipients.findIndex((u) =>
                    u.usageRecipientNumber === usageRecipient.usageRecipientNumber
                );
              this.saleDefinition.definition.cappedService.usageRecipients.splice(index, 1);
            } else {
              const index = this.saleDefinition.definition.uncappedService.usageRecipients.findIndex((u) =>
                    u.usageRecipientNumber === usageRecipient.usageRecipientNumber
                );
              this.saleDefinition.definition.uncappedService.usageRecipients.splice(index, 1);
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing Usage Recipient", error);
          }
        );
    },
    hourlyUsage: function(day) {
      const hostUrl = this.$config.aimsAPI;
      const lastDay = new Date(new Date(this.usageCaption).getFullYear(), new Date(this.usageCaption).getMonth() + 1, 0).getDate();
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;

      if (day == "previous") {
        day = parseInt(this.usageCaption.split(" ")[0]) - 1;
      } else if (day == "next") {
        day = parseInt(this.usageCaption.split(" ")[0]) + 1;
      };

      if (day < 1) {
        day = new Date(new Date(this.usageCaption).getFullYear(), new Date(this.usageCaption).getMonth(), 0).getDate();

        if (("" + this.usagePeriod).substring(4, 6) == "01") {
          this.usagePeriod = this.usagePeriod - 100 + 11; //dec last year
          this.usageYear--;
        } else {
          this.usagePeriod--;
        }
      } else if (day > lastDay) {
        day = 1;
        if (("" + this.usagePeriod).substring(4, 6) == "01") {
          this.usagePeriod = this.usagePeriod - 100 + 11; //dec last year
          this.usageYear++;
        } else {
          this.usagePeriod++;
        }
      };

      this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${this.usagePeriod}/day/${day}/hourly`).then(
        (response) => {
          this.translateCurrentHourlyUsageData(response.data, day);
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching hourly usage", error)
        }
      )
    },
    translateCurrentHourlyUsageData: function (usageData, day) {
      this.currentDailyUsageData = [];
      this.totalUsage = 0;
      this.usageCaption =
        day + " " + this.prettifyBillingPeriod(this.usagePeriod);
      this.usageType = "hourly";
      usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        const translatedDataUsageHour = {
          label: c.hour,
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
        this.currentDailyUsageData.push(translatedDataUsageHour);
      });
    },
    dailyUsage: function(month, year) {
      this.usagePeriod = this.usagePeriod.toString();
      this.usagePeriod = `${year}${month}`;
      this.usagePeriod = parseInt(this.usagePeriod);
      const startPeriod =  parseInt(this.saleStartPeriod);    
      
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;

      if (this.usagePeriod < startPeriod) {        
        this.usagePeriod = startPeriod;
      } 
      
      if (this.usagePeriod > parseInt(this.getCurrentPeriod())) {
        this.usagePeriod = parseInt(this.getCurrentPeriod());
      }
      
      this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${this.usagePeriod}/daily`).then(
          (response) => {
            this.translateCurrentDailyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching daily usage", error);
          }
        );
    },
    translateCurrentDailyUsageData: function (usageData) {
      this.currentDailyUsageData = {};
      this.totalUsage = 0;
      this.usageCaption = "" + this.prettifyBillingPeriod(this.usagePeriod);
      this.usageType = "daily";
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      usageData = usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        return {
          label: c.day + " " + months[c.month - 1],
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
      });
      this.currentDailyUsageData = {
        tooltip: "Click for hourly usage",
        data: usageData,
      };
    },
    previousMonthUsage: function() {
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;

      if (("" + this.usagePeriod).substring(4, 6) == "01") {
        this.usagePeriod = this.usagePeriod - 100 + 11; //dec last year
        this.usageYear--;
      } else {
        this.usagePeriod--;
      };

      this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${this.usagePeriod}/daily`).then(
        (response) => {            
          this.translateCurrentDailyUsageData(response.data);
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching daily usage", error);
        }
      );
    },
    nextMonthUsage() {  
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;

      if (this.usagePeriod < this.getCurrentPeriod()) {
        if (("" + this.usagePeriod).substring(4, 6) == "12") {
          this.usagePeriod = this.usagePeriod + 100 - 11; //jan next year
          this.usageYear++;
        } else {
          this.usagePeriod++;
        }
        this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${this.usagePeriod}/daily`).then(
            (response) => {                            
              this.translateCurrentDailyUsageData(response.data);
            },
            (error) => {
              console.error(error);
              this.showError("Error fetching daily usage", error);
            }
          );
      }
    },
    yearlyUsage: function() {
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
      let qryPeriod = "" + this.usageYear + "12";
      this.usageCaption = this.usageYear;

      this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${qryPeriod}/monthly`).then(
          (response) => {
            this.translateMonthlyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching monthly usage", error);
          }
        );
    },
    previousYearlyUsage: function() {
      this.usageYear--;
      this.yearlyUsage();
    },
    nextYearlyUsage: function() {
      this.usageYear++;
      this.yearlyUsage();
    },
    translateMonthlyUsageData: function (usageData) {      
      this.currentDailyUsageData = {};
      this.totalUsage = 0;
      this.usageType = "monthly";
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      usageData = usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        return {
          label: months[c.month - 1],
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
      });
      this.currentDailyUsageData = {
        tooltip: "Click for daily usage",
        data: usageData,
      };
    },
  },
}
</script>