<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="mt-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <h4>Liquid Integration</h4>
                        <div class="logo-btn-row pull-right align-items-center">
                            <img class="mr-2" src="/img/services/fttb/liquid.png" alt="" srcset="" style="width: auto; height: 80%">
                            <button @click.prevent="addNewAccount()" class="btn btn-primary">Add Customer Account</button>
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div class="row">
                        <div class="col-md-12">
                                <table class="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Customer Account ID</th>
                                            <th>Customer Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="liquidCustomers.length > 0">
                                        <tr v-for="liquidCustomer in liquidCustomers" :key="liquidCustomer.liquidCustomerNumber">
                                            <td>{{ liquidCustomer.customerAccountId }}</td>
                                            <td>{{ liquidCustomer.customerName }}</td>
                                            <td class="text-center">
                                                <button
                                                    v-tooltip="'2FA'"
                                                    type="button"
                                                    @click="openOtp(liquidCustomer)"
                                                    class="btn btn-sm btn-green m-1"
                                                    v-if="liquidCustomer.secretKey"
                                                >
                                                    <em class="fa-solid fa-key"></em>
                                                </button>
                                                <button
                                                    v-tooltip="'Edit Account'"
                                                    type="button"
                                                    @click="editAccount(liquidCustomer)"
                                                    class="btn btn-sm btn-info m-1"
                                                >
                                                    <em class="fa fa-edit fa-fw"></em>
                                                </button>
                                                <button @click.prevent="deleteAccountId(liquidCustomer)" class="btn btn-primary"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                        <td colspan="1">No Liquid Customer Id associated with this client</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddLiquidSubscriptionAccountId ref="accountModal" :liquidAccount="selectedAccount" @loadLiquidCustomers="loadLiquidCustomers()"/>        
        <modal ref="otpModal" size="modal-md" dismissText="Close" :headerNeeded="false" :saveNeeded="false">
            <div class="row">
                <div class="form-group col-md-12">
                    <Otp ref="otpComponent" :secretKey="selectedAccount.secretKey"/>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Otp from "@/components/GeneralComponents/Otp";
import Modal from "@/components/GeneralComponents/Modal";
import AddLiquidSubscriptionAccountId from "@/components/Admin/Modals/AddLiquidSubscriptionAccountId.vue";

export default {
    components: {
        Otp,
        Modal,  
        AddLiquidSubscriptionAccountId
    },
    data() {
        return {
            liquidCustomers: [],
            newAccount: {},
            selectedAccount: {},
        }
    },
    mounted() {
        this.loadLiquidCustomers();
    },
    methods: {
        loadLiquidCustomers() {
            const hostUrl = this.$config.aimsAPI;
            const clientNumber = this.$route.params.clientNumber;
            this.$http.get(`${hostUrl}clients/integrations/${clientNumber}`).then(
                (response) => {
                    this.newAccount = response.data;
                    this.liquidCustomers = _.orderBy(response.data.liquidcustomers, 'customerName', 'asc');
                },
                (error) => {
                    console.warn(error);
                    this.showError("Error Fetching Liquid Accounts list", error);        
                }
            );
        },
        addNewAccount() {
            this.selectedAccount = this.deepCopyObject(this.newAccount);
            this.$refs.accountModal.open();
        },
        editAccount(account) {
            this.selectedAccount = this.deepCopyObject(account);
            this.$refs.accountModal.open();
        },
        deleteAccountId: function(data) {
            this.$swal({
                title: `Are you sure you want to delete Customer Account: ${data.customerAccountId}`,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const liquidCustomerNumber = data.liquidCustomerNumber;
                    this.$http.delete(`${hostUrl}clients/integrations/liquid/${liquidCustomerNumber}`).then(
                        (response) => {
                            this.showSuccess(`Successfully deleted Customer Account ID: ${data.customerAccountId}`);
                            this.loadLiquidCustomers();
                        },
                        (error) => {
                            this.showError("Error deleting Customer Account ID");
                            console.error(error);
                        }
                    );
                }
            });
        },
        openOtp(account) {
            this.selectedAccount = this.deepCopyObject(account);
            this.$refs.otpModal.open();
        },
    },
}
</script>

<style lang="scss" scoped>
    .logo-btn-row {
        width: auto;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        height: 40px;

        img {
            width: 6em;
            object-fit: cover;
        }
    }
</style>