<template>
    <div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <button class="btn btn-info" @click="addPackage">
                    Create New Package
                </button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered" v-if="webhostingPackages.length > 0">
                    <table class="table">
                        <thead>
                            <tr class="text-nowrap">
                                <th>Description</th>
                                <th>Setup fee (excl. VAT)</th>
                                <th>Price (excl. VAT)</th>
                                <th>Price (incl. VAT)</th>
                                <th>Expired</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="webhostingPackage in webhostingPackages" 
                                :key="webhostingPackage.webHostingPackageNumber"
                                :class="{ 'bg-primary': webhostingPackage.expired }"
                            >
                                <td>{{ webhostingPackage.description }}</td>
                                <td>{{ webhostingPackage.setupfee }}</td>
                                <td>{{ getPrice(webhostingPackage.webhostingpackageprices, currentPeriod) }}</td>
                                <td>
                                    {{ getPrice(webhostingPackage.webhostingpackageprices, currentPeriod, true) }}
                                    <span 
                                        class="pull-right"
                                        style="cursor: pointer;"
                                        v-tooltip="'Edit Row Price'"
                                        @click="openPriceModal(webhostingPackage)"
                                    >
                                        <em class="fa fa-edit fa-fw text-green"></em>
                                    </span>
                                </td>
                                <td>
                                    <span 
                                        class="badge"
                                        :class="webhostingPackage.expired ? 'badge-warning' : 'badge-green'"
                                    >
                                        {{ webhostingPackage.expired }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-info m-1" v-tooltip="'Edit'" type="button" @click="editPackage(webhostingPackage)">
                                        <em class="fa fa-edit fa-fw"></em>
                                    </button>
                                    <button class="btn btn-sm btn-primary" v-tooltip="'Delete Row'" type="button" @click="deletePackage(webhostingPackage)">
                                        <em class="fa fa-trash fa-fw"></em>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="alert alert-info">No Webhosting Packages Available</div>
            </div>
        </div>
        <ModifyWebHostingPackageModal
            ref="modifyPackageModal"
            :webhostingPackage="webhostingPackage"
            @savePackage="savePackage"
            @reloadPackages="loadWebhostingPackages()"
        />
        <AddNewWebHostingPackageModal
            ref="addPackageModal"
            :newWebhostingPackage="newPackage"
            @savePackage="savePackage"
            @reloadPackages="loadWebhostingPackages()"
        />
        <AddPriceModal 
            ref="addPriceModal"
            :row-price="rowPrice"
            :new-price="newPrice"
            @addPrice="addPrice"
            @pricesAdded="loadWebhostingPackages()"
        />
    </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import ModifyWebHostingPackageModal from "@/components/Admin/Modals/ModifyWebHostingPackageModal.vue";
import AddNewWebHostingPackageModal from "@/components/Admin/Modals/AddNewWebHostingPackageModal.vue";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal.vue";

export default {
    extends: PriceFunctions,
    components: {
        ModifyWebHostingPackageModal,
        AddNewWebHostingPackageModal,
        AddPriceModal
    },
    props: {
        productNumber: {
            type: Number,
            required: true
        },
        userEditRight: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            webhostingPackages: [],
            period: 0,
            webhostingPackage: {
                webhostingpackageprices: [],
            },
            newPackage: {},
            pricePackage: {},
            newPrice: {},
            rowPrice: [],
        }
    },
    computed: {
        currentPeriod() {
            return this.period = this.getCurrentPeriod();
        }
    },
    mounted() {
        this.loadWebhostingPackages();
    },
    methods: {
        loadWebhostingPackages: function() {
            const hostUrl = this.$config.aimsAPI;
            const productNumber = this.productNumber;                

            this.$http.get(`${hostUrl}pricing/webhostingpackages/product/${productNumber}`).then(
                (response) => {
                    this.webhostingPackages = response.data;                   
                },
                (error) => {
                    this.showError("Error fetching Webhosting packages", error);
                }       
            );
        },
        addPackage: function() {
            this.newPackage = {
                class: "za.co.adept.aims.webhosting.WebHostingPackage",
                productNumber: this.productNumber,
                webhostingpackageprices: [],
            }
            this.$refs.addPackageModal.open();            
        },
        savePackage: function(data) {
            const hostUrl = this.$config.aimsAPI;

            if (data.webHostingPackageNumber) {
            
                this.$http.put(`${hostUrl}pricing/webhostingpackages/update`, data).then(
                    (response) => {
                        this.showSuccess("Package successfully updated");
                        this.$refs.modifyPackageModal.$refs.modifyPackageModal.close();
                        this.loadWebhostingPackages();                                     
                    },
                    (error) => {
                        this.showError('Error updating package', error);
                        console.error(error);
                    }
                );
            } else {
                this.$http.post(`${hostUrl}pricing/webhostingpackages/create`, data).then(
                    (response) => {
                        this.showSuccess('New package successfully created');
                        this.$refs.addPackageModal.$refs.addPackageModal.close();
                        response.data.webhostingpackageprices = [];
                        this.openPriceModal(response.data);
                    },
                    (error) => {
                        this.showError('Error creating new package', error);
                        console.error(error);
                    }
                ).finally(() => {
                    this.$refs.addPackageModal.$refs.addPackageModal.isLoading();              
                });
            }
        },
        openPriceModal: function(data) {                        
            this.rowPrice = this.deepCopyObject(data.webhostingpackageprices);
            this.newPrice = this.deepCopyObject({
                webHostingPackageNumber: data.webHostingPackageNumber,
                class: "za.co.adept.aims.webhosting.WebHostingPackagePrice",
                validFrom: 0,
                validUntil: 0
            });

            this.$refs.addPriceModal.open();
        },
        addPrice: function(newPrice) {
            this.rowPrice.forEach(price => {
            if (price.validUntil == 0) {
                price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
            }
            });
            this.rowPrice.push(newPrice);
            this.newPrice = this.deepCopyObject({
                webHostingPackageNumber: newPrice.webHostingPackageNumber,
                class: "za.co.adept.aims.webhosting.WebHostingPackagePrice",
                validFrom: 0,
                validUntil: 0
            });
        },
        editPackage: function(webhostingPackage) {
            this.webhostingPackage = this.deepCopyObject(webhostingPackage);
            this.$refs.modifyPackageModal.open();
        },
        deletePackage: function(webhostingPackage) {
            this.$swal({
                title: "Are you sure you want to delete this Package?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const packageNumber = webhostingPackage.webHostingPackageNumber;

                    this.$http.delete(`${hostUrl}pricing/webhostingpackages/${packageNumber}`).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.loadWebhostingPackages();
                        },
                        (error) => {
                            this.showError("Error Deleting Price Package", error);
                        }
                    );
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>