<template>
  <ClientHelpdeskTicketLayout :parts="parts">
    <template v-slot:tickets>
      <TicketList
        :ticketList="ticketList"
        :statuses="statuses"
        :createdDate="createdDate"
        @getAllTickets="getAllTickets"
      />
    </template>
  </ClientHelpdeskTicketLayout>
</template>

<script>
import TicketList from "@/components/ObjParts/MultipleLoaded/ClientHelpdeskTickets/TicketList";
import NoReplyTicketList from "@/components/ObjParts/MultipleLoaded/ClientHelpdeskTickets/NoReplyTicketList";
import ClientHelpdeskTicketLayout from "@/layouts/ClientHelpdeskTicketLayout"

export default {
  components: {
    TicketList,
    NoReplyTicketList,
    ClientHelpdeskTicketLayout,
  },
  data() {
    return {
      parts: [
        {
          name: "tickets",
          icon: "fa fa-ticket",
          displayName: "Helpdesk Tickets",
          permissions: ["STAFF", "CLIENT"],
        },
      ],
      clientNumber: null,
      ticketList: [],
      noReplyTickets: [],
      OldTickets: [],
      statuses: [],
      createdDate: '',
    };
  },
  mounted() {
      if (this.userContainsPermission(["CLIENT"])) {
          this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Helpdesk Tickets'); 
      }
      this.clientNumber = this.$route.params.clientNumber;
      this.getAllTickets();
      this.getTicketStatuses();
  },
  methods: {
    getAllTickets: function(data) {
      const hostUrl = this.$config.aimsAPIv3;      
      let url = `${hostUrl}tickets/${this.clientNumber}`;

      if (data) {
        url = `${hostUrl}tickets/${this.clientNumber}?since=${data}`;
      }
      
      this.$http.get(url).then(
        (response) => {
          this.ticketList = response.data;   

          if (this.ticketList.length > 0) {
            this.createdDate = this.dateTimeFormat(this.ticketList.at(-1).created);
          }
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Halo tickets", error);
        }
      );
    },
    getTicketStatuses: function() {
      const hostUrl = this.$config.aimsAPIv3;

      this.$http.get(`${hostUrl}tickets/statuses`).then(
        (response) => {
          this.statuses = response.data;                    
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Halo ticket statuses", error);
        }
      );
    }
  },
};
</script>
