<template>
  <div class="card card-default">
    <div class="card-header">
      <h4>{{bundle.description}}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row mb-md-0">
            <label class="col-md-6 mb-md-0">Package Size:</label>
            <div class="col-md-6">
              <strong v-if="bundle.size > 0">{{bundle.size}} GB</strong>
              <strong v-if="bundle.size === 0">Uncapped</strong>
            </div>
          </div>
          <div class="form-group row mb-md-0">
            <label class="col-md-6 mb-md-0">Download Speed:</label>
            <div class="col-md-6">
              <strong>{{downloadSpeed}} Mbps</strong>
            </div>
          </div>
          <div class="form-group row mb-md-0">
            <label class="col-md-6 mb-md-0">Upload Speed:</label>
            <div class="col-md-6">
              <strong>{{uploadSpeed}} Mbps</strong>
            </div>
          </div>
          <div class="form-group row mb-md-0">
            <label class="col-md-6 mb-md-0">Package Id:</label>
            <div class="col-md-6">
              <strong>{{bundle.objKey}}</strong>
            </div>
          </div>
           <div class="form-group row" v-if="$route.name != 'octotellayer2service'">
            <label class="col-md-6 mb-md-0">Shaping:</label>
            <div class="col-md-6" v-if="shapedPoolDetails">
              <strong>{{shapedPoolDetails.name}}</strong>
            </div>
            <div class="col-md-6" v-else>
              <a href="javascript:void(0)" @click="getShapedPool()">Show Shaping Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      bundle: {
        type: Object,
        required: true
      }
    },
    data: function () {
      return {
        shapedPoolDetails: null
      };
    },
    computed: {
      downloadSpeed: {
        get() {
          return this.speed_bitsToMbits(
            this.bundle.downloadSpeed
          );
        },
        set(downloadSpeed) {
          this.bundle.downloadSpeed = this.speed_mbitsToBits(
            downloadSpeed
          );
        },
      },
      uploadSpeed: {
        get() {
          return this.speed_bitsToMbits(
            this.bundle.uploadSpeed
          );
        },
        set(uploadSpeed) {
          this.bundle.uploadSpeed = this.speed_mbitsToBits(
            uploadSpeed
          );
        },
      },
    },
    methods: {
      getShapedPool() {
        console.log("shapedpool get")
        this.$http.get(this.$config.aimsAPI + "shapedpools/" + this.bundle.shapedPoolNumber)
          .then(response => {
            this.shapedPoolDetails = response.data;
          }, error => {
            console.error(error);
            this.showError('Error fetching Shaped Pool', error);
          });
      }
    }
  };

</script>
